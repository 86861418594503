import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  FlatList,
  ActivityIndicator,
  Icon,
  Card,
  TextSmall,
  Button,
  TextMedium,
  InputField,
} from '../../components';
import UserModel from '../../models/UserModel';
import { View } from 'react-native';

const UsersList = ({
  dimensions,
  usersList,
  loadMoreData,
  pagination,
  scheduleUsersList,
  searchControl,
  setSearchControl,
  addUserToSchedule,
  ...props
}) => {

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormRow padding={4} justifyContent='center'>
      <Card width={width}>
        <FormRow justifyContent='center'>
          <TextMedium>{getDic("lista")} {getDic("de")} {getDic("usuarios")}</TextMedium>
        </FormRow>
        <FormRow paddingLeft={5} paddingRight={5}>
          <InputField
            fieldHeight={25}
            fieldFontSize={12}
            iconLeft={<FormColumn paddingRight={5}><Icon type="FontAwesome" name="search" size={15} color='gray' /></FormColumn>}
            value={searchControl.searchName}
            placeholder={`${getDic("buscar")} ${getDic("nome")}`}
            onChange={v => setSearchControl({ ...searchControl, searchName: v })}
          />
        </FormRow>
        {usersList && usersList.length > 0 && (
          <FlatList
            keyboardShouldPersistTaps='handled'
            data={usersList}
            renderItem={renderListItem}
            keyExtractor={(item) => item.id}
            contentContainerStyle={{ paddingBottom: 10, paddingTop: 5 }}
          // onEndReached={loadMoreData}          
          // ListFooterComponent={(pagination.current.hasMore && <ActivityIndicator />)}
          />
        )}
        {usersList.length > 0 && pagination.hasMore && !pagination.loading && (
          <FormRow paddingBottom={10} justifyContent='center'>
            <FormColumn width={200}>
              <Button
                text={`${getDic("carregar")} ${getDic("mais")}`}
                onPress={() => loadMoreData()}
              />
            </FormColumn>
          </FormRow>
        )}
        {pagination.loading && (
          <FormRow padding={10} justifyContent='center'>
            <ActivityIndicator />
          </FormRow>
        )}
      </Card>
    </FormRow>
  )

  function renderListItem(obj) {
    const _hasUser = scheduleUsersList.find(s => { return s.id === obj.item.id });
    if (_hasUser) {
      return null;
    }
    return (
      <FormRow padding={4} justifyContent='center'>
        <Card padding={0} width={width - 20} background='#f5f5f5'>
          {renderCard(obj.item)}
        </Card>
      </FormRow>
    )
  }

  function renderCard(user = UserModel()) {
    return (
      <FormColumn padding={5} width={width}>
        <FormRow padding={5}>
          <FormColumn>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Button onPress={() => addUserToSchedule(user)}>
                  <TextSmall color='white'>{getDic("adicionar")}</TextSmall>
                </Button>
              </View>
              <FormColumn>
                <FormRow>
                  <TextSmall>{user.name}</TextSmall>
                </FormRow>
                <FormRow>
                  <TextSmall>{user.email}</TextSmall>
                </FormRow>
              </FormColumn>
            </FormRow>
          </FormColumn>
        </FormRow>
      </FormColumn>
    )
  }
};

export default UsersList;
