const obj = {
  id: '',
  eventId: '',
  scheduleId: '',
  eventName: '',
  scheduleName: '',
  startDate: '',
  endDate: '',
}

export default function ScheduleModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};
