import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  Icon,
  Card,
  FormColumn,
  TextSmall,
  ButtonTransparent,
  ActivityIndicator,
} from '../../components';
import GuestClassificationModel from '../../models/GuestClassificationModel';
import { View } from 'react-native';
import moment from 'moment';

const ClassificationHistory = (props) => {
  const {
    classificationHistoryControl,
    setClassificationHistoryControl,
    getGuestClassifications,
  } = props;

  return (
    <FormColumn paddingTop={10}>
      <ButtonTransparent onPress={() => {
        if (classificationHistoryControl.open) {
          setClassificationHistoryControl({ ...classificationHistoryControl, open: false });
        } else {
          getGuestClassifications()
        }
      }}>
        <FormRow justifyContent='space-between'>
          <TextSmall color='#5c5c5c'>{getDic("historico")} {getDic("classificacao")}</TextSmall>
          <Icon type="Entypo" name={classificationHistoryControl.open ? "chevron-down" : "chevron-up"} size={20} color='#5c5c5c' />
        </FormRow>
      </ButtonTransparent>
      {classificationHistoryControl.open ? classificationHistoryControl.isLoading ? renderIsLoading() : renderHistory() : null}
    </FormColumn>
  )

  function renderIsLoading() {
    return (
      <FormRow justifyContent='center'>
        <ActivityIndicator />
      </FormRow>
    )
  }

  function renderHistory() {
    return (
      <FormColumn>
        {classificationHistoryControl.classifications.map((c, i) => {
          return (
            <View style={{ padding: 3 }} key={i}>
              <Card backgroundColor='#e3e3e3'>
                <FormRow justifyContent='space-between'>
                  <TextSmall color='#5c5c5c'>{moment(c.date).format('DD/MM/YYYY HH:mm')}</TextSmall>
                </FormRow>
                <FormRow justifyContent='space-between'>
                  <View>
                    {renderStars(c)}
                  </View>
                  <TextSmall color='#5c5c5c'>{c.note}</TextSmall>
                </FormRow>
              </Card>
            </View>
          )
        })}
      </FormColumn>
    )
  }

  function renderStars(classification = GuestClassificationModel()) {
    return (
      <FormRow>
        {classification.stars >= 1 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {classification.stars >= 2 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {classification.stars >= 3 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {classification.stars >= 4 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {classification.stars >= 5 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
      </FormRow>
    )
  }
};

export default ClassificationHistory;
