import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import Icon from './Icon';

const RadioButton = ({
  active = false,
  color = "#456091",
  onPress,
}) => {

  const styles = StyleSheet.create({
    container: {
      alignItems: 'center',
      alignSelf: 'flex-start',
      flexDirection: 'row',
    },
  });

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        if (onPress) {
          onPress(!active);
        }
      }}
    >
      <View>
        {active ? (
          <Icon type="Ionicons" name="radio-button-on" size={20} color={color} />
        ) : (
          <Icon type="Ionicons" name="radio-button-off" size={20} color={color} />
        )}
      </View>
    </TouchableOpacity>
  )
};

export default RadioButton;
