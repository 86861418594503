import axios from 'axios';
import moment from 'moment';
import apiService from './apiService';
import UserImporterModel from '../models/UserImporterModel';

export default {
  async inportOrganizationAndUserAndSchedule(userImporter = UserImporterModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/userImporter`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          organizationName: userImporter.organizationName,
          userName: userImporter.userName,
          userEmail: userImporter.userEmail,
          userPassword: userImporter.userPassword,
          eventId: userImporter.eventId,
          scheduleId: userImporter.scheduleId,
          eventName: userImporter.eventName,
          scheduleName: userImporter.scheduleName,
          startDate: moment(userImporter.startDate).toISOString(),
          endDate: moment(userImporter.endDate).toISOString(),
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return _ret.data;

    } catch (error) {
      console.log('Error getUserApi', error);
      throw error;
    }
  }
}
