export const SET_CHOSEN_ORGANIZATION = 'SET_CHOSEN_ORGANIZATION';
export function setChosenOrganization(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_CHOSEN_ORGANIZATION,
      payload,
    });
  };
}

export const CLEAR_CHOSEN_ORGANIZATION = 'CLEAR_CHOSEN_ORGANIZATION';
export function clearChosenOrganization() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_CHOSEN_ORGANIZATION,
    });
  };
}
