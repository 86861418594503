import * as React from 'react';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { getDic } from '../../assets/i18n/dictionary';
import userService from '../../services/userService';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  FormColumn,
  Card,
  FormRow,
  ButtonTransparent,
  TextSmall,
  TextMedium,
} from '../../components';
import { Dimensions } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

import RecoverPasswordForm from './RecoverPasswordForm';
import RoundedBackground from './RoundedBackground';

const objRecoverPasswordForm = {
  email: '',
  token: '',
  password: '',
  confirmPassword: '',
}

const RecoverPasswordPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [recoverPasswordForm, setRecoverPasswordForm] = React.useState({ ...objRecoverPasswordForm });
  const [recoverPasswordFormError, setRecoverPasswordFormError] = React.useState({ ...objRecoverPasswordForm });
  const [formControl, setFormControl] = React.useState('email');

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      setFormControl('email');
      setRecoverPasswordForm({ ...objRecoverPasswordForm });
      setRecoverPasswordFormError({ ...objRecoverPasswordForm });
    }, [props.navigation])
  );

  const getPageData = React.useCallback(async () => {
    try {

    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
    setLoadingControl({ ...loadingControl, open: false });
  });

  const sendEmailRecoverPassword = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, open: true });

      if (!validateForm()) {
        setLoadingControl({ ...loadingControl, open: false });
        return;
      }

      await userService.createTokenRecoverPassword(recoverPasswordForm.email);

      setAlertControl({
        open: true,
        title: '',
        message: getDic("esqueceu-a-senha-mensagem"),
        icon: 'info',
      });
      setFormControl('token');

    } catch (error) {
      console.log("Error sendEmailRecoverPassword", error);
      errorTreatment(error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function errorTreatment(error) {
      let _alertObject = {
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      };

      if (error?.response?.data?.message === 'EMAIL_NOT_FOUND') {
        _alertObject = {
          ..._alertObject,
          title: '',
          message: `${getDic("email")} ${getDic("nao")} ${getDic("localizado")}`,
          icon: 'danger',
        };
      }

      setAlertControl(_alertObject);
    }

    function validateForm() {
      let _isValid = true;
      let _recoverPasswordFormError = { ...objRecoverPasswordForm };

      if (!recoverPasswordForm.email) {
        _isValid = false;
        _recoverPasswordFormError.email = getDic("preenchimento-obrigatorio");
      }

      setRecoverPasswordFormError(_recoverPasswordFormError);

      return _isValid;
    }
  });

  const tryRecoverPassword = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, open: true });

      if (!validateForm()) {
        setLoadingControl({ ...loadingControl, open: false });
        return;
      }

      await userService.recoverPassword(
        recoverPasswordForm.email,
        recoverPasswordForm.token,
        recoverPasswordForm.password,
        recoverPasswordForm.confirmPassword,
      );

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success',
      });
      setFormControl('success');
    } catch (error) {
      console.log("Error tryRecoverPassword", error);
      errorTreatment(error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function errorTreatment(error) {
      let _alertObject = {
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      };

      if (error?.response?.data?.message === 'USER_NOT_FOUND') {
        _alertObject = {
          ..._alertObject,
          message: `${getDic("email")} ${getDic("nao")} ${getDic("localizado")}`,
        };
      }

      if (error?.response?.data?.message === 'TOKEN_ACCESS_INVALID_OR_EXPIRED') {
        _alertObject = {
          ..._alertObject,
          message: `Token ${getDic("invalido")}`,
        };
      }

      if (error?.response?.data?.message === 'WRONG_CONFIRM_PASSWORD') {
        _alertObject = {
          ..._alertObject,
          message: `${getDic("senha-confirmacao-erro")}`,
        };
      }

      setAlertControl(_alertObject);
    }

    function validateForm() {
      let _isValid = true;
      let _recoverPasswordFormError = { ...objRecoverPasswordForm };

      if (!recoverPasswordForm.email) {
        _isValid = false;
        _recoverPasswordFormError.email = getDic("preenchimento-obrigatorio");
      }

      if (!recoverPasswordForm.token) {
        _isValid = false;
        _recoverPasswordFormError.token = getDic("preenchimento-obrigatorio");
      }

      if (!recoverPasswordForm.password) {
        _isValid = false;
        _recoverPasswordFormError.password = getDic("preenchimento-obrigatorio");
      }

      if (!recoverPasswordForm.confirmPassword) {
        _isValid = false;
        _recoverPasswordFormError.confirmPassword = getDic("preenchimento-obrigatorio");
      }

      if (recoverPasswordForm.password !== recoverPasswordForm.confirmPassword) {
        _isValid = false;
        _recoverPasswordFormError.confirmPassword = getDic("senha-confirmacao-erro");
      }

      setRecoverPasswordFormError(_recoverPasswordFormError);

      return _isValid;
    }
  });

  const goBack = React.useCallback(() => {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack();
    } else {
      window.history.back();
    }
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      padding={0}
      scroll
    >
      <RoundedBackground dimensions={dimensions} />
      <FormColumn alignItems='center' paddingTop={70} padding={10}>
        <Card borderRadius={20}>
          {formControl === 'success' ? (
            <FormRow justifyContent='flex-end' padding={15}>
              <TextMedium>{getDic("enviado-sucesso")}</TextMedium>
            </FormRow>
          ) : (
            <RecoverPasswordForm
              dimensions={dimensions}
              formControl={formControl}
              recoverPasswordForm={recoverPasswordForm}
              setRecoverPasswordForm={setRecoverPasswordForm}
              recoverPasswordFormError={recoverPasswordFormError}
              sendEmailRecoverPassword={sendEmailRecoverPassword}
              tryRecoverPassword={tryRecoverPassword}
            />
          )}
          <FormRow justifyContent='flex-end' padding={15}>
            <ButtonTransparent onPress={() => goBack()}>
              <TextSmall>{getDic("voltar")}</TextSmall>
            </ButtonTransparent>
          </FormRow>
        </Card>
      </FormColumn>

      {modalControl()}
    </Container >
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default RecoverPasswordPage;
