import axios from 'axios';
import UserModel from '../models/UserModel';
import apiService from './apiService';
import moment from 'moment';
import { store } from '../store';

export default {
  async addUserToSchedule(userId = '', scheduleId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}userXSchedule/addUserToSchedule`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          userId: userId,
          scheduleId: scheduleId
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error addUserToSchedule', error);
      throw error;
    }
  },

  async removeUserFromSchedule(userId = '', scheduleId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}userXSchedule/removeUserFromSchedule`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          userId: userId,
          scheduleId: scheduleId
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error removeUserFromSchedule', error);
      throw error;
    }
  },

  async createUser(organizationId = '', name = '', email = '', password = '', confirmPassword = '', profile = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/createUser`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          organizationId: organizationId,
          name: name,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
          profile: profile,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      let _user = UserModel(_ret.data.data);
      return _user;

    } catch (error) {
      console.log('Error createUser', error);
      throw error;
    }
  },

  async getUsers(
    organizationId = '',
    searchString = '',
    pagination = {
      limit: 1,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'name',
    order = 'ASC',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/getUsers?organizationId=${organizationId}&offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(UserModel({
          id: item.id,
          email: item.email,
          name: item.name,
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;

    } catch (error) {
      console.log('Error getUsers', error);
      throw error;
    }
  },

  async getUsersBySchedule(
    scheduleId = '',
    searchString = '',
    pagination = {
      limit: 1,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'name',
    order = 'ASC',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/getUsersBySchedule?scheduleId=${scheduleId}&offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(UserModel({
          id: item.id,
          email: item.email,
          name: item.name,
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;

    } catch (error) {
      console.log('Error getUsersBySchedule', error);
      throw error;
    }
  },

  async getUsersWithoutInteegra(
    organizationId = '',
    searchString = '',
    pagination = {
      limit: 1,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'name',
    order = 'ASC',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/getUsersWithoutInteegra?organizationId=${organizationId}&offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(UserModel({
          id: item.id,
          email: item.email,
          name: item.name,
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;

    } catch (error) {
      console.log('Error getUsersWithoutInteegra', error);
      throw error;
    }
  },

  async inactiveUser(userId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/inactiveUser`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          id: userId,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error inactiveUser', error);
      throw error;
    }
  },

  async loginUser(login = '', password = '') {
    try {
      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email: login,
          password: password
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      let _user = getUserModelFromApiReturn(_ret.data);
      return _user;

    } catch (error) {
      console.log('Error getUserApi', error);
      throw error;
    }
  },

  async createTokenRecoverPassword(email = '') {
    try {
      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/createTokenRecoveryPassword`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email: email,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error createTokenRecoverPassword', error);
      throw error;
    }
  },

  async recoverPassword(email = '', token = '', password = '', confirmPassword = '') {
    try {
      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/recoverPassword`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email: email,
          token: token,
          password: password,
          confirmPassword: confirmPassword,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error recoverPassword', error);
      throw error;
    }
  },

  async updateUser(user = UserModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/updateUser`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          id: user.id,
          name: user.name,
          email: user.email
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      let _user = getUserModelFromApiReturn(_ret.data);
      return _user;

    } catch (error) {
      console.log('Error updateUser', error);
      throw error;
    }
  },

  async updatePassword(user = UserModel(), password = '', confirmPassword = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/updatePassword`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          id: user.id,
          password: password,
          confirmPassword: confirmPassword
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error updatePassword', error);
      throw error;
    }
  },

  async updateProfile(user = UserModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}user/updateProfile`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          id: user.id,
          profile: user.profile,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error updateProfile', error);
      throw error;
    }
  },
}

function getUserModelFromApiReturn(apiReturn) {
  try {
    let _user = UserModel({
      id: apiReturn.data.user.id,
      email: apiReturn.data.user.email,
      name: apiReturn.data.user.name,
      profile: apiReturn.data.user.profile,
      token: apiReturn.data.token,
      refreshToken: apiReturn.data.refreshToken,
      lastTokenDate: moment().format(),
      organizations: apiReturn.data.user.organizations,
    });

    return _user;
  } catch (error) {
    console.log('Error getUserModelFromApiReturn', error);
    throw error;
  }
}
