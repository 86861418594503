import * as React from 'react';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { getDic } from '../../assets/i18n/dictionary';
import userService from '../../services/userService';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  FormColumn,
  Card,
} from '../../components';
import { Dimensions } from 'react-native';
import OrganizationModel from '../../models/OrganizationModel';

import InteegraCopyright from './InteegraCopyright';
import LoginForm from './LoginForm';
import RoundedBackground from './RoundedBackground';

const objLoginForm = {
  login: '',
  password: ''
}

const LoginPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });

  const [loginForm, setLoginForm] = React.useState({ ...objLoginForm });
  const [loginFormError, setLoginFormError] = React.useState({ ...objLoginForm });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {

    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
    setLoadingControl({ ...loadingControl, open: false });
  });

  const tryLogin = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, open: true });

      if (!validateForm()) {
        setLoadingControl({ ...loadingControl, open: false });
        return;
      }

      let _user = await userService.loginUser(loginForm.login, loginForm.password);
      store.dispatch(actions.setUser(_user));
      store.dispatch(actions.setChosenOrganization(OrganizationModel(_user.organizations[0])));

      props.navigation.replace('HomePage');

    } catch (error) {
      console.log("Error tryLogin", error);
      errorTreatment(error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function errorTreatment(error) {
      let _alertObject = {
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      };

      if (error?.response?.data?.message === 'WRONG_PASSWORD' || error?.response?.data?.message === 'EMAIL_NOT_FOUND') {
        _alertObject = {
          ..._alertObject,
          title: '',
          message: getDic("falha-login-mensagem"),
          icon: 'danger',
        };
      }

      setAlertControl(_alertObject);
    }

    function validateForm() {
      let _isValid = true;
      let _loginFormError = { ...objLoginForm };

      if (!loginForm.login) {
        _isValid = false;
        _loginFormError.login = getDic("preenchimento-obrigatorio");
      }

      if (!loginForm.password) {
        _isValid = false;
        _loginFormError.password = getDic("preenchimento-obrigatorio");
      }

      setLoginFormError(_loginFormError);

      return _isValid;
    }
  });

  const openPageRecoverPassword = React.useCallback(async () => {
    props.navigation.navigate('RecoverPasswordPage');
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      padding={0}
      scroll
    >
      <RoundedBackground dimensions={dimensions} />
      <FormColumn alignItems='center' paddingTop={70} padding={10}>
        <Card borderRadius={20}>
          <LoginForm
            dimensions={dimensions}
            loginForm={loginForm}
            setLoginForm={setLoginForm}
            loginFormError={loginFormError}
            tryLogin={tryLogin}
            openPageRecoverPassword={openPageRecoverPassword}
          />
        </Card>
        <InteegraCopyright />
      </FormColumn>

      {modalControl()}
    </Container >
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default LoginPage;
