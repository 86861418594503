export const SET_CHOSEN_QRCODE = 'SET_CHOSEN_QRCODE';
export function setChosenQrCode(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_CHOSEN_QRCODE,
      payload,
    });
  };
}

export const CLEAR_CHOSEN_QRCODE = 'CLEAR_CHOSEN_QRCODE';
export function clearChosenQrCode() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_CHOSEN_QRCODE,
    });
  };
}
