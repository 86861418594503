const obj = {
  id: '',
  email: '',
  name: '',
  company: '',
  position: '',
  phone: '',
  stars: 0,
  note: '',
  date: '',
  userName: '',
  userEmail: '',
}

export default function GuestModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};
