import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  InputField,
  Button,
  FormColumn,
  TextSmall,
  Icon,
  ButtonTransparent,
} from '../../components';
import { View } from 'react-native';

const ResetPasswordForm = (props) => {
  const {
    resetPasswordControl,
    setResetPasswordControl,
    trySaveNewPassword,
  } = props;

  return (
    <FormColumn paddingTop={10} padding={5}>
      <ButtonTransparent padding={0} onPress={() => setResetPasswordControl({ ...resetPasswordControl, open: !resetPasswordControl.open })}>
        <FormRow justifyContent='space-between' paddingTop={5} paddingBottom={5}>
          <TextSmall color='#5c5c5c'>{getDic("redefinir")} {getDic("senha")}</TextSmall>
          <Icon type="Entypo" name={resetPasswordControl.open ? "chevron-down" : "chevron-up"} size={20} color='#5c5c5c' />
        </FormRow>
      </ButtonTransparent>
      {resetPasswordControl.open && renderForm()}
    </FormColumn>
  )

  function renderForm() {
    return (
      <FormColumn>
        <FormRow>
          <InputField
            backgroundColor='#f2f2f2'
            value={resetPasswordControl.password}
            title={`${getDic("nova")} ${getDic("senha")} *`}
            secureTextEntry
            onChange={e => setResetPasswordControl({ ...resetPasswordControl, password: e })}
          />
        </FormRow>
        <FormRow>
          <InputField
            backgroundColor='#f2f2f2'
            value={resetPasswordControl.confirmPassword}
            title={`${getDic("confirmar")} ${getDic("nova")} ${getDic("senha")} *`}
            secureTextEntry
            onChange={e => setResetPasswordControl({ ...resetPasswordControl, confirmPassword: e })}
          />
        </FormRow>
        <FormRow justifyContent='center'>
          <View style={{ padding: 5 }}>
            <Button
              text={`${getDic("salvar")} ${getDic("nova")} ${getDic("senha")}`}
              onPress={trySaveNewPassword}
              background='green'
            />
          </View>
        </FormRow>
      </FormColumn>
    )
  }
};

export default ResetPasswordForm;
