import moment from 'moment';

export default {
  validatePassword(password) {
    var _ret = true;

    if (password.length < 8) {
      _ret = false;
    }
    if (password.search(/[a-z]/i) < 0) {
      _ret = false;
    }
    if (password.toLowerCase() === password) {
      _ret = false;
    }
    if (password.toUpperCase() === password) {
      _ret = false;
    }
    if (password.search(/[0-9]/) < 0) {
      _ret = false;
    }

    var regularExpression = /^(?=.*[a-zA-Z!#$%&? "])[a-zA-Z0-9!#$%&?]{8,20}$/;

    if (regularExpression.test(password)) {
      _ret = false;
    }

    return _ret;
  },

  validateCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    ) {
      return false
    }
    var _sum = 0
    var _rest
    for (var i = 1; i <= 9; i++)
      _sum = _sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    _rest = (_sum * 10) % 11
    if ((_rest == 10) || (_rest == 11)) _rest = 0
    if (_rest != parseInt(cpf.substring(9, 10))) return false
    _sum = 0
    for (var i = 1; i <= 10; i++)
      _sum = _sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    _rest = (_sum * 10) % 11
    if ((_rest == 10) || (_rest == 11)) _rest = 0
    if (_rest != parseInt(cpf.substring(10, 11))) return false
    return true
  },

  validateDate(value) {
    let _values = value.split('/');
    let _day = _values[0] || moment().format('DD');
    let _month = _values[1] || moment().format('MM');
    let _year = _values[2] || moment().format('YYYY');

    if (parseInt(_month) > 12) {
      _month = '12';
    }

    if (parseInt(_month) === 2 && parseInt(_day) > 29) {
      _day = '29';
    } else if (parseInt(_day) > 31 && (
      parseInt(_month) === 1 ||
      parseInt(_month) === 3 ||
      parseInt(_month) === 5 ||
      parseInt(_month) === 7 ||
      parseInt(_month) === 8 ||
      parseInt(_month) === 10 ||
      parseInt(_month) === 12
    )) {
      _day = '31';
    } else if (parseInt(_day) > 30 && (
      parseInt(_month) === 4 ||
      parseInt(_month) === 6 ||
      parseInt(_month) === 9 ||
      parseInt(_month) === 11
    )) {
      _day = '30';
    }

    if (parseInt(_year) < 1900) {
      _year = '1900';
    } else if (parseInt(_year) > 2100) {
      _year = '2100';
    }

    return `${_day}/${_month}/${_year}`;
  },

  validateHour(values) {
    let _values = values.split(':');
    let _hour = _values[0] || moment().format('HH');
    let _minutes = _values[1] || moment().format('mm');

    if (parseInt(_hour) > 23) {
      _hour = '23';
    }
    if (parseInt(_minutes) > 59) {
      _minutes = '59';
    }

    return `${_hour}:${_minutes}`;
  },

  validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(mail)) {
      return true;
    }
    return false;
  }
}
