import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  Card,
  Icon,
  TextMedium,
  ButtonTransparent,
  FormColumn,
} from '../../components';

const CommandButtons = (props) => {
  const {
    dimensions,
    navigatePage,
    openGuestRegistration,
  } = props;

  const [cardWidth, setCardWidth] = React.useState(150);
  const [iconSize, setIconSize] = React.useState(100);
  const [fontSize, setFontSize] = React.useState(15);

  React.useEffect(() => {
    if (dimensions.width < 390) {
      setCardWidth(dimensions.width / 2 - 50);
      setIconSize(50);
      setFontSize(10);
    } else {
      setCardWidth(150);
      setIconSize(100);
      setFontSize(15);
    }
  }, [dimensions]);

  return (
    <FormRow flexWrap='wrap' justifyContent='center'>
      <FormColumn padding={5}>
        <ButtonTransparent
          onPress={() => navigatePage('QrCodePage')}
        >
          <Card
            borderColor='#456091'
            borderWidth={2}
          >
            <FormColumn height={cardWidth} width={cardWidth} alignItems='center' justifyContent={'center'}>
              <Icon type="AntDesign" name="qrcode" size={iconSize} color='#456091' />
              <TextMedium align='center' fontSize={fontSize} color='#456091'>{`${getDic("ler")} QR Code`}</TextMedium>
            </FormColumn>
          </Card>
        </ButtonTransparent>
      </FormColumn>
      <FormColumn padding={5}>
        <ButtonTransparent
          onPress={() => openGuestRegistration()}
        >
          <Card
            borderColor='#456091'
            borderWidth={2}
          >
            <FormColumn height={cardWidth} width={cardWidth} alignItems='center' justifyContent={'center'}>
              <Icon type="Ionicons" name="person-add" size={iconSize} color='#456091' />
              <TextMedium align='center' fontSize={fontSize} color='#456091'>{`${getDic("cadastrar")} ${getDic("participante")}`}</TextMedium>
            </FormColumn>
          </Card>
        </ButtonTransparent>
      </FormColumn>
      <FormColumn padding={5}>
        <ButtonTransparent
          onPress={() => navigatePage('ScheduleGuestsReportPage')}
        >
          <Card
            borderColor='#456091'
            borderWidth={2}
          >
            <FormColumn height={cardWidth} width={cardWidth} alignItems='center' justifyContent={'center'}>
              <Icon type="FontAwesome" name="list-alt" size={iconSize} color='#456091' />
              <TextMedium align='center' fontSize={fontSize} color='#456091'>{getDic("relatorio")}</TextMedium>
            </FormColumn>
          </Card>
        </ButtonTransparent>
      </FormColumn>
    </FormRow>
  )
};

export default CommandButtons;
