import React from "react";
import { getDic } from '../assets/i18n/dictionary';
import { store } from '../store';
import {
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Dimensions,
  Platform,
} from "react-native";
import FormRow from './FormRow';
import FormColumn from './FormColumn';
import Alert from './Alert';
import Loading from './Loading';
import Icon from './Icon';
import Dialog from './Dialog';
import ButtonTransparent from "./ButtonTransparent";
import Modal from "react-native-modal";
import userService from "../services/userService";

const SideMenu = ({
  open,
  onClose,
  navigateTo,
  navigation,
  ...props
}) => {

  const menuMinWidth = 300;
  const poweredByInteegraHeight = Platform.OS === 'ios' ? 60 : 40;
  const [dimensions, setDimensions] = React.useState({ menuWidth: 0, backdropWidth: 0, menuHeight: 0 });
  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [dialogExitAppControl, setDialogExitAppControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
    command: '', // trySyncDataAndExit, exitApp
  });

  React.useEffect(() => {
    getComponentData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setMenuWidth(window.width, window.height);
      }
    );
    return () => subscription?.remove();
  }, []);

  const getComponentData = React.useCallback(async () => {
    const _dimensions = Dimensions.get('window');
    setMenuWidth(_dimensions.width, _dimensions.height);
  });

  const setMenuWidth = React.useCallback((width = 0, height = 0) => {
    let _backdropWidth = width - menuMinWidth
    setDimensions({
      menuWidth: width < menuMinWidth ? width : menuMinWidth,
      backdropWidth: _backdropWidth < 0 ? 0 : _backdropWidth,
      menuHeight: height - poweredByInteegraHeight
    });
  });

  const closeMenu = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  });

  const localNavigateTo = React.useCallback((page = '') => {
    if (navigateTo) {
      navigateTo(page);
    }
  });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      margin: 0
    },
    backdrop: {
      height: '100%',
    },
    window: {
      width: '100%',
      height: '100%',
      backgroundColor: '#456091',
      borderRadius: 5,
      padding: 10,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    windowButtons: {
      width: '100%',
      height: dimensions.menuHeight,
    },
    poweredByInteegra: {
      height: poweredByInteegraHeight,
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    text: {
      fontFamily: 'Verdana',
      color: 'white',
      fontSize: 17
    },
  });

  return (
    <>
      <Modal
        style={styles.container}
        animationIn={"bounceInLeft"}
        animationOut={"bounceOutLeft"}
        animationInTiming={800}
        animationOutTiming={800}
        isVisible={open}
      >
        <FormRow>
          <FormColumn width={dimensions.menuWidth}>
            <View style={styles.window}>
              <View style={styles.windowButtons}>
                <FormRow justifyContent='flex-end' paddingTop={Platform.OS === 'ios' ? 20 : null}>
                  <ButtonTransparent padding={10} onPress={() => closeMenu()}>
                    <Icon type="AntDesign" name="close" size={20} color={'white'} />
                  </ButtonTransparent>
                </FormRow>
                <ButtonTransparent padding={10} onPress={() => localNavigateTo('UserProfilePage')}>
                  <FormRow>
                    <View style={{ paddingRight: 10 }}>
                      <Icon type="Ionicons" name="person" size={20} color={'white'} />
                    </View>
                    <Text style={styles.text}>{getDic("perfil")}</Text>
                  </FormRow>
                </ButtonTransparent>
                <ButtonTransparent padding={10} onPress={() => localNavigateTo('HomePage')}>
                  <FormRow>
                    <View style={{ paddingRight: 10 }}>
                      <Icon type="AntDesign" name="home" size={20} color={'white'} />
                    </View>
                    <Text style={styles.text}>{getDic("home")}</Text>
                  </FormRow>
                </ButtonTransparent>
                {renderAdminButtons()}
                {renderInteegraButtons()}
                <ButtonTransparent padding={10} onPress={() => {
                  setDialogExitAppControl({
                    ...dialogExitAppControl,
                    open: true,
                    title: '',
                    icon: 'info',
                    message: `${getDic("deseja-sair")}`,
                    command: 'trySyncDataAndExit'
                  });
                }}>
                  <FormRow>
                    <View style={{ paddingRight: 10 }}>
                      <Icon type="MaterialCommunityIcons" name="location-exit" size={20} color={'white'} />
                    </View>
                    <Text style={styles.text}>{getDic("sair")}</Text>
                  </FormRow>
                </ButtonTransparent>
              </View>
              <View style={styles.poweredByInteegra}>
                <Text style={styles.text}>Powered by Inteegra</Text>
              </View>
            </View>
          </FormColumn>
          <FormColumn width={dimensions.backdropWidth}>
            <TouchableWithoutFeedback
              onPress={() => closeMenu()}
              style={styles.backdrop}
            >
              <View style={styles.backdrop}></View>
            </TouchableWithoutFeedback>
          </FormColumn>
        </FormRow>

        {modalControl()}
      </Modal>
    </>
  );

  function renderAdminButtons() {
    if (store.getState().user.profile === 'admin' || store.getState().user.profile === 'inteegra') {
      return (
        <>
          <ButtonTransparent padding={10} onPress={() => localNavigateTo('AdminUsersListPage')}>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Icon type="FontAwesome5" name="users" size={20} color={'white'} />
              </View>
              <Text style={styles.text}>{getDic("usuarios")}</Text>
            </FormRow>
          </ButtonTransparent>
          <ButtonTransparent padding={10} onPress={() => localNavigateTo('AdminGuestsListPage')}>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Icon type="MaterialIcons" name="emoji-people" size={20} color={'white'} />
              </View>
              <Text style={styles.text}>{getDic("participantes")}</Text>
            </FormRow>
          </ButtonTransparent>
        </>
      )
    }
    return null;
  }

  function renderInteegraButtons() {
    if (store.getState().user.profile === 'inteegra') {
      return (
        <>
          <ButtonTransparent padding={10} onPress={() => localNavigateTo('AdminSchedulesListPage')}>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Icon type="MaterialIcons" name="event" size={20} color={'white'} />
              </View>
              <Text style={styles.text}>{getDic("eventos")} {getDic("e")} {getDic("programacoes")}</Text>
            </FormRow>
          </ButtonTransparent>
          <ButtonTransparent padding={10} onPress={() => localNavigateTo('AdminOrganizationsListPage')}>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Icon type="FontAwesome5" name="file-signature" size={20} color={'white'} />
              </View>
              <Text style={styles.text}>{getDic("contratos")}</Text>
            </FormRow>
          </ButtonTransparent>
          <ButtonTransparent padding={10} onPress={() => localNavigateTo('AdminUsersImporterPage')}>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Icon type="MaterialCommunityIcons" name="microsoft-excel" size={20} color={'white'} />
              </View>
              <Text style={styles.text}>{getDic("importador")} {getDic("de")} {getDic("eventos")}</Text>
            </FormRow>
          </ButtonTransparent>
        </>
      )
    }
    return null;
  }

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (dialogExitAppControl.open) {
      return (
        <Dialog
          open={dialogExitAppControl.open}
          title={dialogExitAppControl.title}
          message={dialogExitAppControl.message}
          icon={dialogExitAppControl.icon}
          button1OnPress={() => navigation.replace('LoginPage')}
          button2OnPress={() => setDialogExitAppControl({ ...dialogExitAppControl, open: false })}
          backdropPress={() => setDialogExitAppControl({ ...dialogExitAppControl, open: false })}
        />
      )
    }

    return null;
  }
};

export default SideMenu;
