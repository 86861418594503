import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  InputField,
  Button,
  FormColumn,
} from '../../components';
import { View } from 'react-native';

const UserForm = (props) => {
  const {
    userForm,
    setUserForm,
    userFormError,
    userFormIsEditing,
    trySaveUser,
    openEditForm,
    cancelEditForm,
  } = props;

  return (
    <FormColumn padding={5}>
      <FormRow>
        <InputField
          backgroundColor='#f2f2f2'
          value={userForm.name}
          title={`${getDic("nome")} *`}
          editable={userFormIsEditing}
          onChange={e => setUserForm({ ...userForm, name: e })}
          error={userFormError.name !== '' ? true : false}
          errorMessage={userFormError.name}
        />
      </FormRow>
      <FormRow padding={10}>
        {userFormIsEditing ? (
          <FormRow justifyContent='center'>
            <View style={{ padding: 5 }}>
              <Button
                text={`${getDic("salvar")}`}
                onPress={trySaveUser}
                background='green'
              />
            </View>
            <View style={{ padding: 5 }}>
              <Button
                text={`${getDic("cancelar")}`}
                onPress={cancelEditForm}
                background='red'
              />
            </View>
          </FormRow>
        ) : (
          <FormRow justifyContent='center'>
            <View style={{ padding: 5 }}>
              <Button
                text={`${getDic("editar")} ${getDic("nome")}`}
                onPress={openEditForm}
              />
            </View>
          </FormRow>
        )}
      </FormRow>
    </FormColumn>
  )
};

export default UserForm;
