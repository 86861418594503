import React from "react";
import { View, StyleSheet, TextInput, Text, Platform } from "react-native";
import { MaskedTextInput } from "react-native-mask-text";
import ButtonTransparent from "./ButtonTransparent";
import Icon from './Icon';

const InputField = ({
  key,
  value,
  title,
  onChange,
  placeholder = "",
  onFocus,
  error = false,
  errorMessage = "",
  required = false,
  maxLength,
  mask = "",
  backgroundColor = "white",
  editable = true,
  onBlur,
  blurIcon = false,
  blurIconPress,
  type = "text",
  onPress,
  labelColor = 'black',
  borderColor = 'black',
  secureTextEntry = false,
  keyboardType = 'default',
  fieldHeight = 35,
  fieldFontSize = 12,
  labelFontSize = 12,
  iconLeft = null,
  iconRight = null,
  multiline = false,
  moneyInput = false,
  moneyInputPrefix = 'R$',
  moneyInputPrecision = 2,
  ...props
}) => {

  const [focusBorder, setFocusBorder] = React.useState(false);

  const inputMobile = {
    flex: 1,
    opacity: focusBorder ? 1 : 0.7,
    fontSize: fieldFontSize,
  }

  const inputWeb = {
    flex: 1,
    outlineStyle: 'none',
    opacity: focusBorder ? 1 : 0.7,
    fontSize: fieldFontSize,
  }

  const styles = StyleSheet.create({
    container: {
      paddingTop: 5,
      paddingBottom: 5,
      width: '100%',
      opacity: editable ? 1 : 0.6,
    },
    searchSection: {
      width: '100%',
      flexDirection: 'row',
      backgroundColor: backgroundColor,
      height: fieldHeight,
      borderBottomWidth: focusBorder ? 2 : 1,
      borderColor: error ? 'red' : borderColor,
      padding: 5,
      fontSize: fieldFontSize,
      borderRadius: 5,
    },
    text: {
      fontFamily: 'Verdana',
      color: labelColor,
      fontSize: labelFontSize
    },
    input: Platform.OS === 'web' ? inputWeb : inputMobile,
    errorMessage: {
      color: 'red',
      fontSize: fieldFontSize
    }
  });

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{title}</Text>
      <View style={styles.searchSection}>
        {iconLeft}
        {mask && mask !== '' ? (
          <MaskedTextInput
            mask={mask}
            style={styles.input}
            onChangeText={onChange}
            value={value}
            placeholder={placeholder}
            secureTextEntry={secureTextEntry}
            keyboardType={keyboardType}
            maxLength={maxLength}
            editable={editable}
            onFocus={(e) => {
              setFocusBorder(true);
              if (onFocus) {
                onFocus(e)
              }
            }}
            onBlur={(e) => {
              setFocusBorder(false);
              if (onBlur) {
                onBlur(e)
              }
            }}
            multiline={multiline}
          />
        ) : moneyInput ? (
          <MaskedTextInput
            style={styles.input}
            onChangeText={(text, rawText) => {
              if (onChange) {
                onChange(rawText);
              }
            }}
            defaultValue={value}
            value={value}
            placeholder={placeholder}
            keyboardType="numeric"
            editable={editable}
            type="currency"
            onFocus={(e) => {
              setFocusBorder(true);
              if (onFocus) {
                onFocus(e);
              }
            }}
            onBlur={(e) => {
              setFocusBorder(false);
              if (onBlur) {
                onBlur(e);
              }
            }}
            options={{
              prefix: `${moneyInputPrefix} `,
              decimalSeparator: ',',
              groupSeparator: '.',
              precision: moneyInputPrecision
            }}
          />
        ) : (
          <TextInput
            style={styles.input}
            onChangeText={onChange}
            value={value}
            placeholder={placeholder}
            secureTextEntry={secureTextEntry}
            keyboardType={keyboardType}
            maxLength={maxLength}
            editable={editable}
            onFocus={(e) => {
              setFocusBorder(true);
              if (onFocus) {
                onFocus(e)
              }
            }}
            onBlur={(e) => {
              setFocusBorder(false);
              if (onBlur) {
                onBlur(e)
              }
            }}
            multiline={multiline}
          />
        )}
        {blurIcon && (
          <ButtonTransparent onPress={() => {
            if (blurIconPress) {
              blurIconPress();
            }
          }}>
            <Icon type="AntDesign" name="close" size={fieldFontSize} color='#8a8a8a' />
          </ButtonTransparent>
        )}
        {iconRight}
      </View>
      {error && errorMessage !== '' && (
        <Text style={styles.errorMessage}>{errorMessage}</Text>
      )}
    </View>
  );
};

export default InputField;
