import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';

const IsLoadingBackdrop = () => {

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    indicator: {
      color: 'black'
    }
  });

  return (
    <View style={styles.container}>
      <ActivityIndicator color={styles.indicator.color} size='large' />
    </View>
  )
};

export default IsLoadingBackdrop;
