import { combineReducers } from 'redux';

import chosenGuestReducer from './chosenGuestReducer';
import chosenOrganizationReducer from './chosenOrganizationReducer';
import chosenQrCodeReducer from './chosenQrCodeReducer';
import chosenScheduleReducer from './chosenScheduleReducer';
import languageReducer from './languageReducer';
import userReducer from './userReducer';

export default combineReducers({
    chosenGuest: chosenGuestReducer,
    chosenOrganization: chosenOrganizationReducer,
    chosenQrCode: chosenQrCodeReducer,
    chosenSchedule: chosenScheduleReducer,
    lang: languageReducer,
    user: userReducer
});
