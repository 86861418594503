const obj = {
  organizationName: '',
  userName: '',
  userEmail: '',
  userPassword: '',
  eventId: '',
  scheduleId: '',
  eventName: '',
  scheduleName: '',
  startDate: '',
  endDate: '',
}

export default function UserImporterModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};
