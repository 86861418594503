import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { Dimensions } from 'react-native';
import {
  Container,
  FormColumn,
  Loading,
  Alert,
  Dialog,
} from '../../components';
import guestService from '../../services/guestService';
import { useFocusEffect } from '@react-navigation/native';
import GuestModel from '../../models/GuestModel';
import ScheduleModel from '../../models/ScheduleModel';
import guestClassificationService from '../../services/guestClassificationService';
import excelService from '../../services/excelService';
import moment from 'moment';

import GuestsList from './GuestsList';
import HeaderContent from './HeaderContent';
import ModalAddClassification from './ModalAddClassification';

const modalAddClassificationControlObj = {
  open: false,
  guest: GuestModel(),
  stars: 1,
  note: '',
}

const ScheduleGuestsReportPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
    hasMore: true,
    loading: false,
  });
  const paginationRef = React.useRef();
  React.useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);
  const [guestsList, setGuestsList] = React.useState([]);
  const [guestsListQuantity, setGuestsListQuantity] = React.useState(0);
  const [searchControl, setSearchControl] = React.useState({
    searchName: '',
    searchEmail: '',
    orderBy: 'name',
    order: 'ASC',
  });
  const lastSearchStringUpdate = React.useRef(Date.now());
  const chosenSchedule = ScheduleModel(store.getState().chosenSchedule);
  const [modalAddClassificationControl, setModalAddClassificationControl] = React.useState({ ...modalAddClassificationControlObj });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  React.useEffect(() => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setGuestsList([]);
    lastSearchStringUpdate.current = Date.now();
    setTimeout(() => {
      if (lastSearchStringUpdate.current + 500 < Date.now()) {
        getGuestsList(true);
      }
    }, 500);
  }, [searchControl]);

  const getPageData = React.useCallback(async () => {
    try {

    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  useFocusEffect(
    React.useCallback(() => {
      resetGuestsList();
    }, [props.navigation])
  );

  const resetGuestsList = React.useCallback(async () => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setGuestsList([]);
    setTimeout(() => {
      getGuestsList(true);
    }, 500);
  });

  const cleanFilterDataControl = React.useCallback(async () => {
    setSearchControl({
      searchName: '',
      searchEmail: '',
      orderBy: 'name',
      order: 'ASC',
    });
  });

  const getGuestsList = React.useCallback(async (newSearch = false) => {
    try {
      setPagination({ ...pagination, loading: true });

      let _ret = await guestService.getGuestsWithClassificationBySchedule(
        chosenSchedule.id,
        searchControl.searchName,
        paginationRef.current,
        searchControl.orderBy,
        searchControl.order,
      );

      let _finalList = [];
      if (!newSearch) {
        _finalList = [...guestsList, ..._ret.data];
      } else {
        _finalList = [..._ret.data];
      }

      setGuestsList(_finalList);
      setGuestsListQuantity(_ret?.count || 0)

      setPagination({ ...pagination, ..._ret.pagination, loading: false });
    } catch (error) {
      console.log('Error getGuestsList', error);
      setPagination({ ...pagination, loading: false });
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  const loadMoreData = React.useCallback(async () => {
    setPagination({
      limit: pagination.limit,
      offset: pagination.limit + pagination.offset,
      hasMore: true,
      loading: true,
    });
    getGuestsList();
  });

  const openModalAddClassificationControl = React.useCallback(async (guest = GuestModel()) => {
    setModalAddClassificationControl({
      ...modalAddClassificationControlObj,
      open: true,
      guest: guest,
    });
  });

  const addGuestClassification = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: getDic("salvando") });

      await guestClassificationService.addGuestClassification(
        modalAddClassificationControl.guest.id,
        modalAddClassificationControl.stars,
        modalAddClassificationControl.note,
      );

      setModalAddClassificationControl({ ...modalAddClassificationControlObj });

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success',
      });

      resetGuestsList();

    } catch (error) {
      console.log('Error addGuestClassification', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  const openGuestDetailsPage = React.useCallback(async (guest = GuestModel()) => {
    store.dispatch(actions.setChosenGuest(guest));
    props.navigation.navigate('GuestDetailsPage');
  });

  const extractReport = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: getDic("buscando") });

      let _columnInfo = getColumnInfo();
      let _json = await getJsonData();

      await excelService.getXlsxFromObjectArray(
        _columnInfo,
        _json,
        `${getDic("relatorio")}_${moment().format('DD.MM.YYYY_HH.mm')}.xlsx`
      );

    } catch (error) {
      console.log('Error extractReport', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function getColumnInfo() {
      try {
        return [
          {
            columnName: 'id',
            text: 'ID',
            width: 60,
          },
          {
            columnName: 'userName',
            text: `${getDic("nome")} ${getDic("usuario")}`,
            width: 40,
          },
          {
            columnName: 'userEmail',
            text: `${getDic("email")} ${getDic("usuario")}`,
            width: 40,
          },
          {
            columnName: 'name',
            text: getDic("nome"),
            width: 40,
          },
          {
            columnName: 'email',
            text: getDic("email"),
            width: 60,
          },
          {
            columnName: 'phone',
            text: getDic("telefone"),
            width: 40,
          },
          {
            columnName: 'company',
            text: getDic("empresa"),
            width: 40,
          },
          {
            columnName: 'position',
            text: getDic("cargo"),
            width: 40,
          },
          {
            columnName: 'stars',
            text: getDic("classificacao"),
            width: 20,
          },
          {
            columnName: 'note',
            text: getDic("observacao"),
            width: 60,
          },
          {
            columnName: 'date',
            text: getDic("data"),
            width: 60,
          },
        ]
      } catch (error) {
        console.log('Error getColumnInfo', error);
        throw error;
      }
    }

    async function getJsonData() {
      try {
        let _array = [];

        let _hasMore = true;
        let _offset = 0;
        do {
          let _guests = await guestService.getGuestsWithClassificationBySchedule(
            chosenSchedule.id,
            '',
            {
              limit: 100,
              offset: _offset,
              hasMore: true
            },
            '',
            '',
          );

          _offset += 100;
          _array = [
            ..._array,
            ..._guests.data,
          ];
          _hasMore = _guests.pagination.hasMore;

          let _progress = (_offset / _guests.count * 100).toFixed(0);
          setLoadingControl({ ...loadingControl, open: true, message: `${getDic("buscando")} ${_progress > 100 ? 100 : _progress}%` });

        } while (_hasMore);

        return _array;

      } catch (error) {
        console.log('Error getJsonData', error);
        throw error;
      }
    }
  });

  return (
    <Container
      navigation={props.navigation}
      headerContent={
        <HeaderContent
          dimensions={dimensions}
          searchControl={searchControl}
          setSearchControl={setSearchControl}
          cleanFilterDataControl={cleanFilterDataControl}
          guestsListQuantity={guestsListQuantity}
          extractReport={extractReport}
        />
      }
      headerPadding={5}
      backButton
      scroll
    >
      <FormColumn>
        <GuestsList
          dimensions={dimensions}
          guestsList={guestsList}
          pagination={pagination}
          loadMoreData={loadMoreData}
          openModalAddClassificationControl={openModalAddClassificationControl}
          openGuestDetailsPage={openGuestDetailsPage}
        />
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (modalAddClassificationControl.open) {
      return (
        <ModalAddClassification
          dimensions={dimensions}
          modalAddClassificationControl={modalAddClassificationControl}
          setModalAddClassificationControl={setModalAddClassificationControl}
          addGuestClassification={addGuestClassification}
        />
      )
    }

    return null;
  }
};

export default ScheduleGuestsReportPage;
