export const SET_CHOSEN_SCHEDULE = 'SET_CHOSEN_SCHEDULE';
export function setChosenSchedule(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_CHOSEN_SCHEDULE,
      payload,
    });
  };
}

export const CLEAR_CHOSEN_SCHEDULE = 'CLEAR_CHOSEN_SCHEDULE';
export function clearChosenSchedule() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_CHOSEN_SCHEDULE,
    });
  };
}
