import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  Card,
  FormColumn
} from '../../components';
import { Dimensions } from 'react-native';
import UserModel from '../../models/UserModel';

import UserForm from './UserForm';
import userService from '../../services/userService';

let timer = null;

const getUserFormObj = (user = UserModel()) => {
  return {
    ...UserModel(user),
    password: '',
    confirmPassword: '',
  }
}

const AdminUserRegistrationPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
  const openAlertSuccessRef = React.useRef(false);
  React.useEffect(() => {
    openAlertSuccessRef.current = openAlertSuccess;
  }, [openAlertSuccess]);
  const [userForm, setUserForm] = React.useState(getUserFormObj());
  const [userFormError, setUserFormError] = React.useState(getUserFormObj());

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {
      if (store.getState().user.profile !== 'admin' && store.getState().user.profile !== 'inteegra') {
        if (props.navigation.canGoBack()) {
          props.navigation.goBack();
        } else {
          window.history.back();
        }
      }

      let _user = UserModel(props.route.params.user);
      setUserForm(_user);

    } catch (error) {
      console.log('error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }
  });

  const trySaveUser = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, message: getDic("salvando"), open: true });

      if (!validateForm()) {
        return;
      }

      if (userForm.id) {
        await userService.updateUser(userForm);
        await userService.updatePassword(
          userForm,
          userForm.password,
          userForm.confirmPassword,
        );
        await userService.updateProfile(userForm);
      } else {
        await userService.createUser(
          store.getState().chosenOrganization.id,
          userForm.name,
          userForm.email,
          userForm.password,
          userForm.confirmPassword,
          userForm.profile,
        );
      }

      setOpenAlertSuccess(true);

      setUserForm(getUserFormObj());

      timer = setTimeout(() => {
        closeConfirmationAlertAndNavigatePage();
      }, 2000);

    } catch (error) {
      console.log('error trySaveUser', error);
      errorTreatment(error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }

    function errorTreatment(error) {
      let _alertObj = {
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      };

      switch (error?.response?.data?.message) {
        case 'USER_IS_NOT_ADMIN':
          _alertObj.message = `${getDic("usuario")} ${getDic("nao")} Admin`;
          break;
        case 'PASSWORD_DIFFERENT_OF_CONFIRMATION':
          _alertObj.message = `${getDic("senha-confirmacao-erro")}`;
          break;
        case 'ORGANIZATION_NOT_FOUND':
          _alertObj.message = `${getDic("organizacao")} ${getDic("invalida")}`;
          break;
        case 'USER_ALREADY_EXISTS':
          _alertObj.message = `${getDic("email")} ${getDic("ja-existe")}`;
          break;
        case 'USER_IS_NOT_FROM_ORGANIZATION':
          _alertObj.message = `${getDic("organizacao")} ${getDic("invalida")}`;
          break;
        default:
          break;
      }

      setAlertControl(_alertObj);
    }

    function validateForm() {
      try {
        let _isValid = true;
        let _userFormError = getUserFormObj();

        if (!userForm.name) {
          _isValid = false;
          _userFormError.name = `${getDic("campo")} ${getDic("obrigatorio")}`;
        }
        if (!userForm.email) {
          _isValid = false;
          _userFormError.email = `${getDic("campo")} ${getDic("obrigatorio")}`;
        }
        if (!userForm.password) {
          _isValid = false;
          _userFormError.password = `${getDic("campo")} ${getDic("obrigatorio")}`;
        }
        if (!userForm.confirmPassword) {
          _isValid = false;
          _userFormError.confirmPassword = `${getDic("campo")} ${getDic("obrigatorio")}`;
        }
        if (userForm.password !== userForm.confirmPassword) {
          _isValid = false;
          _userFormError.confirmPassword = `${getDic("senha-confirmacao-erro")}`;
        }

        setUserFormError(_userFormError);

        return _isValid;
      } catch (error) {
        console.log('error validateForm', error);
        throw error;
      }
    }
  });

  const closeConfirmationAlertAndNavigatePage = React.useCallback(async () => {
    clearTimeout(timer);
    if (openAlertSuccessRef.current) {
      setOpenAlertSuccess(false);
      if (props.navigation.canGoBack()) {
        props.navigation.goBack();
      } else {
        window.history.back();
      }
    }
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      navigation={props.navigation}
      header={`${userForm.id ? getDic("editar") : getDic("cadastrar")} ${getDic("usuario")}`}
      backButton
      scroll
    >
      <FormColumn alignItems='center' padding={10}>
        <Card>
          <FormColumn width={dimensions.width < 620 ? dimensions.width - 30 : 600}>
            <UserForm
              userForm={userForm}
              setUserForm={setUserForm}
              userFormError={userFormError}
              trySaveUser={trySaveUser}
            />
          </FormColumn>
        </Card>
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (openAlertSuccess) {
      return (
        <Alert
          open={openAlertSuccess}
          title=''
          message={getDic("enviado-sucesso")}
          onPress={() => closeConfirmationAlertAndNavigatePage()}
        />
      )
    }

    return null;
  }
};

export default AdminUserRegistrationPage;
