import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  Card,
  FormColumn
} from '../../components';
import { Dimensions } from 'react-native';
import GuestModel from '../../models/GuestModel';
import QrCodeModel from '../../models/QrCodeModel';
import GuestClassificationModel from '../../models/GuestClassificationModel';
import ScheduleModel from '../../models/ScheduleModel';
import guestService from '../../services/guestService';
import qrCodeService from '../../services/qrCodeService';
import scheduleXGuestService from '../../services/scheduleXGuestService';
import guestClassificationService from '../../services/guestClassificationService';

import GuestForm from './GuestForm';

let timer = null;

const GuestRegistrationPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
  const openAlertSuccessRef = React.useRef(false);
  React.useEffect(() => {
    openAlertSuccessRef.current = openAlertSuccess;
  }, [openAlertSuccess]);
  const chosenQrCode = QrCodeModel(store.getState().chosenQrCode);
  const chosenSchedule = ScheduleModel(store.getState().chosenSchedule);
  const [guestForm, setGuestForm] = React.useState(GuestModel(store.getState().chosenGuest));
  const [guestFormError, setGuestFormError] = React.useState(GuestModel());
  const [guestClassificationForm, setGuestClassificationForm] = React.useState(GuestClassificationModel());
  const [lastClassification, setLastClassification] = React.useState(GuestClassificationModel());
  const [preSearchControl, setPreSearchControl] = React.useState({
    preSearchRealized: false,
    lastEmail: '',
  });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {
      if (guestForm.email && guestForm.id) {
        setPreSearchControl({
          preSearchRealized: true,
          lastEmail: guestForm.email,
        });

        try {
          await getLastClassification(guestForm.id);
        } catch (error) {
          console.log('Error getLastClassification');
        }
      }

    } catch (error) {
      console.log('error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }
  });

  const eraseFormData = React.useCallback(async () => {
    setGuestForm({
      ...GuestModel(),
      email: guestForm.email,
    });
    setGuestClassificationForm(GuestClassificationModel());
    setLastClassification(GuestClassificationModel());
  });

  const tryPreSearch = React.useCallback(async () => {
    try {
      if (!checkIfCanMakeSearch()) {
        return;
      }

      setLoadingControl({ ...loadingControl, message: getDic("buscando"), open: true });

      setPreSearchControl({
        preSearchRealized: true,
        lastEmail: guestForm.email,
      });

      let _guest = GuestModel();
      try {
        _guest = await guestService.getGuestByEmail(store.getState().chosenOrganization.id, guestForm.email);
        setGuestForm({ ..._guest });
      } catch (error) {
        console.log('Error getGuestByEmail', error);
        eraseFormData();
      }

      if (!_guest.id) {
        return;
      }

      try {
        await getLastClassification(_guest.id);
      } catch (error) {
        console.log('Error getLastClassification');
      }

    } catch (error) {
      console.log('error tryPreSearch', error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function checkIfCanMakeSearch() {
      if (preSearchControl.preSearchRealized && preSearchControl.lastEmail === guestForm.email) {
        return false;
      }

      if (!preSearchControl.preSearchRealized && !guestForm.email) {
        return false;
      }

      return true;
    }
  });

  const getLastClassification = React.useCallback(async (guestId = '') => {
    try {
      let _classifications = await guestClassificationService.getGuestClassifications(
        guestId,
        {
          limit: 1,
          offset: 0,
        }
      );
      if (_classifications.data.length > 0) {
        setGuestClassificationForm(_classifications.data[0]);
        setLastClassification(_classifications.data[0]);
      }
    } catch (error) {
      console.log('Error getLastClassification', error);
      throw error;
    }
  });

  const trySaveGuest = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, message: getDic("salvando"), open: true });

      if (!validateForm()) {
        return;
      }

      let _return;
      if (!guestForm.id) {
        _return = await guestService.saveGuest(
          store.getState().chosenOrganization.id,
          guestForm,
        );
      } else {
        _return = await guestService.updateGuest(
          guestForm,
        );
      }

      await scheduleXGuestService.addGuestToSchedule(_return.id, chosenSchedule.id);

      if (chosenQrCode.id && _return.id) {
        await qrCodeService.setQrCodeSync(chosenQrCode.id, _return.id);
      }

      store.dispatch(actions.setChosenGuest(_return));
      store.dispatch(actions.clearChosenQrCode());
      setGuestForm(_return);

      await checkIfCanSaveClassification(_return.id);

      setOpenAlertSuccess(true);

      timer = setTimeout(() => {
        closeConfirmationAlertAndNavigatePage();
      }, 2000);

    } catch (error) {
      console.log('error trySaveGuest', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }

    function validateForm() {
      try {
        let _isValid = true;
        let _guestFormError = GuestModel();

        if (!guestForm.name || guestForm.name === '') {
          _isValid = false;
          _guestFormError.name = `${getDic("campo")} ${getDic("obrigatorio")}`;
        }
        if (!guestForm.email || guestForm.email === '') {
          _isValid = false;
          _guestFormError.email = `${getDic("campo")} ${getDic("obrigatorio")}`;
        }

        setGuestFormError(_guestFormError);

        return _isValid;
      } catch (error) {
        console.log('error validateForm', error);
        throw error;
      }
    }

    async function checkIfCanSaveClassification(guestId = '') {
      try {
        if (guestClassificationForm.stars <= 0) { return; }
        if (
          lastClassification.id &&
          lastClassification.stars === guestClassificationForm.stars &&
          lastClassification.note === guestClassificationForm.note
        ) {
          return;
        }

        await guestClassificationService.addGuestClassification(
          guestId,
          guestClassificationForm.stars,
          guestClassificationForm.note,
        );
      } catch (error) {
        console.log('Error checkIfCanSaveClassification', error);
        throw error;
      }
    }
  });

  const closeConfirmationAlertAndNavigatePage = React.useCallback(async () => {
    clearTimeout(timer);
    if (openAlertSuccessRef.current) {
      setOpenAlertSuccess(false);
      if (props.navigation.canGoBack()) {
        props.navigation.goBack();
      } else {
        window.history.back();
      }
    }
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      navigation={props.navigation}
      header={`${guestForm.id ? getDic("editar") : getDic("cadastrar")} ${getDic("participante")}`}
      backButton
      scroll
    >
      <FormColumn alignItems='center' padding={10}>
        <Card>
          <FormColumn width={dimensions.width < 620 ? dimensions.width - 30 : 600}>
            <GuestForm
              guestForm={guestForm}
              setGuestForm={setGuestForm}
              guestFormError={guestFormError}
              trySaveGuest={trySaveGuest}
              guestClassificationForm={guestClassificationForm}
              setGuestClassificationForm={setGuestClassificationForm}
              preSearchControl={preSearchControl}
              tryPreSearch={tryPreSearch}
            />
          </FormColumn>
        </Card>
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (openAlertSuccess) {
      return (
        <Alert
          open={openAlertSuccess}
          title=''
          message={getDic("enviado-sucesso")}
          onPress={() => closeConfirmationAlertAndNavigatePage()}
        />
      )
    }

    return null;
  }
};

export default GuestRegistrationPage;
