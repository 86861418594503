import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  Icon,
  TextMedium,
  FormColumn,
  Button,
} from '../../components';

const ErrorScreen = (props) => {
  const {
    dimensions,
    clearCameraDataAndSaveClassification,
  } = props;

  return (
    <FormRow
      justifyContent='center'
      background='red'
      width='100%'
      height={dimensions.height - 60}
    >
      <FormColumn justifyContent='center' alignItems='center'>
        <Icon type="MaterialIcons" name="error-outline" size={100} color='white' />
        <TextMedium color='white'>{`${getDic("erro")} ${getDic("ao")} ${getDic("ler")} QR Code`}</TextMedium>
        <FormRow paddingTop={30} justifyContent='center'>
          <Button
            width={200}
            text='Ok'
            onPress={() => clearCameraDataAndSaveClassification()}
          />
        </FormRow>
      </FormColumn>
    </FormRow >
  )
};

export default ErrorScreen;
