import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  Card,
  FormColumn
} from '../../components';
import { Dimensions } from 'react-native';
import OrganizationModel from '../../models/OrganizationModel';
import organizationService from '../../services/organizationService';

import OrganizationForm from './OrganizationForm';

let timer = null;

const getOrganizationFormObj = (organization = OrganizationModel()) => {
  return {
    ...OrganizationModel(organization),
    password: '',
    confirmPassword: '',
  }
}

const AdminOrganizationRegistrationPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
  const openAlertSuccessRef = React.useRef(false);
  React.useEffect(() => {
    openAlertSuccessRef.current = openAlertSuccess;
  }, [openAlertSuccess]);
  const [organizationForm, setOrganizationForm] = React.useState(getOrganizationFormObj());
  const [organizationFormError, setOrganizationFormError] = React.useState(getOrganizationFormObj());

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {
      if (store.getState().user.profile !== 'inteegra') {
        if (props.navigation.canGoBack()) {
          props.navigation.goBack();
        } else {
          window.history.back();
        }
      }

      let _organization = OrganizationModel(props.route.params.organization);
      setOrganizationForm(_organization);

    } catch (error) {
      console.log('error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }
  });

  const trySaveOrganization = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, message: getDic("salvando"), open: true });

      if (!validateForm()) {
        return;
      }

      if (organizationForm.id) {
        await organizationService.updateOrganization(organizationForm);
      } else {
        await organizationService.createOrganization(organizationForm);
      }

      setOpenAlertSuccess(true);

      setOrganizationForm(getOrganizationFormObj());

      timer = setTimeout(() => {
        closeConfirmationAlertAndNavigatePage();
      }, 2000);

    } catch (error) {
      console.log('error trySaveOrganization', error);
      errorTreatment(error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }

    function errorTreatment(error) {
      let _alertObj = {
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      };

      switch (error?.response?.data?.message) {
        case 'USER_IS_NOT_INTEEGRA':
          _alertObj.message = `${getDic("contrato")} ${getDic("nao")} Inteegra`;
          break;
        case 'ORGANIZATION_ALREADY_EXISTS':
          _alertObj.message = `${getDic("organizacao")} ${getDic("ja-existe")}`;
          break;
        default:
          break;
      }

      setAlertControl(_alertObj);
    }

    function validateForm() {
      try {
        let _isValid = true;
        let _organizationFormError = getOrganizationFormObj();

        if (!organizationForm.name) {
          _isValid = false;
          _organizationFormError.name = `${getDic("campo")} ${getDic("obrigatorio")}`;
        }

        setOrganizationFormError(_organizationFormError);

        return _isValid;
      } catch (error) {
        console.log('error validateForm', error);
        throw error;
      }
    }
  });

  const closeConfirmationAlertAndNavigatePage = React.useCallback(async () => {
    clearTimeout(timer);
    if (openAlertSuccessRef.current) {
      setOpenAlertSuccess(false);
      if (props.navigation.canGoBack()) {
        props.navigation.goBack();
      } else {
        window.history.back();
      }
    }
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      navigation={props.navigation}
      header={`${organizationForm.id ? getDic("editar") : getDic("cadastrar")} ${getDic("contrato")}`}
      backButton
      scroll
    >
      <FormColumn alignItems='center' padding={10}>
        <Card>
          <FormColumn width={dimensions.width < 620 ? dimensions.width - 30 : 600}>
            <OrganizationForm
              organizationForm={organizationForm}
              setOrganizationForm={setOrganizationForm}
              organizationFormError={organizationFormError}
              trySaveOrganization={trySaveOrganization}
            />
          </FormColumn>
        </Card>
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (openAlertSuccess) {
      return (
        <Alert
          open={openAlertSuccess}
          title=''
          message={getDic("enviado-sucesso")}
          onPress={() => closeConfirmationAlertAndNavigatePage()}
          icon='success'
        />
      )
    }

    return null;
  }
};

export default AdminOrganizationRegistrationPage;
