import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FlatList,
  Button,
  Card,
  FormColumn,
  TextSmall,
  TextMedium,
} from '../../components';
import { View } from 'react-native';

const UsersScheduleList = ({
  dimensions,
  scheduleUsersList,
  removeUserFromSchedule,
  ...props
}) => {

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormRow padding={4} justifyContent='center'>
      <Card width={width}>
        <FormRow justifyContent='center'>
          <TextMedium>{getDic("usuarios")} {getDic("evento")}</TextMedium>
        </FormRow>
        {scheduleUsersList && scheduleUsersList.length > 0 && (
          <FlatList
            keyboardShouldPersistTaps='handled'
            data={scheduleUsersList}
            renderItem={renderListItem}
            keyExtractor={(item) => item.id}
            contentContainerStyle={{ paddingBottom: 10, paddingTop: 5 }}
          // onEndReached={loadMoreData}          
          // ListFooterComponent={(pagination.current.hasMore && <ActivityIndicator />)}
          />
        )}
        {scheduleUsersList && scheduleUsersList.length <= 0 && (
          <FormRow justifyContent='center'>
            <TextSmall>{getDic("pesquisa-sem-resultado")}</TextSmall>
          </FormRow>
        )}
      </Card>
    </FormRow>
  )

  function renderListItem(obj) {
    return (
      <FormRow padding={4} justifyContent='center'>
        <Card padding={0} width={width - 20} background='#f5f5f5'>
          {renderCard(obj.item)}
        </Card>
      </FormRow>
    )
  }

  function renderCard(user = UserModel()) {
    return (
      <FormColumn padding={5} width={width}>
        <FormRow padding={5}>
          <FormColumn>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Button
                  background='red'
                  onPress={() => removeUserFromSchedule(user)}
                >
                  <TextSmall color='white'>{getDic("remover")}</TextSmall>
                </Button>
              </View>
              <FormColumn>
                <FormRow>
                  <TextSmall>{user.name}</TextSmall>
                </FormRow>
                <FormRow>
                  <TextSmall>{user.email}</TextSmall>
                </FormRow>
              </FormColumn>
            </FormRow>
          </FormColumn>
        </FormRow>
      </FormColumn>
    )
  }
};

export default UsersScheduleList;
