import GuestModel from '../../models/GuestModel';
import { SET_CHOSEN_GUEST, CLEAR_CHOSEN_GUEST } from '../actions';

const INITIAL_STATE = GuestModel();

export default function chosenGuestReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CHOSEN_GUEST:
      return { ...state, ...action.payload };
    case CLEAR_CHOSEN_GUEST: ;
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
