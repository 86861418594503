import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  InputField,
  Button,
  ButtonTransparent,
  Icon,
  SelectField,
} from '../../components';

const UserForm = (props) => {
  const {
    userForm,
    setUserForm,
    userFormError,
    trySaveUser,
  } = props;

  return (
    <>
      {renderForm()}
    </>
  )

  function renderForm() {
    return (
      <FormColumn padding={10}>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={userForm.name}
            title={`${getDic("nome")} *`}
            onChange={e => setUserForm({ ...userForm, name: e })}
            error={userFormError.name !== '' ? true : false}
            errorMessage={userFormError.name}
          />
        </FormRow>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={userForm.email}
            title={`${getDic("email")} *`}
            onChange={e => setUserForm({ ...userForm, email: e })}
            keyboardType='email-address'
            error={userFormError.email !== '' ? true : false}
            errorMessage={userFormError.email}
          />
        </FormRow>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={userForm.password}
            title={`${getDic("nova")} ${getDic("senha")} *`}
            onChange={e => setUserForm({ ...userForm, password: e })}
            error={userFormError.password !== '' ? true : false}
            errorMessage={userFormError.password}
          />
        </FormRow>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={userForm.confirmPassword}
            title={`${getDic("confirmar")} ${getDic("nova")} ${getDic("senha")} *`}
            onChange={e => setUserForm({ ...userForm, confirmPassword: e })}
            error={userFormError.confirmPassword !== '' ? true : false}
            errorMessage={userFormError.confirmPassword}
          />
        </FormRow>
        <FormRow>
          <SelectField
            backgroundColor='#e8eced'
            title={`${getDic("perfil")}`}
            value={userForm.profile}
            menuItens={[
              { value: "", text: 'Staff' },
              { value: "admin", text: 'Admin' },
              { value: "inteegra", text: 'Inteegra' },
            ]}
            onChange={e => setUserForm({ ...userForm, profile: e })}
            error={userFormError.profile !== '' ? true : false}
            errorMessage={userFormError.profile}
          />
        </FormRow>

        <FormRow paddingTop={10} justifyContent='center'>
          <Button
            width={200}
            text={`${getDic("salvar")}`}
            onPress={trySaveUser}
            background='green'
          />
        </FormRow>
      </FormColumn>
    )
  }
};

export default UserForm;
