import React from "react";
import {
  View,
  StyleSheet,
  TextInput,
  Text,
  TouchableOpacity,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import Modal from "react-native-modal";
import Icon from './Icon';

const SelectField = ({
  value,
  menuItens = [{ value: false, text: 'Não' }, { value: true, text: 'Sim' }],
  title = '',
  onChange,
  onFocus,
  error = false,
  errorMessage = "",
  required = false,
  backgroundColor = "white",
  disabled = false,
  onBlur,
  onPress,
  width = '100%',
  maxWidth = undefined,
  labelColor = 'black',
  inputTextColor = 'black',
  borderColor = 'black',
  fieldHeight = 35,
  fieldFontSize = 12,
  labelFontSize = 12,
  iconSize = 18,
  ...props
}) => {

  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [focusBorder, setFocusBorder] = React.useState(false);
  const [openModalItems, setOpenModalItems] = React.useState(false);

  const inputMobile = {
    flex: 1,
    opacity: focusBorder ? 1 : 0.7,
    fontSize: fieldFontSize,
  }

  const inputWeb = {
    flex: 1,
    outlineStyle: 'none',
    opacity: focusBorder ? 1 : 0.7,
    fontSize: fieldFontSize,
  }

  React.useEffect(() => {
    getSelectedValue();
  }, [value]);

  const getSelectedValue = React.useCallback(() => {
    const _selectedValue = menuItens.find((i) => { return i.value === value });
    if (_selectedValue) {
      setSelectedValue(_selectedValue.text);
    }
    setIsLoading(false);
  });

  const localOnPress = React.useCallback((e) => {
    setOpenModalItems(true);
    if (onPress) {
      onPress(e);
    }
  });

  const styles = StyleSheet.create({
    container: {
      paddingTop: 5,
      paddingBottom: 5,
      minHeight: 45,
      width: width,
      maxWidth: maxWidth,
    },
    text: {
      fontFamily: 'Verdana',
      color: labelColor,
      fontSize: labelFontSize,
    },
    inputText: {
      fontFamily: 'Verdana',
      color: inputTextColor
    },
    input: Platform.OS === 'web' ? inputWeb : inputMobile,
    errorMessage: {
      color: 'red',
      fontSize: fieldFontSize
    },
    searchSection: {
      flexDirection: 'row',
      backgroundColor: backgroundColor,
      height: fieldHeight,
      borderBottomWidth: focusBorder ? 2 : 1,
      borderColor: error ? 'red' : borderColor,
      padding: 5,
      borderRadius: 5,
    },
    modal: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    window: {
      backgroundColor: 'white',
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 15,
      minHeight: 100,
      minWidth: 200,
      width: '100%',
      padding: 15,
      elevation: 20,
      shadowColor: '#52006A',
    },
    messageView: {
      alignItems: 'center',
      paddingBottom: 10
    },
    buttonView: {
      width: '100%',
      alignSelf: 'flex-end',
      right: 0,
      paddingTop: 10
    },
    button: {
      alignSelf: 'flex-end',
      width: 30,
      height: 20
    },
    buttonText: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 16,
      fontWeight: 'bold'
    },
    textTitle: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 18,
      fontWeight: 'bold',
      paddingBottom: 10
    },
    textMessage: {
      fontFamily: 'Verdana',
      color: '#404040',
    },
    contentContainerStyle: {
      flexGrow: 1,
      justifyContent: 'center',
    },
    listItemContainer: {
      width: '90%',
      minHeight: 40,
      alignItems: 'center',
      alignSelf: 'flex-start',
      flexDirection: 'row',
      paddingRight: 30,
      paddingBottom: 10,
    },
    listItemText: {
      fontFamily: 'Verdana',
      fontSize: 16,
    }
  });

  return (
    <View
      style={styles.container}
    >
      {title !== '' && (
        <Text style={styles.text}>{title}</Text>
      )}
      <TouchableOpacity
        style={styles.searchSection}
        onPress={localOnPress}
      >
        {!isLoading && (
          <TextInput
            style={styles.input}
            editable={false}
            placeholder={selectedValue}
            placeholderTextColor={styles.inputText.color}
            onFocus={(e) => {
              setFocusBorder(true);
              if (onFocus) {
                onFocus(e)
              }
            }}
            onBlur={(e) => {
              setFocusBorder(false);
              if (onBlur) {
                onBlur(e)
              }
            }}
          />
        )}
        <Icon type="AntDesign" name="caretdown" size={iconSize} color={styles.inputText.color} />
      </TouchableOpacity>
      {error && errorMessage !== '' && (
        <Text style={styles.errorMessage}>{errorMessage}</Text>
      )}

      <Modal
        style={styles.modal}
        isVisible={openModalItems}
        animationIn='fadeIn'
        animationOut='fadeOut'
        customBackdrop={
          <TouchableWithoutFeedback onPress={() => setOpenModalItems(false)}>
            <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)' }} />
          </TouchableWithoutFeedback>
        }
      >
        <ScrollView contentContainerStyle={styles.contentContainerStyle}>
          <View style={styles.window}>
            {menuItens.length > 0 && menuItens.map((item, i) => {
              return (
                <TouchableOpacity
                  key={i}
                  style={styles.listItemContainer}
                  onPress={() => {
                    if (onChange) {
                      onChange(item.value);
                    }
                  }}
                >
                  <View style={{ padding: 10 }}>
                    {item.value === value ? (
                      <Icon type="AntDesign" name="checkcircle" size={20} color={'blue'} />
                    ) : (
                      <Icon type="Entypo" name="circle" size={20} color={'black'} />
                    )}
                  </View>
                  <Text style={styles.listItemText}>{item.text || ''}</Text>
                </TouchableOpacity>
              )
            })}
            <View style={styles.buttonView}>
              <TouchableOpacity
                style={styles.button}
                onPress={() => setOpenModalItems(false)}
              >
                <Text style={styles.buttonText}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </Modal>
    </View>
  );
};

export default SelectField;
