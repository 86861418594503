import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  Card,
  Modal,
  FormColumn,
  FormRow,
  ButtonTransparent,
  Icon,
  InputField,
  Button,
  TextMedium,
} from '../../components';

const ModalAddClassification = (props) => {
  const {
    dimensions,
    modalAddClassificationControl,
    setModalAddClassificationControl,
    addGuestClassification,
  } = props;

  return (
    <Modal
      open={modalAddClassificationControl.open}
      backdropPress={() => setModalAddClassificationControl({ ...modalAddClassificationControl, open: false })}
    >
      <Card>
        <FormColumn padding={5} minWidth={dimensions.width < 350 ? null : 300}>
          <FormRow justifyContent='flex-end'>
            <ButtonTransparent
              onPress={() => setModalAddClassificationControl({ ...modalAddClassificationControl, open: false })}
            >
              <Icon type="AntDesign" name="close" size={20} color={'black'} />
            </ButtonTransparent>
          </FormRow>
          <FormRow paddingTop={5} justifyContent='center'>
            <TextMedium>
              {modalAddClassificationControl.guest.name}
            </TextMedium>
          </FormRow>
          <FormRow paddingTop={5}>
            {renderStarsInput()}
          </FormRow>
          <FormRow paddingTop={5}>
            <InputField
              backgroundColor='#e8eced'
              title={`${getDic("observacao")}`}
              value={modalAddClassificationControl.note}
              onChange={(e) => setModalAddClassificationControl({ ...modalAddClassificationControl, note: e })}
            />
          </FormRow>
          <FormRow padding={10} justifyContent='center'>
            <Button
              width={200}
              text={`${getDic("classificar")}`}
              onPress={addGuestClassification}
              background='green'
            />
          </FormRow>
        </FormColumn>
      </Card>
    </Modal>
  )

  function renderStarsInput() {
    return (
      <FormRow justifyContent='center'>
        <ButtonTransparent
          padding={0}
          onPress={() => setModalAddClassificationControl({ ...modalAddClassificationControl, stars: 1 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={modalAddClassificationControl.stars >= 1 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setModalAddClassificationControl({ ...modalAddClassificationControl, stars: 2 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={modalAddClassificationControl.stars >= 2 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setModalAddClassificationControl({ ...modalAddClassificationControl, stars: 3 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={modalAddClassificationControl.stars >= 3 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setModalAddClassificationControl({ ...modalAddClassificationControl, stars: 4 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={modalAddClassificationControl.stars >= 4 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setModalAddClassificationControl({ ...modalAddClassificationControl, stars: 5 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={modalAddClassificationControl.stars >= 5 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
      </FormRow>
    )
  }
};

export default ModalAddClassification;
