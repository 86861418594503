import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  FlatList,
  ActivityIndicator,
  Icon,
  Card,
  TextSmall,
  Button,
  ButtonTransparent,
} from '../../components';
import ScheduleModel from '../../models/ScheduleModel';
import { View } from 'react-native';
import moment from 'moment';

const SchedulesList = (props) => {
  const {
    dimensions,
    schedulesList,
    loadMoreData,
    pagination,
    openPageEditSchedule,
    openDialogDeleteSchedule,
    openScheduleUsersPage,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <>
      {schedulesList && schedulesList.length > 0 && (
        <FlatList
          keyboardShouldPersistTaps='handled'
          data={schedulesList}
          renderItem={renderListItem}
          keyExtractor={(item) => item.id}
          contentContainerStyle={{ paddingBottom: 10, paddingTop: 5 }}
        // onEndReached={loadMoreData}          
        // ListFooterComponent={(pagination.current.hasMore && <ActivityIndicator />)}
        />
      )}
      {schedulesList.length > 0 && pagination.hasMore && !pagination.loading && (
        <FormRow paddingBottom={10} justifyContent='center'>
          <FormColumn width={200}>
            <Button
              text={`${getDic("carregar")} ${getDic("mais")}`}
              onPress={() => loadMoreData()}
            />
          </FormColumn>
        </FormRow>
      )}
      {pagination.loading && (
        <FormRow padding={10} justifyContent='center'>
          <ActivityIndicator />
        </FormRow>
      )}
    </>
  )

  function renderListItem(obj) {
    return (
      <FormRow padding={4} justifyContent='center'>
        <Card padding={0} width={width}>
          {renderCard(obj.item)}
        </Card>
      </FormRow>
    )
  }

  function renderCard(schedule = ScheduleModel()) {
    return (
      <FormColumn padding={5} width={width}>
        <FormRow padding={5}>
          <FormColumn width='100%' justifyContent='center'>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Icon type="MaterialIcons" name="event" size={17} color='#456091' />
              </View>
              <TextSmall>{schedule.eventId} - {schedule.eventName}</TextSmall>
            </FormRow>
          </FormColumn>
        </FormRow>
        <FormRow padding={5}>
          <FormColumn width='100%'>
            <TextSmall fontWeight='bold'>{getDic("programacao")}</TextSmall>
            <TextSmall>{schedule.scheduleName}</TextSmall>
          </FormColumn>
        </FormRow>
        <FormRow padding={5}>
          <FormColumn width='50%'>
            <TextSmall fontWeight='bold'>{getDic("data")} {getDic("inicio")}</TextSmall>
            <TextSmall>{moment(schedule.startDate).format('DD/MM/YYYY - HH:mm')}</TextSmall>
          </FormColumn>
          <FormColumn width='50%' alignItems='flex-end'>
            <TextSmall fontWeight='bold'>{getDic("data")} {getDic("fim")}</TextSmall>
            <TextSmall>{moment(schedule.endDate).format('DD/MM/YYYY - HH:mm')}</TextSmall>
          </FormColumn>
        </FormRow>

        {renderCommandButtons(schedule)}
      </FormColumn>
    )
  }

  function renderCommandButtons(schedule = ScheduleModel()) {
    return (
      <FormRow padding={5} justifyContent='flex-end'>
        <FormColumn paddingLeft={15}>
          <ButtonTransparent
            padding={0}
            onPress={() => openScheduleUsersPage(schedule)}
          >
            <FormRow>
              <FormColumn paddingRight={3}>
                <TextSmall color='#737373'>{getDic("usuarios")}</TextSmall>
              </FormColumn>
              <Icon type="AntDesign" name="user" size={15} color='#737373' />
            </FormRow>
          </ButtonTransparent>
        </FormColumn>
        <FormColumn paddingLeft={15}>
          <ButtonTransparent
            padding={0}
            onPress={() => openPageEditSchedule(schedule)}
          >
            <FormRow>
              <FormColumn paddingRight={3}>
                <TextSmall color='#456091'>{getDic("editar")}</TextSmall>
              </FormColumn>
              <Icon type="Feather" name="edit" size={15} color='#456091' />
            </FormRow>
          </ButtonTransparent>
        </FormColumn>
        <FormColumn paddingLeft={15}>
          <ButtonTransparent
            padding={0}
            onPress={() => openDialogDeleteSchedule(schedule)}
          >
            <FormRow>
              <FormColumn paddingRight={3}>
                <TextSmall color='red'>{getDic("inativar")}</TextSmall>
              </FormColumn>
              <Icon type="FontAwesome" name="trash-o" size={15} color='red' />
            </FormRow>
          </ButtonTransparent>
        </FormColumn>
      </FormRow>
    )
  }
};

export default SchedulesList;
