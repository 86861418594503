import React from 'react';
import {
  FormRow,
} from '../../components';
import { View } from 'react-native';

import InteegraLogo from './InteegraLogo';

const AppVersionText = (props) => {
  const {
    dimensions,
  } = props;

  return (
    <View style={{
      height: (dimensions.height / 2),
      backgroundColor: '#456091',
      borderBottomStartRadius: 50,
      borderBottomEndRadius: 50,
      marginBottom: -(dimensions.height / 4)
    }}>
      <View style={{ paddingTop: (dimensions.height / 10) }}>
        <InteegraLogo />
      </View>
    </View>
  )
};

export default AppVersionText;
