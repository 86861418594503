const obj = {
  id: '',
  scheduleId: '',
  qrCode: '',
}

export default function QrCodeModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};
