import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  Icon,
  TextMedium,
  FormColumn,
  TextSmall,
  Card,
} from '../../components';
import { View } from 'react-native';
import moment from 'moment';

const GuestDetails = (props) => {
  const {
    guest,
    lastClassification,
  } = props;

  return (
    <>
      <FormRow>
        <FormColumn paddingRight={10}>
          <Icon type="Ionicons" name="person" size={20} color='#456091' />
        </FormColumn>
        <FormColumn>
          <TextMedium>{guest.name}</TextMedium>
        </FormColumn>
      </FormRow>
      <FormRow paddingTop={5}>
        <FormColumn paddingRight={10}>
          <Icon type="MaterialIcons" name="email" size={20} color='#456091' />
        </FormColumn>
        <FormColumn>
          <TextMedium>{guest.email}</TextMedium>
        </FormColumn>
      </FormRow>
      {guest.phone !== '' && (
        <FormRow paddingTop={5}>
          <FormColumn paddingRight={10}>
            <Icon type="Entypo" name="phone" size={20} color='#456091' />
          </FormColumn>
          <FormColumn>
            <TextMedium>{guest.phone}</TextMedium>
          </FormColumn>
        </FormRow>
      )}
      {guest.company !== '' && (
        <FormRow paddingTop={5}>
          <FormColumn paddingRight={10}>
            <Icon type="FontAwesome5" name="building" size={20} color='#456091' />
          </FormColumn>
          <FormColumn>
            <TextMedium>{guest.company}</TextMedium>
          </FormColumn>
        </FormRow>
      )}
      {guest.position !== '' && (
        <FormRow paddingTop={5}>
          <FormColumn paddingRight={10}>
            <Icon type="MaterialCommunityIcons" name="desk" size={20} color='#456091' />
          </FormColumn>
          <FormColumn>
            <TextMedium>{guest.position}</TextMedium>
          </FormColumn>
        </FormRow>
      )}
      {lastClassification?.id !== '' && (
        <View style={{ padding: 3 }}>
          <Card backgroundColor='#e3e3e3'>
            <FormRow justifyContent='space-between'>
              <TextSmall color='#5c5c5c'>{moment(lastClassification.date).format('DD/MM/YYYY HH:mm')}</TextSmall>
            </FormRow>
            <FormRow justifyContent='space-between'>
              <View>
                {renderStars()}
              </View>
              <TextSmall color='#5c5c5c'>{lastClassification.note}</TextSmall>
            </FormRow>
          </Card>
        </View>
      )}
    </>
  )

  function renderStars() {
    return (
      <FormRow>
        {lastClassification.stars >= 1 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {lastClassification.stars >= 2 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {lastClassification.stars >= 3 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {lastClassification.stars >= 4 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {lastClassification.stars >= 5 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
      </FormRow>
    )
  }
};

export default GuestDetails;
