export const SET_CHOSEN_GUEST = 'SET_CHOSEN_GUEST';
export function setChosenGuest(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_CHOSEN_GUEST,
      payload,
    });
  };
}

export const CLEAR_CHOSEN_GUEST = 'CLEAR_CHOSEN_GUEST';
export function clearChosenGuest() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_CHOSEN_GUEST,
    });
  };
}
