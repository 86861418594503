import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import GuestModel from '../../models/GuestModel';
import guestClassificationService from '../../services/guestClassificationService';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  Card,
  FormColumn
} from '../../components';
import { Dimensions } from 'react-native';
import GuestClassificationModel from '../../models/GuestClassificationModel';
import { useFocusEffect } from '@react-navigation/native';

import ClassificationHistory from './ClassificationHistory';
import CommandButtons from './CommandButtons';
import GuestDetails from './GuestDetails';
import ModalAddClassification from './ModalAddClassification';

const modalAddClassificationControlObj = {
  open: false,
  stars: 1,
  note: '',
}

const GuestDetailsPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [guest, setGuest] = React.useState(GuestModel(store.getState().chosenGuest));
  const [lastClassification, setLastClassification] = React.useState(GuestClassificationModel());
  const [classificationHistoryControl, setClassificationHistoryControl] = React.useState({
    open: false,
    isLoading: false,
    classifications: [],
  });
  const [modalAddClassificationControl, setModalAddClassificationControl] = React.useState({ ...modalAddClassificationControlObj });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      getPageData();
      setGuest(GuestModel(store.getState().chosenGuest));
    }, [props.navigation])
  );

  const getPageData = React.useCallback(async () => {
    try {
      let _classifications = await guestClassificationService.getGuestClassifications(
        guest.id,
        {
          limit: 1,
          offset: 0,
        }
      );
      if (_classifications.data.length > 0) {
        setLastClassification(_classifications.data[0]);
      }

    } catch (error) {
      console.log('error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }
  });

  const getGuestClassifications = React.useCallback(async () => {
    let _classificationHistoryControl = {
      ...classificationHistoryControl,
      isLoading: true,
      open: true,
    };

    try {
      setClassificationHistoryControl(_classificationHistoryControl);

      let _classifications = await guestClassificationService.getGuestClassifications(
        guest.id,
        {
          limit: 10,
          offset: 0,
        }
      );
      if (_classifications.data.length > 0) {
        _classificationHistoryControl.classifications = _classifications.data;
      }

    } catch (error) {
      console.log('error getGuestClassifications', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });

    } finally {
      setClassificationHistoryControl({
        ..._classificationHistoryControl,
        isLoading: false,
      });
    }
  });

  const openModalAddClassificationControl = React.useCallback(async (guest = GuestModel()) => {
    setModalAddClassificationControl({
      ...modalAddClassificationControlObj,
      open: true,
    });
  });

  const addGuestClassification = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: getDic("salvando") });

      let _classification = await guestClassificationService.addGuestClassification(
        guest.id,
        modalAddClassificationControl.stars,
        modalAddClassificationControl.note,
      );

      setModalAddClassificationControl({ ...modalAddClassificationControlObj });
      setLastClassification(_classification);

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success',
      });

    } catch (error) {
      console.log('Error addGuestClassification', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  const openGuestRegistrationPage = React.useCallback(async () => {
    store.dispatch(actions.setChosenGuest(guest));
    store.dispatch(actions.clearChosenQrCode());
    props.navigation.navigate('GuestRegistrationPage');
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      navigation={props.navigation}
      header={`${getDic("detalhes")} ${getDic("participante")}`}
      backButton
      scroll
    >
      <FormColumn alignItems='center' padding={10}>
        <Card>
          <FormColumn width={dimensions.width < 620 ? dimensions.width - 30 : 600}>
            <GuestDetails
              guest={guest}
              lastClassification={lastClassification}
            />
            <CommandButtons
              openModalAddClassificationControl={openModalAddClassificationControl}
              openGuestRegistrationPage={openGuestRegistrationPage}
            />
            <ClassificationHistory
              classificationHistoryControl={classificationHistoryControl}
              setClassificationHistoryControl={setClassificationHistoryControl}
              getGuestClassifications={getGuestClassifications}
            />
          </FormColumn>
        </Card>
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (modalAddClassificationControl.open) {
      return (
        <ModalAddClassification
          dimensions={dimensions}
          modalAddClassificationControl={modalAddClassificationControl}
          setModalAddClassificationControl={setModalAddClassificationControl}
          addGuestClassification={addGuestClassification}
          guest={guest}
        />
      )
    }

    return null;
  }
};

export default GuestDetailsPage;
