import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  Icon,
  TextSmall,
  Button,
} from '../../components';

const CommandButtons = (props) => {
  const {
    openModalAddClassificationControl,
    openGuestRegistrationPage,
  } = props;

  return (
    <FormRow padding={5} justifyContent='flex-end'>
      <FormColumn paddingLeft={5}>
        <Button
          onPress={() => openGuestRegistrationPage()}
        >
          <FormRow>
            <FormColumn paddingRight={5}>
              <Icon type="Entypo" name="edit" size={12} color='white' />
            </FormColumn>
            <TextSmall color='white'>{getDic("editar")}</TextSmall>
          </FormRow>
        </Button>
      </FormColumn>
      <FormColumn paddingLeft={5}>
        <Button
          background='green'
          onPress={() => openModalAddClassificationControl()}
        >
          <FormRow>
            <FormColumn paddingRight={5}>
              <Icon type="MaterialIcons" name="star-rate" size={14} color='white' />
            </FormColumn>
            <TextSmall color='white'>{getDic("classificar")}</TextSmall>
          </FormRow>
        </Button>
      </FormColumn>
    </FormRow>
  )
};

export default CommandButtons;
