import React from 'react';
import {
  FormRow,
  Image
} from '../../components';

const InteegraLogoButton = () => {

  return (
    <FormRow padding={20}>
      <Image height={100} file={require('../../assets/logo-inteegra.png')} />
    </FormRow>
  )
};

export default InteegraLogoButton;
