import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { Dimensions } from 'react-native';
import {
  Container,
  FormColumn,
  Loading,
  Alert,
  Dialog,
} from '../../components';
import guestService from '../../services/guestService';
import { useFocusEffect } from '@react-navigation/native';
import GuestModel from '../../models/GuestModel';

import GuestsList from './GuestsList';
import HeaderContent from './HeaderContent';

const AdminGuestsListPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
    hasMore: true,
    loading: false,
  });
  const paginationRef = React.useRef();
  React.useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);
  const [guestsList, setGuestsList] = React.useState([]);
  const [guestsListQuantity, setGuestsListQuantity] = React.useState(0);
  const [searchControl, setSearchControl] = React.useState({
    searchName: '',
    searchEmail: '',
    orderBy: 'name',
    order: 'ASC',
  });
  const lastSearchStringUpdate = React.useRef(Date.now());
  const [dialogGuestControl, setDialogGuestControl] = React.useState({
    open: false,
    title: '',
    message: '',
    command: '',
    guestId: '',
  });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  React.useEffect(() => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setGuestsList([]);
    lastSearchStringUpdate.current = Date.now();
    setTimeout(() => {
      if (lastSearchStringUpdate.current + 500 < Date.now()) {
        getGuestsList(true);
      }
    }, 500);
  }, [searchControl]);

  const getPageData = React.useCallback(async () => {
    try {
      if (store.getState().user.profile !== 'admin' && store.getState().user.profile !== 'inteegra') {
        if (props.navigation.canGoBack()) {
          props.navigation.goBack();
        } else {
          window.history.back();
        }
      }

    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  useFocusEffect(
    React.useCallback(() => {
      resetGuestsList();
    }, [props.navigation])
  );

  const resetGuestsList = React.useCallback(async () => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setGuestsList([]);
    setTimeout(() => {
      getGuestsList(true);
    }, 500);
  });

  const cleanFilterDataControl = React.useCallback(async () => {
    setSearchControl({
      searchName: '',
      searchEmail: '',
      orderBy: 'name',
      order: 'ASC',
    });
  });

  const getGuestsList = React.useCallback(async (newSearch = false) => {
    try {
      setPagination({ ...pagination, loading: true });

      let _ret = await guestService.getGuests(
        store.getState().chosenOrganization.id,
        searchControl.searchName,
        paginationRef.current,
        searchControl.orderBy,
        searchControl.order,
      );

      let _finalList = [];
      if (!newSearch) {
        _finalList = [...guestsList, ..._ret.data];
      } else {
        _finalList = [..._ret.data];
      }

      setGuestsList(_finalList);
      setGuestsListQuantity(_ret?.count || 0)

      setPagination({ ...pagination, ..._ret.pagination, loading: false });
    } catch (error) {
      console.log('Error getGuestsList', error);
      setPagination({ ...pagination, loading: false });
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  const loadMoreData = React.useCallback(async () => {
    setPagination({
      limit: pagination.limit,
      offset: pagination.limit + pagination.offset,
      hasMore: true,
      loading: true,
    });
    getGuestsList();
  });

  const openPageEditGuest = React.useCallback(async (guest = GuestModel()) => {
    props.navigation.navigate('GuestRegistrationPage', { guest: guest });
  });

  const openDialogDeleteGuest = React.useCallback(async (guest = GuestModel()) => {
    setDialogGuestControl({
      ...dialogGuestControl,
      open: true,
      title: getDic("atencao"),
      message: `${getDic("deletar")} ${getDic("usuario")} '${guest.name} - ${guest.email}'. ${getDic("confirmacao-acao")}`,
      command: 'delete',
      guestId: guest.id
    });
  });

  const tryDeleteGuest = React.useCallback(async () => {
    try {
      setDialogGuestControl({ ...dialogGuestControl, open: false });

      setLoadingControl({ ...loadingControl, open: true, message: getDic("excluindo") });

      await guestService.inactiveGuest(dialogGuestControl.guestId);

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success',
      });

      resetGuestsList();

    } catch (error) {
      console.log('Error tryDeleteGuest', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  return (
    <Container
      navigation={props.navigation}
      headerContent={
        <>
          <HeaderContent
            dimensions={dimensions}
            searchControl={searchControl}
            setSearchControl={setSearchControl}
            cleanFilterDataControl={cleanFilterDataControl}
            guestsListQuantity={guestsListQuantity}
          />
        </>
      }
      headerPadding={5}
      backButton
      scroll
    >
      <FormColumn>
        <GuestsList
          dimensions={dimensions}
          guestsList={guestsList}
          pagination={pagination}
          loadMoreData={loadMoreData}
          openDialogDeleteGuest={openDialogDeleteGuest}
        />
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (dialogGuestControl.open) {
      return (
        <Dialog
          open={dialogGuestControl.open}
          title={dialogGuestControl.title}
          message={dialogGuestControl.message}
          icon='danger'
          button1OnPress={() => tryDeleteGuest()}
          button2OnPress={() => setDialogGuestControl({ ...dialogGuestControl, open: false })}
          backdropPress={() => setDialogGuestControl({ ...dialogGuestControl, open: false })}
        />
      )
    }

    return null;
  }
};

export default AdminGuestsListPage;
