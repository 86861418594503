import DeviceInfo from 'react-native-device-info';

export default {
  async isIos() {
    let _return = false;
    try {
      const _device = await DeviceInfo.getManufacturer();
      if (_device && _device.toUpperCase() === "APPLE" || _device.toUpperCase() === "IOS") {
        _return = true;
      }
    } catch (error) {
      console.log('error DeviceInfo', error);
    }
    return _return;
  },

  isTablet() {
    return DeviceInfo.isTablet();
  },

  isWeb() {
    let _deviceId = DeviceInfo.getDeviceId();
    if (_deviceId === 'unknown') {
      return true;
    } else {
      return false;
    }
  }
}
