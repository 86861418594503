import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Container,
  FormColumn,
  Loading,
  Alert,
} from '../../components';
import { Dimensions } from 'react-native';
// import EventModel from '../../models/EventModel';
import ScheduleModel from '../../models/ScheduleModel';
import scheduleService from '../../services/scheduleService';
import moment from 'moment';

import ScheduleForm from './ScheduleForm';

const getFormObject = (schedule = ScheduleModel()) => {
  return {
    ...schedule,
    startHour: '',
    endHour: '',
  }
};

const ScheduleRegisterPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [scheduleForm, setScheduleForm] = React.useState(getFormObject());
  const [scheduleFormError, setScheduleFormError] = React.useState({
    hasError: false,
    form: getFormObject()
  });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {
      if (store.getState().user.profile !== 'admin' && store.getState().user.profile !== 'inteegra') {
        if (props.navigation.canGoBack()) {
          props.navigation.goBack();
        } else {
          window.history.back();
        }
      }

      let _schedule = getFormObject(props.route.params.schedule);
      _schedule.startHour = _schedule.startDate ? moment(_schedule.startDate).format('HH:mm') : '';
      _schedule.endHour = _schedule.endDate ? moment(_schedule.endDate).format('HH:mm') : '';
      _schedule.startDate = _schedule.startDate ? moment(_schedule.startDate).format('DD/MM/YYYY') : '';
      _schedule.endDate = _schedule.endDate ? moment(_schedule.endDate).format('DD/MM/YYYY') : '';
      setScheduleForm(_schedule);

    } catch (error) {
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  const trySaveSchedule = React.useCallback(async () => {
    try {
      setLoadingControl({ open: true, message: getDic("salvando") });
      const _errorMessage = validateForm();
      if (_errorMessage) {
        setAlertControl({
          open: true,
          title: getDic("erro"),
          message: _errorMessage,
          icon: 'error'
        });
        return;
      }

      let _scheduleForm = ScheduleModel({
        ...scheduleForm,
        startDate: moment(`${scheduleForm.startDate} ${scheduleForm.startHour}`, 'DD/MM/YYYY HH:mm').format(),
        endDate: moment(`${scheduleForm.endDate} ${scheduleForm.endHour}`, 'DD/MM/YYYY HH:mm').format(),
      });

      if (!_scheduleForm.id) {
        await scheduleService.createSchedule(_scheduleForm);
      } else {
        await scheduleService.updateSchedule(_scheduleForm);
      }

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success',
      });

      setScheduleForm(getFormObject());

    } catch (error) {
      console.log('Error trySaveSchedule', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function validateForm() {
      try {
        if (!scheduleForm.eventId) {
          return `${getDic("campo")} 'ID ${getDic("evento")}' ${getDic("obrigatorio")}`;
        }
        if (!scheduleForm.eventName) {
          return `${getDic("campo")} '${getDic("nome")} ${getDic("evento")}' ${getDic("obrigatorio")}`;
        }
        if (!scheduleForm.scheduleId) {
          return `${getDic("campo")} 'ID ${getDic("programacao")}' ${getDic("obrigatorio")}`;
        }
        if (!scheduleForm.scheduleName) {
          return `${getDic("campo")} '${getDic("nome")} ${getDic("programacao")}' ${getDic("obrigatorio")}`;
        }
        if (!scheduleForm.startDate || scheduleForm.startDate.length !== 10) {
          return `${getDic("campo")} '${getDic("data")} ${getDic("inicio")}' ${getDic("invalido")}`;
        }
        if (!scheduleForm.endDate || scheduleForm.endDate.length !== 10) {
          return `${getDic("campo")} '${getDic("data")} ${getDic("inicio")}' ${getDic("invalido")}`;
        }
        if (!scheduleForm.startHour || scheduleForm.startHour.length !== 5) {
          return `${getDic("campo")} '${getDic("hora")} ${getDic("inicio")}' ${getDic("invalido")}`;
        }
        if (!scheduleForm.endHour || scheduleForm.endHour.length !== 5) {
          return `${getDic("campo")} '${getDic("hora")} ${getDic("fim")}' ${getDic("invalido")}`;
        }

        return '';
      } catch (error) {
        console.log('Error validateForm', error);
        throw error;
      }
    }
  });

  return (
    <Container
      navigation={props.navigation}
      header={`${scheduleForm.id ? getDic("editar") : getDic("cadastrar")} ${getDic("programacao")}`}
      backButton
      scroll
    >
      <FormColumn padding={10}>
        <ScheduleForm
          dimensions={dimensions}
          scheduleForm={scheduleForm}
          setScheduleForm={setScheduleForm}
          trySaveSchedule={trySaveSchedule}
        />
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default ScheduleRegisterPage;
