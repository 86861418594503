import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { Dimensions } from 'react-native';
import {
  Container,
  FormColumn,
  Loading,
  Alert,
  Dialog,
} from '../../components';
import userService from '../../services/userService';
import { useFocusEffect } from '@react-navigation/native';
import UserModel from '../../models/UserModel';

import FabButtonNewUser from './FabButtonNewUser';
import UsersList from './UsersList';
import HeaderContent from './HeaderContent';

const AdminUsersListPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
    hasMore: true,
    loading: false,
  });
  const paginationRef = React.useRef();
  React.useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);
  const [usersList, setUsersList] = React.useState([]);
  const [usersListQuantity, setUsersListQuantity] = React.useState(0);
  const [searchControl, setSearchControl] = React.useState({
    searchName: '',
    searchEmail: '',
    orderBy: 'name',
    order: 'ASC',
  });
  const lastSearchStringUpdate = React.useRef(Date.now());
  const [dialogUserControl, setDialogUserControl] = React.useState({
    open: false,
    title: '',
    message: '',
    command: '',
    userId: '',
  });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  React.useEffect(() => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setUsersList([]);
    lastSearchStringUpdate.current = Date.now();
    setTimeout(() => {
      if (lastSearchStringUpdate.current + 500 < Date.now()) {
        getUsersList(true);
      }
    }, 500);
  }, [searchControl]);

  const getPageData = React.useCallback(async () => {
    try {
      if (store.getState().user.profile !== 'admin' && store.getState().user.profile !== 'inteegra') {
        if (props.navigation.canGoBack()) {
          props.navigation.goBack();
        } else {
          window.history.back();
        }
      }

    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  useFocusEffect(
    React.useCallback(() => {
      resetUsersList();
    }, [props.navigation])
  );

  const resetUsersList = React.useCallback(async () => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setUsersList([]);
    setTimeout(() => {
      getUsersList(true);
    }, 500);
  });

  const cleanFilterDataControl = React.useCallback(async () => {
    setSearchControl({
      searchName: '',
      searchEmail: '',
      orderBy: 'name',
      order: 'ASC',
    });
  });

  const getUsersList = React.useCallback(async (newSearch = false) => {
    try {
      setPagination({ ...pagination, loading: true });

      let _ret = await userService.getUsersWithoutInteegra(
        store.getState().chosenOrganization.id,
        searchControl.searchName,
        paginationRef.current,
        searchControl.orderBy,
        searchControl.order,
      );

      let _finalList = [];
      if (!newSearch) {
        _finalList = [...usersList, ..._ret.data];
      } else {
        _finalList = [..._ret.data];
      }

      setUsersList(_finalList);
      setUsersListQuantity(_ret?.count || 0)

      setPagination({ ...pagination, ..._ret.pagination, loading: false });
    } catch (error) {
      console.log('Error getUsersList', error);
      setPagination({ ...pagination, loading: false });
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  const loadMoreData = React.useCallback(async () => {
    setPagination({
      limit: pagination.limit,
      offset: pagination.limit + pagination.offset,
      hasMore: true,
      loading: true,
    });
    getUsersList();
  });

  const openPageNewUser = React.useCallback(async () => {
    props.navigation.navigate('AdminUserRegistrationPage', { user: UserModel() });
  });

  const openPageEditUser = React.useCallback(async (user = UserModel()) => {
    props.navigation.navigate('AdminUserRegistrationPage', { user: user });
  });

  const openDialogDeleteUser = React.useCallback(async (user = UserModel()) => {
    setDialogUserControl({
      ...dialogUserControl,
      open: true,
      title: getDic("atencao"),
      message: `${getDic("deletar")} ${getDic("usuario")} '${user.name} - ${user.email}'. ${getDic("confirmacao-acao")}`,
      command: 'delete',
      userId: user.id
    });
  });

  const tryDeleteUser = React.useCallback(async () => {
    try {
      setDialogUserControl({ ...dialogUserControl, open: false });

      setLoadingControl({ ...loadingControl, open: true, message: getDic("excluindo") });

      await userService.inactiveUser(dialogUserControl.userId);

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success',
      });

      resetUsersList();

    } catch (error) {
      console.log('Error tryDeleteUser', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  return (
    <Container
      navigation={props.navigation}
      headerContent={
        <>
          <HeaderContent
            dimensions={dimensions}
            searchControl={searchControl}
            setSearchControl={setSearchControl}
            cleanFilterDataControl={cleanFilterDataControl}
            usersListQuantity={usersListQuantity}
          />
        </>
      }
      headerSubContent={
        <FabButtonNewUser
          openPageNewUser={openPageNewUser}
        />
      }
      headerPadding={5}
      backButton
      scroll
    >
      <FormColumn>
        <UsersList
          dimensions={dimensions}
          usersList={usersList}
          pagination={pagination}
          loadMoreData={loadMoreData}
          openPageEditUser={openPageEditUser}
          openDialogDeleteUser={openDialogDeleteUser}
        />
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (dialogUserControl.open) {
      return (
        <Dialog
          open={dialogUserControl.open}
          title={dialogUserControl.title}
          message={dialogUserControl.message}
          icon='danger'
          button1OnPress={() => tryDeleteUser()}
          button2OnPress={() => setDialogUserControl({ ...dialogUserControl, open: false })}
          backdropPress={() => setDialogUserControl({ ...dialogUserControl, open: false })}
        />
      )
    }

    return null;
  }
};

export default AdminUsersListPage;
