import React from 'react';
import { StyleSheet, Image } from 'react-native';

const ImageProp = ({
  file,
  url,
  width = null,
  padding = 0,
  paddingTop = padding && padding !== 0 ? padding : 0,
  paddingBottom = padding && padding !== 0 ? padding : 0,
  paddingLeft = padding && padding !== 0 ? padding : 0,
  paddingRight = padding && padding !== 0 ? padding : 0,
  height = null,
  minHeight = undefined,
  ...props
}) => {

  const styles = StyleSheet.create({
    image: {
      width: width,
      paddingTop: paddingTop,
      paddingBottom: paddingBottom,
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      height: height,
      minHeight: minHeight,
      resizeMode: 'contain',
      flex: 1,
    }
  });

  return (
    <Image
      style={styles.image}
      source={file ? file : {
        uri: url
      }}
    />
  )
};

export default ImageProp;
