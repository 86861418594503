import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { Dimensions } from 'react-native';
import {
  Container,
  FormColumn,
  FormRow,
  Loading,
  Alert,
} from '../../components';
import * as DocumentPicker from "expo-document-picker";
import moment from 'moment';
import excelService from '../../services/excelService';
import UserImporterModel from '../../models/UserImporterModel';
import importerService from '../../services/importerService';

import CommandButtons from './CommandButtons';
import Instructions from './Instructions';

const AdminUsersListPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {
      if (store.getState().user.profile !== 'inteegra') {
        if (props.navigation.canGoBack()) {
          props.navigation.goBack();
        } else {
          window.history.back();
        }
      }

    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  const exportExcelSheet = React.useCallback(async () => {
    try {
      excelService.getXlsxMultisheetFromObjectArray({
        sheets: [
          getDataTable(),
          getInstructionsTable(),
        ],
        fileName: `${getDic("importador")}_${moment().format('DD.MM.YYYY_HH.mm')}.xlsx`
      })
    } catch (error) {
      console.log('Error exportExcelSheet', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function getDataTable() {
      const _columns = [];
      const _importerObj = UserImporterModel();

      for (var key in _importerObj) {
        let _text = '';
        switch (key) {
          case 'organizationName':
            _text = `${getDic("nome")} ${getDic("organizacao")}`;
            break;
          case 'userName':
            _text = `${getDic("nome")} ${getDic("usuario")}`;
            break;
          case 'userEmail':
            _text = `${getDic("email")} ${getDic("usuario")}`;
            break;
          case 'userPassword':
            _text = `${getDic("senha")} ${getDic("usuario")}`;
            break;
          case 'eventId':
            _text = `ID ${getDic("evento")}`;
            break;
          case 'scheduleId':
            _text = `ID ${getDic("programacao")}`;
            break;
          case 'eventName':
            _text = `${getDic("nome")} ${getDic("evento")}`;
            break;
          case 'scheduleName':
            _text = `${getDic("nome")} ${getDic("programacao")}`;
            break;
          case 'startDate':
            _text = `${getDic("data")} ${getDic("inicio")}`;
            break;
          case 'endDate':
            _text = `${getDic("data")} ${getDic("fim")}`;
            break;
          default:
            _text = '';
            break;
        }

        _columns.push({
          columnName: key,
          text: _text,
          width: 20,
        })
      }

      return {
        columnInfo: _columns,
        controlLine: true,
        sheetName: `${getDic("dados")}`,
        array: [_importerObj],
      }
    }

    function getInstructionsTable() {
      const _columns = [
        {
          columnName: 'column',
          text: getDic("coluna"),
          width: 20,
        },
        {
          columnName: 'instructions',
          text: getDic("instrucoes"),
          width: 20,
        }
      ];

      const _data = [
        {
          column: 'organizationName',
          instructions: 'Nome da Organização (Stand)'
        },
        {
          column: 'userName',
          instructions: 'Nome do Usuário'
        },
        {
          column: 'userEmail',
          instructions: 'Email do Usuário'
        },
        {
          column: 'userPassword',
          instructions: 'Senha de acesso do usuário'
        },
        {
          column: 'eventId',
          instructions: 'ID do Evento'
        },
        {
          column: 'scheduleId',
          instructions: 'ID da Programação'
        },
        {
          column: 'eventName',
          instructions: 'Nome do Evento'
        },
        {
          column: 'scheduleName',
          instructions: 'Nome da Programação'
        },
        {
          column: 'startDate',
          instructions: 'Data e hora de início formato DD/MM/AAAA HH:MM'
        },
        {
          column: 'endDate',
          instructions: 'Data e hora de encerramento formato DD/MM/AAAA HH:MM'
        },
      ];

      return {
        columnInfo: _columns,
        sheetName: `${getDic("instrucoes")}`,
        array: _data,
      }
    }
  });

  const importExcelSheet = React.useCallback(async () => {
    const baseObject = UserImporterModel();

    try {
      setLoadingControl({ ...loadingControl, open: true, message: getDic("salvando") });

      let _file = await DocumentPicker.getDocumentAsync({
        type: '.xlsx'
      });

      if (_file.canceled) {
        return;
      }

      let _data = await excelService.readExcelFileAndReturnJson(_file.assets[0].file);

      if (!checkIfAllColumnsAreOnSheet(_data)) { return; }

      let _objectArray = checkIfAllDataAreSetCorrectelyAndConvertToObjectArray(_data);
      if (!_objectArray) { return; }

      await saveObjectArray(_objectArray);

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success'
      });

    } catch (error) {
      console.log('Error importExcelSheet', error);
      errorTreatment(error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function errorTreatment(error) {
      let _errorMessage = getDic("enviado-erro");

      if (error?.response?.data?.message) {
        switch (error.response.data.message) {
          case 'USER_IS_NOT_INTEEGRA':
            _errorMessage = `${getDic("usuario-nao-autorizado")}`;
            break;
          case 'USER_ALREADY_EXISTS_IN_ANOTHER_ORGANIZATION':
            _errorMessage = `${getDic("email")} ${getDic("ja-existe").toLowerCase()} ${getDic("em")} ${getDic("outro").toLowerCase()} ${getDic("contrato").toLowerCase()}`;
            break;
          default:
            break;
        }
      }

      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: _errorMessage,
        icon: 'error',
      });
    }

    function checkIfAllColumnsAreOnSheet(data) {
      try {
        for (var key in baseObject) {
          let _column = data[0].find(v => { return v === key });
          if (!_column) {
            setAlertControl({
              open: true,
              title: getDic("erro"),
              message: `${getDic("coluna")} "${key}" ${getDic("nao")} ${getDic("localizada")}`,
              icon: 'error'
            });
            return false;
          }
        }
        return true;
      } catch (error) {
        console.log('Error checkIfAllColumnsAreOnSheet', error);
        throw error;
      }
    }

    function checkIfAllDataAreSetCorrectelyAndConvertToObjectArray(data) {
      try {
        const _objectArray = [];

        for (let i = 2; i < data.length; i++) {
          let _item = UserImporterModel();
          const _row = data[i];
          for (var key in baseObject) {
            let _index = data[0].findIndex(v => { return v === key });
            try {
              _item[key] = getData(key, _row[_index]);
            } catch (error) {
              setAlertControl({
                open: true,
                title: getDic("erro"),
                message: `${getDic("coluna")} "${key}" - ${getDic("linha")} "${i + 1}": ${getDic("valor")} ${getDic("invalido")}`,
                icon: 'error'
              });
              return null;
            }
          }
          _objectArray.push(_item);
        }
        return _objectArray;
      } catch (error) {
        console.log('Error checkIfAllDataAreSetCorrectelyAndConvertToObjectArray', error);
        throw error;
      }

      function getData(key, fieldData) {
        try {
          if (!fieldData) {
            throw new Error('NO_VALUE');
          }

          if (key === 'startDate' || key === 'endDate') {
            if (!moment(fieldData).isValid()) {
              throw new Error('INVALID_DATE');
            } else {
              return moment(fieldData).toISOString();
            }
          }

          return fieldData;
        } catch (error) {
          console.log('Error getData', error);
          throw error;
        }
      }
    }

    async function saveObjectArray(objectArray = []) {
      try {
        let _count = 0;
        do {
          setLoadingControl({ ...loadingControl, open: true, message: `${getDic("salvando")} ${_count + 1} ${getDic("de")} ${objectArray.length}` });

          await importerService.inportOrganizationAndUserAndSchedule(objectArray[_count]);
          _count++;
        } while (_count < objectArray.length);
      } catch (error) {
        console.log('Error saveObjectArray', error);
        throw error;
      }
    }
  });

  return (
    <Container
      navigation={props.navigation}
      header={`${getDic("importador")} ${getDic("de")} ${getDic("eventos")}`}
      backButton
      scroll
    >
      <FormColumn justifyContent='center' padding={10} height={dimensions.height - 70}>
        <Instructions
          dimensions={dimensions}
        />
        <FormRow justifyContent='center'>
          <CommandButtons
            dimensions={dimensions}
            exportExcelSheet={exportExcelSheet}
            importExcelSheet={importExcelSheet}
          />
        </FormRow>
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default AdminUsersListPage;
