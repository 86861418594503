import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  Icon,
  InputField,
  ButtonTransparent,
  TextSmall,
  Button,
  RadioButton,
} from '../../components';

const HeaderContent = (props) => {
  const {
    dimensions,
    searchControl,
    setSearchControl,
    cleanFilterDataControl,
    usersListQuantity,
  } = props;

  return (
    <FormColumn>
      <FormRow paddingLeft={10} width={dimensions.width - 80}>
        <InputField
          fieldHeight={25}
          fieldFontSize={12}
          iconLeft={<FormColumn paddingRight={5}><Icon type="FontAwesome" name="search" size={15} color='gray' /></FormColumn>}
          value={searchControl.searchName}
          placeholder={`${getDic("buscar")} ${getDic("usuario")}`}
          onChange={v => setSearchControl({ ...searchControl, searchName: v })}
        />
        <FormColumn width={40}>
          <ButtonTransparent onPress={() => cleanFilterDataControl()}>
            <Icon type="Ionicons" name="refresh" size={18} color='white' />
          </ButtonTransparent>
        </FormColumn>
      </FormRow>
      <FormRow background="#456091" paddingLeft={10} flexWrap='wrap'>
        <FormColumn width='50%' paddingRight={5}>
          <FormRow>
            <TextSmall color='white'>{`${getDic("ordenacao")}`}</TextSmall>
          </FormRow>
          <FormRow>
            <FormColumn paddingRight={5}>
              <RadioButton
                color='white'
                active={searchControl.order === 'ASC' ? true : false}
                onPress={() => setSearchControl({ ...searchControl, order: 'ASC' })}
              />
            </FormColumn>
            <FormColumn justifyContent='center'>
              <TextSmall color='white'>A-Z</TextSmall>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn paddingRight={5}>
              <RadioButton
                color='white'
                active={searchControl.order === 'DESC' ? true : false}
                onPress={() => setSearchControl({ ...searchControl, order: 'DESC' })}
              />
            </FormColumn>
            <FormColumn justifyContent='center'>
              <TextSmall color='white'>Z-A</TextSmall>
            </FormColumn>
          </FormRow>
        </FormColumn>
        <FormColumn width='50%' paddingLeft={5}>
          <FormRow>
            <TextSmall color='white'>{`${getDic("ordenar")} ${getDic("por")}`}</TextSmall>
          </FormRow>
          <FormRow>
            <FormColumn paddingRight={5}>
              <RadioButton
                color='white'
                active={searchControl.orderBy === 'name' ? true : false}
                onPress={() => setSearchControl({ ...searchControl, orderBy: 'name' })}
              />
            </FormColumn>
            <FormColumn justifyContent='center'>
              <TextSmall color='white'>{`${getDic("nome")}`}</TextSmall>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn paddingRight={5}>
              <RadioButton
                color='white'
                active={searchControl.orderBy === 'email' ? true : false}
                onPress={() => setSearchControl({ ...searchControl, orderBy: 'email' })}
              />
            </FormColumn>
            <FormColumn justifyContent='center'>
              <TextSmall color='white'>{`${getDic("email")}`}</TextSmall>
            </FormColumn>
          </FormRow>
        </FormColumn>
      </FormRow>
      <FormRow width={dimensions.width - 80} justifyContent='center' flexWrap='wrap'>
        <FormColumn justifyContent='center'>
          <TextSmall color='white'>{`${getDic("usuarios")} ${getDic("encontrados")}: ${usersListQuantity}`}</TextSmall>
        </FormColumn>
      </FormRow>
    </FormColumn>
  )
};

export default HeaderContent;
