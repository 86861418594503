import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  InputField,
  Button,
  ButtonTransparent,
  Icon,
} from '../../components';

const OrganizationForm = (props) => {
  const {
    organizationForm,
    setOrganizationForm,
    organizationFormError,
    trySaveOrganization,
  } = props;

  return (
    <>
      {renderForm()}
    </>
  )

  function renderForm() {
    return (
      <FormColumn padding={10}>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={organizationForm.name}
            title={`${getDic("nome")} *`}
            onChange={e => setOrganizationForm({ ...organizationForm, name: e })}
            error={organizationFormError.name !== '' ? true : false}
            errorMessage={organizationFormError.name}
          />
        </FormRow>

        <FormRow paddingTop={10} justifyContent='center'>
          <Button
            width={200}
            text={`${getDic("salvar")}`}
            onPress={trySaveOrganization}
            background='green'
          />
        </FormRow>
      </FormColumn>
    )
  }
};

export default OrganizationForm;
