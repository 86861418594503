import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  Card,
  FormColumn
} from '../../components';
import { Dimensions } from 'react-native';
import UserModel from '../../models/UserModel';
import userService from '../../services/userService';
import { useFocusEffect } from '@react-navigation/native';

import UsersList from './UsersList';
import UsersScheduleList from './UsersScheduleList';

let timer = null;

const AdminUserRegistrationPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
    hasMore: true,
    loading: false,
  });
  const paginationRef = React.useRef();
  React.useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);
  const [usersList, setUsersList] = React.useState([]);
  const [usersListQuantity, setUsersListQuantity] = React.useState(0);
  const [searchControl, setSearchControl] = React.useState({
    searchName: '',
    searchEmail: '',
    orderBy: 'name',
    order: 'ASC',
  });
  const lastSearchStringUpdate = React.useRef(Date.now());
  const [scheduleUsersList, setScheduleUsersList] = React.useState([]);

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  React.useEffect(() => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setUsersList([]);
    lastSearchStringUpdate.current = Date.now();
    setTimeout(() => {
      if (lastSearchStringUpdate.current + 500 < Date.now()) {
        getUsersList(true);
      }
    }, 500);
  }, [searchControl]);

  const getPageData = React.useCallback(async () => {
    try {
      if (store.getState().user.profile !== 'admin' && store.getState().user.profile !== 'inteegra') {
        if (props.navigation.canGoBack()) {
          props.navigation.goBack();
        } else {
          window.history.back();
        }
      }

      const _ret = await userService.getUsersBySchedule(
        store.getState().chosenSchedule.id,
        '',
        {
          limit: 50,
          offset: 0,
          hasMore: true,
        },
        'name',
        'ASC',
      );

      setScheduleUsersList(_ret.data);

    } catch (error) {
      console.log('error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }
  });

  useFocusEffect(
    React.useCallback(() => {
      resetUsersList();
    }, [props.navigation])
  );

  const resetUsersList = React.useCallback(async () => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setUsersList([]);
    setTimeout(() => {
      getUsersList(true);
    }, 500);
  });

  const cleanFilterDataControl = React.useCallback(async () => {
    setSearchControl({
      searchName: '',
      searchEmail: '',
      orderBy: 'name',
      order: 'ASC',
    });
  });

  const getUsersList = React.useCallback(async (newSearch = false) => {
    try {
      setPagination({ ...pagination, loading: true });

      let _ret = await userService.getUsers(
        store.getState().chosenOrganization.id,
        searchControl.searchName,
        paginationRef.current,
        searchControl.orderBy,
        searchControl.order,
      );

      let _finalList = [];
      if (!newSearch) {
        _finalList = [...usersList, ..._ret.data];
      } else {
        _finalList = [..._ret.data];
      }

      setUsersList(_finalList);
      setUsersListQuantity(_ret?.count || 0)

      setPagination({ ...pagination, ..._ret.pagination, loading: false });
    } catch (error) {
      console.log('Error getUsersList', error);
      setPagination({ ...pagination, loading: false });
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  const getScheduleUsersList = React.useCallback(async () => {
    try {
      const _ret = await userService.getUsersBySchedule(
        store.getState().chosenSchedule.id,
        '',
        {
          limit: 50,
          offset: 0,
          hasMore: true,
        },
        'name',
        'ASC',
      );

      setScheduleUsersList(_ret.data);
    } catch (error) {
      console.log('Error getUsersList', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
    finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  const loadMoreData = React.useCallback(async () => {
    setPagination({
      limit: pagination.limit,
      offset: pagination.limit + pagination.offset,
      hasMore: true,
      loading: true,
    });
    getUsersList();
  });

  const addUserToSchedule = React.useCallback(async (user = UserModel()) => {
    try {
      setLoadingControl({ ...loadingControl, message: getDic("salvando"), open: true });

      await userService.addUserToSchedule(user.id, store.getState().chosenSchedule.id);

      resetUsersList();

      await getScheduleUsersList();

    } catch (error) {
      console.log('Error addUserToSchedule', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  const removeUserFromSchedule = React.useCallback(async (user = UserModel()) => {
    try {
      setLoadingControl({ ...loadingControl, message: getDic("salvando"), open: true });

      await userService.removeUserFromSchedule(user.id, store.getState().chosenSchedule.id);

      resetUsersList();

      await getScheduleUsersList();

    } catch (error) {
      console.log('Error removeUserFromSchedule', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      navigation={props.navigation}
      header={`${getDic("usuarios")} ${store.getState().chosenSchedule.eventName}`}
      backButton
      scroll
    >
      <UsersScheduleList
        dimensions={dimensions}
        scheduleUsersList={scheduleUsersList}
        removeUserFromSchedule={removeUserFromSchedule}
      />
      <UsersList
        dimensions={dimensions}
        usersList={usersList}
        pagination={pagination}
        loadMoreData={loadMoreData}
        scheduleUsersList={scheduleUsersList}
        searchControl={searchControl}
        setSearchControl={setSearchControl}
        addUserToSchedule={addUserToSchedule}
      />

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default AdminUserRegistrationPage;
