import writeXlsxFile from 'write-excel-file';
import readXlsxFile from 'read-excel-file';

export default {
  async getXlsxFromObjectArray(
    columnInfo = [{
      columnName: '',
      text: '',
      width: 20,
    }],
    array = [],
    fileName = ''
  ) {
    try {
      const _headerRow = getHeaderRow();
      const _dataRows = getDataRows();

      await writeXlsxFile([
        _headerRow,
        ..._dataRows
      ], {
        fileName: fileName,
      });

    } catch (error) {
      console.log('Error getXlsxFromJson', error);
      throw error;
    }

    function getHeaderRow() {
      try {
        const _headerRow = [];

        if (array.length > 0) {
          for (var key in array[0]) {
            let _column = columnInfo.find(h => h.columnName === key);
            _headerRow.push({
              value: _column.text,
              fontWeight: 'bold',
              backgroundColor: '#bfbfbf',
            })
          }
        }

        return _headerRow;
      } catch (error) {
        console.log('Error getHeaderRow', error);
        throw error;
      }
    }

    function getDataRows() {
      try {
        const _dataRows = [];

        for (var item of array) {
          const _row = [];
          for (var key in item) {
            let _column = columnInfo.find(h => h.columnName === key);
            _row.push({
              type: String,
              value: item[key] ? item[key].toString() : '',
              width: _column.width,
            });
          }
          _dataRows.push(_row)
        }

        return _dataRows;
      } catch (error) {
        console.log('Error getDataRows', error);
        throw error;
      }
    }
  },

  async getXlsxMultisheetFromObjectArray(
    params = {
      sheets: [
        {
          columnInfo: [{
            columnName: '',
            text: '',
            width: 20,
          }],
          controlLine: false,
          sheetName: '',
          array: [],
        }
      ],
      fileName: ''
    }
  ) {
    try {
      const _sheets = [];
      const _sheetNames = [];

      for (let i = 0; i < params.sheets.length; i++) {
        const _sheet = params.sheets[i];

        const _headerRow = getHeaderRow(_sheet.columnInfo, _sheet.array);
        const _dataRows = getDataRows(_sheet.columnInfo, _sheet.array);

        let _sheetData = [];
        if (_sheet.controlLine) {
          const _controlRow = getControlRow(_sheet.array);

          _sheetData = [
            _controlRow,
            _headerRow,
            ..._dataRows
          ]
        } else {
          _sheetData = [
            _headerRow,
            ..._dataRows
          ]
        }

        _sheets.push(_sheetData);

        _sheetNames.push(_sheet.sheetName);
      }

      await writeXlsxFile(_sheets, {
        sheets: _sheetNames,
        fileName: params.fileName,
      });

    } catch (error) {
      console.log('Error getXlsxFromJson', error);
      throw error;
    }

    function getHeaderRow(columnInfo, array) {
      try {
        const _headerRow = [];

        if (array.length > 0) {
          for (var key in array[0]) {
            let _column = columnInfo.find(h => h.columnName === key);
            _headerRow.push({
              value: _column.text,
              fontWeight: 'bold',
              backgroundColor: '#bfbfbf',
              borderColor: '#000000',
              borderStyle: 'thin',
            })
          }
        }

        return _headerRow;
      } catch (error) {
        console.log('Error getHeaderRow', error);
        throw error;
      }
    }

    function getControlRow(array) {
      try {
        const _headerRow = [];

        if (array.length > 0) {
          for (var key in array[0]) {
            _headerRow.push({
              value: key,
              backgroundColor: '#e3e3e3',
              borderColor: '#000000',
              borderStyle: 'thin',
            })
          }
        }

        return _headerRow;
      } catch (error) {
        console.log('Error getControlRow', error);
        throw error;
      }
    }

    function getDataRows(columnInfo, array) {
      try {
        const _dataRows = [];

        for (var item of array) {
          const _row = [];
          for (var key in item) {
            let _column = columnInfo.find(h => h.columnName === key);
            _row.push({
              type: String,
              value: item[key] ? item[key].toString() : '',
              width: _column.width,
            });
          }
          _dataRows.push(_row)
        }

        return _dataRows;
      } catch (error) {
        console.log('Error getDataRows', error);
        throw error;
      }
    }
  },

  async readExcelFileAndReturnJson(file) {
    try {
      let _data = await readXlsxFile(file);
      return _data;
    } catch (error) {
      console.log('Error readExcelFileAndReturnJson', error);
      throw error;
    }
  }
}
