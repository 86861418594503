import * as React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import {
  Button,
  Text,
  View,
  StyleSheet,
  Platform
} from 'react-native';
import deviceService from '../services/deviceService';
import { Camera } from 'expo-camera';
import FormRow from './FormRow';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { isMobile } from 'react-device-detect';

const QrCodeScanner = ({
  onScann,
  onCancel,
  isScanning = false,
  textColor = 'black',
  useFrontCamera = false,
  disableBackButton = false,
  ...props
}) => {

  const [hasPermission, setHasPermission] = React.useState(null);
  const [flash, setFlash] = React.useState(Camera.Constants.FlashMode.off);

  React.useEffect(() => {
    requestQrPermission();
  }, []);

  const requestQrPermission = React.useCallback(async () => {
    if (Platform.OS !== "web") {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    } else {
      setHasPermission('granted');
    }
  });

  const handleBarCodeScanned = React.useCallback((params) => {
    if (isScanning && onScann && params.data) {
      onScann(params.data);
    }
  });

  const checkIfCanRenderLightButton = React.useCallback(() => {
    if (Platform.OS === "web" && isMobile) {
      return true;
    }
    if (Platform.OS === "android" || Platform.OS === "ios") {
      return true;
    }
    return false;
  });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    scann: {
      height: '90%',
      width: '100%',
    },
    targetBox: {
      position: 'absolute',
      alignSelf: 'center',
      justifyContent: 'center',
      height: 200,
      width: 200,
      borderColor: 'green',
      borderWidth: 1
    },
    text: {
      color: textColor
    }
  });

  if (hasPermission === null) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{getDic("qrcode-requisita-camera")}</Text>
      </View>
    );
  }
  if (hasPermission === false) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{getDic("qrcode-sem-acesso-camera")}</Text>
        <Button
          title={getDic("voltar").toUpperCase()}
          onPress={() => {
            if (onCancel) {
              onCancel();
            }
          }}
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <>
        {isScanning && (
          <Camera
            type={useFrontCamera ? 'front' : 'back'}
            style={styles.scann}
            onBarCodeScanned={handleBarCodeScanned}
            flashMode={flash}
            barCodeScannerSettings={{
              barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
            }}
          />
        )}
      </>
      {renderFooter()}
      <View style={styles.targetBox} />
    </View >
  )

  function renderFooter() {
    return (
      <FormRow padding={5} justifyContent='center'>
        {!disableBackButton && (
          <View style={{ padding: 5 }}>
            <Button
              title={getDic("voltar").toUpperCase()}
              onPress={() => {
                if (onCancel) {
                  onCancel();
                }
              }}
            />
          </View>
        )}
        {checkIfCanRenderLightButton() && (
          <View style={{ padding: 5 }}>
            <Button
              title={getDic("lanterna").toUpperCase()}
              onPress={() => {
                setFlash(
                  flash === Camera.Constants.FlashMode.off
                    ? Camera.Constants.FlashMode.torch
                    : Camera.Constants.FlashMode.off);
              }}
            />
          </View>
        )}
      </FormRow>
    )
  }
};

export default QrCodeScanner;
