import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  InputField,
  Button,
  ButtonTransparent,
  Icon,
  FormColumn,
} from '../../components';

const GuestForm = (props) => {
  const {
    guestForm,
    setGuestForm,
    guestFormError,
    trySaveGuest,
    guestClassificationForm,
    setGuestClassificationForm,
    preSearchControl,
    tryPreSearch,
  } = props;

  return (
    <>
      {renderForm()}
    </>
  )

  function renderForm() {
    return (
      <FormColumn padding={5}>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={guestForm.email}
            title={`${getDic("email")} *`}
            onChange={e => setGuestForm({ ...guestForm, email: e })}
            onBlur={() => {
              setGuestForm({ ...guestForm, email: guestForm.email?.toLowerCase() });
              tryPreSearch();
            }}
            keyboardType='email-address'
            error={guestFormError.email !== '' ? true : false}
            errorMessage={guestFormError.email}
          />
        </FormRow>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={guestForm.name}
            title={`${getDic("nome")} *`}
            onChange={e => setGuestForm({ ...guestForm, name: e })}
            error={guestFormError.name !== '' ? true : false}
            errorMessage={guestFormError.name}
            editable={preSearchControl.preSearchRealized}
          />
        </FormRow>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={guestForm.phone}
            title={`${getDic("telefone")}`}
            keyboardType='phone-pad'
            onChange={e => setGuestForm({ ...guestForm, phone: e })}
            error={guestFormError.phone !== '' ? true : false}
            errorMessage={guestFormError.phone}
            editable={preSearchControl.preSearchRealized}
          />
        </FormRow>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={guestForm.company}
            title={`${getDic("empresa")}`}
            onChange={e => setGuestForm({ ...guestForm, company: e })}
            error={guestFormError.company !== '' ? true : false}
            errorMessage={guestFormError.company}
            editable={preSearchControl.preSearchRealized}
          />
        </FormRow>
        <FormRow>
          <InputField
            backgroundColor='#e8eced'
            value={guestForm.position}
            title={`${getDic("cargo")}`}
            onChange={e => setGuestForm({ ...guestForm, position: e })}
            error={guestFormError.position !== '' ? true : false}
            errorMessage={guestFormError.position}
            editable={preSearchControl.preSearchRealized}
          />
        </FormRow>
        {preSearchControl.preSearchRealized && (
          <>
            <FormRow paddingTop={5}>
              {renderStarsInput()}
            </FormRow>
            <FormRow paddingTop={5}>
              <InputField
                backgroundColor='#e8eced'
                title={`${getDic("observacao")}`}
                value={guestClassificationForm.note}
                onChange={(e) => setGuestClassificationForm({ ...guestClassificationForm, note: e })}
              />
            </FormRow>
          </>
        )}
        <FormRow paddingTop={10} justifyContent='center'>
          <Button
            width={200}
            text={`${getDic("salvar")}`}
            onPress={trySaveGuest}
            background='green'
          />
        </FormRow>
      </FormColumn>
    )
  }

  function renderStarsInput() {
    return (
      <FormRow justifyContent='center'>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 1 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 1 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 2 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 2 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 3 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 3 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 4 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 4 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 5 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 5 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
      </FormRow>
    )
  }
};

export default GuestForm;
