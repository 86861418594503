import axios from 'axios';
import apiService from './apiService';
import OrganizationModel from '../models/OrganizationModel';

export default {
  async changeCurrentOrganization(organization = OrganizationModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}organization/changeCurrentOrganization`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          organizationId: organization.id,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return OrganizationModel(_ret.data.data);

    } catch (error) {
      console.log('Error changeCurrentOrganization', error);
      throw error;
    }
  },

  async createOrganization(organization = OrganizationModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}organization/createOrganization`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          name: organization.name,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return OrganizationModel(_ret.data.data);

    } catch (error) {
      console.log('Error createOrganization', error);
      throw error;
    }
  },

  async deleteOrganization(organizationId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}organization/inactiveOrganization`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          id: organizationId,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error deleteOrganization', error);
      throw error;
    }
  },

  async getOrganizations(
    searchString = '',
    pagination = {
      limit: 10,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'name',
    order = 'DESC',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _token = await apiService.getUserToken();

      const _options = {
        url: `${apiService.getUrl().accreditatioStand}organization/getOrganizations?offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        }
      };

      const _ret = await axios(_options);
      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(OrganizationModel({
          ...item
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;

    } catch (error) {
      console.log('Error getOrganizations', error);
      throw error;
    }
  },

  async updateOrganization(organization = OrganizationModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}organization/updateOrganization`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          id: organization.id,
          name: organization.name,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return OrganizationModel(_ret.data.data);

    } catch (error) {
      console.log('Error updateOrganization', error);
      throw error;
    }
  },
}
