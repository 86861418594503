import ScheduleModel from '../../models/ScheduleModel';
import { SET_CHOSEN_SCHEDULE, CLEAR_CHOSEN_SCHEDULE } from '../actions';

const INITIAL_STATE = ScheduleModel();

export default function chosenScheduleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CHOSEN_SCHEDULE:
      return { ...state, ...action.payload };
    case CLEAR_CHOSEN_SCHEDULE: ;
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
