import axios from 'axios';
import apiService from './apiService';
import GuestClassificationModel from '../models/GuestClassificationModel';

export default {
  async addGuestClassification(guestId = '', stars = 0, note = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}guestClassification/createGuestClassification`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          guestId: guestId,
          stars: stars,
          note: note
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return GuestClassificationModel({
        ..._ret.data.data,
        date: _ret.data.data.createdAt,
      });

    } catch (error) {
      console.log('Error addGuestClassification', error);
      throw error;
    }
  },

  async getGuestClassifications(
    guestId = '',
    pagination = {
      limit: 0,
      offset: 0,
      hasMore: true,
    },
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        average: 0,
        pagination: pagination
      }

      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}guestClassification/getGuestClassifications?guestId=${guestId}&offset=${pagination.offset}&limit=${pagination.limit}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(GuestClassificationModel({
          id: item.id,
          date: item.createdAt,
          note: item.note,
          stars: item.stars,
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;

    } catch (error) {
      console.log('Error getGuestClassifications', error);
      throw error;
    }
  },
}
