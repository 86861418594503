import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Container,
  FormColumn,
  Loading,
  Alert,
} from '../../components';
import { Dimensions } from 'react-native';
import scheduleService from '../../services/scheduleService';
import { useFocusEffect } from '@react-navigation/native';

import HeaderContent from './HeaderContent';
import SchedulesList from './SchedulesList';

const HomePage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [currentOrganization, setCurrentOrganization] = React.useState(store.getState().chosenOrganization.name);
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
    hasMore: true,
    loading: false,
  });
  const paginationRef = React.useRef();
  React.useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);
  const [schedulesList, setSchedulesList] = React.useState([]);
  const [schedulesListQuantity, setSchedulesListQuantity] = React.useState(0);
  const [searchControl, setSearchControl] = React.useState({
    searchName: '',
    searchEmail: '',
    orderBy: 'start_date',
    order: 'DESC',
  });
  const lastSearchStringUpdate = React.useRef(Date.now());

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  React.useEffect(() => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setSchedulesList([]);
    lastSearchStringUpdate.current = Date.now();
    setTimeout(() => {
      if (lastSearchStringUpdate.current + 500 < Date.now()) {
        getSchedulesList(true);
      }
    }, 500);
  }, [searchControl]);

  useFocusEffect(
    React.useCallback(() => {
      setCurrentOrganization(store.getState().chosenOrganization.name);
      cleanFilterDataControl();
    }, [props.navigation])
  );

  const getPageData = React.useCallback(async () => {
    try {
      setCurrentOrganization(store.getState().chosenOrganization.name);

    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  const openEventPage = React.useCallback(async (schedule = ScheduleModel()) => {
    try {
      store.dispatch(actions.setChosenSchedule(schedule));

      props.navigation.navigate('ScheduleDetailPage');
    } catch (error) {
      console.log('Error openEventPage', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  const getSchedulesList = React.useCallback(async (newSearch = false) => {
    try {
      if (paginationRef.current.loading) { return; }

      setPagination({ ...pagination, loading: true });

      let _ret;
      if (store.getState().user.profile === 'inteegra' || store.getState().user.profile === 'admin') {
        _ret = await scheduleService.getSchedulesByOrganization(
          store.getState().chosenOrganization.id,
          searchControl.searchName,
          paginationRef.current,
          searchControl.orderBy,
          searchControl.order,
        );
      } else {
        _ret = await scheduleService.getSchedules(
          store.getState().chosenOrganization.id,
          searchControl.searchName,
          paginationRef.current,
          searchControl.orderBy,
          searchControl.order,
        );
      }

      let _finalList = [];
      if (!newSearch) {
        _finalList = [...schedulesList, ..._ret.data];
      } else {
        _finalList = [..._ret.data];
      }

      setSchedulesList(_finalList);
      setSchedulesListQuantity(_ret?.count || 0)

      setPagination({ ...pagination, ..._ret.pagination, loading: false });
    } catch (error) {
      console.log('Error getSchedulesList', error);
      setPagination({ ...pagination, loading: false });
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  const loadMoreData = React.useCallback(async () => {
    setPagination({
      limit: pagination.limit,
      offset: pagination.limit + pagination.offset,
      hasMore: true,
      loading: true,
    });
    getSchedulesList();
  });

  const cleanFilterDataControl = React.useCallback(async () => {
    setSearchControl({
      searchName: '',
      searchEmail: '',
      orderBy: 'start_date',
      order: 'DESC',
    });
  });

  return (
    <Container
      navigation={props.navigation}
      headerContent={
        <HeaderContent
          dimensions={dimensions}
          searchControl={searchControl}
          setSearchControl={setSearchControl}
          cleanFilterDataControl={cleanFilterDataControl}
          schedulesListQuantity={schedulesListQuantity}
          currentOrganization={currentOrganization}
        />
      }
      headerPadding={5}
      scroll
      menu
    >
      <FormColumn>
        <SchedulesList
          dimensions={dimensions}
          schedulesList={schedulesList}
          pagination={pagination}
          loadMoreData={loadMoreData}
          openEventPage={openEventPage}
        />
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default HomePage;
