import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  FlatList,
  ActivityIndicator,
  Icon,
  Card,
  TextSmall,
  Button,
  ButtonTransparent,
} from '../../components';
import OrganizationModel from '../../models/OrganizationModel';
import { View } from 'react-native';
import moment from 'moment';

const OrganizationsList = (props) => {
  const {
    dimensions,
    organizationsList,
    loadMoreData,
    pagination,
    openPageEditOrganization,
    openDialogDeleteOrganization,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <>
      {organizationsList && organizationsList.length > 0 && (
        <FlatList
          keyboardShouldPersistTaps='handled'
          data={organizationsList}
          renderItem={renderListItem}
          keyExtractor={(item) => item.id}
          contentContainerStyle={{ paddingBottom: 10, paddingTop: 5 }}
        // onEndReached={loadMoreData}          
        // ListFooterComponent={(pagination.current.hasMore && <ActivityIndicator />)}
        />
      )}
      {organizationsList.length > 0 && pagination.hasMore && !pagination.loading && (
        <FormRow paddingBottom={10} justifyContent='center'>
          <FormColumn width={200}>
            <Button
              text={`${getDic("carregar")} ${getDic("mais")}`}
              onPress={() => loadMoreData()}
            />
          </FormColumn>
        </FormRow>
      )}
      {pagination.loading && (
        <FormRow padding={10} justifyContent='center'>
          <ActivityIndicator />
        </FormRow>
      )}
    </>
  )

  function renderListItem(obj) {
    return (
      <FormRow padding={4} justifyContent='center'>
        <Card padding={0} width={width}>
          {renderCard(obj.item)}
        </Card>
      </FormRow>
    )
  }

  function renderCard(organization = OrganizationModel()) {
    return (
      <FormColumn padding={5} width={width}>
        <FormRow padding={5}>
          <FormColumn width='100%' justifyContent='center'>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Icon type="FontAwesome5" name="file-signature" size={15} color='#456091' />
              </View>
              <TextSmall>{organization.name}</TextSmall>
            </FormRow>
          </FormColumn>
        </FormRow>

        {renderCommandButtons(organization)}
      </FormColumn>
    )
  }

  function renderCommandButtons(organization = OrganizationModel()) {
    return (
      <FormRow padding={5} justifyContent='flex-end'>
        <FormColumn paddingLeft={15}>
          <ButtonTransparent
            padding={0}
            onPress={() => openPageEditOrganization(organization)}
          >
            <FormRow>
              <FormColumn paddingRight={3}>
                <TextSmall color='#456091'>{getDic("editar")}</TextSmall>
              </FormColumn>
              <Icon type="Feather" name="edit" size={15} color='#456091' />
            </FormRow>
          </ButtonTransparent>
        </FormColumn>
        <FormColumn paddingLeft={15}>
          <ButtonTransparent
            padding={0}
            onPress={() => openDialogDeleteOrganization(organization)}
          >
            <FormRow>
              <FormColumn paddingRight={3}>
                <TextSmall color='red'>{getDic("inativar")}</TextSmall>
              </FormColumn>
              <Icon type="FontAwesome" name="trash-o" size={15} color='red' />
            </FormRow>
          </ButtonTransparent>
        </FormColumn>
      </FormRow>
    )
  }
};

export default OrganizationsList;
