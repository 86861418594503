import React from 'react';
import {
  FormRow,
  TextMedium
} from '../../components';
import moment from 'moment';
import appJson from '../../../app.json';

const AppVersionText = (props) => {
  return (
    <FormRow justifyContent='center' paddingTop={20}>
      <TextMedium color='gray'>
        {`© ${moment().format('YYYY')} Inteegra - V ${appJson.expo.version}`}
      </TextMedium>
    </FormRow>
  )
};

export default AppVersionText;
