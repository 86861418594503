import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  FlatList,
  ActivityIndicator,
  Icon,
  Card,
  TextSmall,
  Button,
  ButtonTransparent,
} from '../../components';
import { View } from 'react-native';
import ScheduleModel from '../../models/ScheduleModel';
import moment from 'moment';

const SchedulesList = (props) => {
  const {
    dimensions,
    schedulesList,
    loadMoreData,
    pagination,
    openEventPage,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <>
      {schedulesList && schedulesList.length > 0 && (
        <FlatList
          keyboardShouldPersistTaps='handled'
          data={schedulesList}
          renderItem={renderListItem}
          keyExtractor={(item) => item.id}
          contentContainerStyle={{ paddingBottom: 10, paddingTop: 5 }}
        // onEndReached={loadMoreData}          
        // ListFooterComponent={(pagination.current.hasMore && <ActivityIndicator />)}
        />
      )}
      {schedulesList.length > 0 && pagination.hasMore && !pagination.loading && (
        <FormRow paddingBottom={10} justifyContent='center'>
          <FormColumn width={200}>
            <Button
              text={`${getDic("carregar")} ${getDic("mais")}`}
              onPress={() => loadMoreData()}
            />
          </FormColumn>
        </FormRow>
      )}
      {pagination.loading && (
        <FormRow padding={10} justifyContent='center'>
          <ActivityIndicator />
        </FormRow>
      )}
    </>
  )

  function renderListItem(obj) {
    return (
      <View key={obj.item.id}>
        <FormRow justifyContent='center' padding={5}>
          <ButtonTransparent onPress={() => openEventPage(obj.item)}>
            <Card borderLeftColor='#456091' borderLeftWidth={5} width={width}>
              {renderCard(obj.item)}
            </Card>
          </ButtonTransparent>
        </FormRow>
      </View>
    )
  }

  function renderCard(schedule = ScheduleModel()) {
    return (
      <FormColumn padding={5} width={width} paddingRight={15}>
        <FormColumn>
          <FormRow>
            <View style={{ paddingRight: 10 }}>
              <Icon type="MaterialIcons" name="event" size={20} color='#456091' />
            </View>
            <TextSmall>{`${schedule.eventId} - ${schedule.eventName}`}</TextSmall>
          </FormRow>
        </FormColumn>
        <FormRow padding={5}>
          <FormColumn width='50%'>
            <TextSmall fontWeight='bold'>{getDic("programacao")}</TextSmall>
            <TextSmall>{schedule.scheduleName}</TextSmall>
          </FormColumn>
          {/* <FormColumn width='50%' alignItems='flex-end'>
            <TextSmall fontWeight='bold'>{getDic("data")}</TextSmall>
            <TextSmall>{moment(schedule.startDate).format('DD/MM/YYYY')}</TextSmall>
          </FormColumn> */}
        </FormRow>
        <FormRow padding={5}>
          <FormColumn width='50%'>
            <TextSmall fontWeight='bold'>{`${getDic("data")} ${getDic("inicio")}`}</TextSmall>
            <TextSmall>{moment(schedule.startDate).format('DD/MM/YYYY HH:mm')}</TextSmall>
          </FormColumn>
          <FormColumn width='50%' alignItems='flex-end'>
            <TextSmall fontWeight='bold'>{`${getDic("data")} ${getDic("fim")}`}</TextSmall>
            <TextSmall>{moment(schedule.endDate).format('DD/MM/YYYY HH:mm')}</TextSmall>
          </FormColumn>
        </FormRow>
      </FormColumn>
    )
  }
};

export default SchedulesList;
