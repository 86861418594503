import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  Card,
  FormColumn
} from '../../components';
import { Dimensions } from 'react-native';
import UserModel from '../../models/UserModel';
import OrganizationModel from '../../models/OrganizationModel';
import userService from '../../services/userService';
import organizationService from '../../services/organizationService';

import OrganizationsList from './OrganizationsList';
import UserForm from './UserForm';
import ResetPasswordForm from './ResetPasswordForm';

const UserProfilePage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [userForm, setUserForm] = React.useState(UserModel(store.getState().user));
  const [userFormError, setUserFormError] = React.useState(UserModel());
  const [userFormIsEditing, setUserFormIsEditing] = React.useState(false);
  const [resetPasswordControl, setResetPasswordControl] = React.useState({
    open: false,
    password: '',
    confirmPassword: '',
  });
  const [currentOrganization, setCurrentOrganization] = React.useState(store.getState().chosenOrganization);
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
    hasMore: true,
    loading: false,
  });
  const paginationRef = React.useRef();
  React.useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);
  const [searchControl, setSearchControl] = React.useState({
    searchName: '',
    searchEmail: '',
    orderBy: 'name',
    order: 'ASC',
  });
  const lastSearchStringUpdate = React.useRef(Date.now());
  const [organizationsList, setOrganizationsList] = React.useState([]);

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  React.useEffect(() => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setOrganizationsList([]);
    lastSearchStringUpdate.current = Date.now();
    setTimeout(() => {
      if (lastSearchStringUpdate.current + 500 < Date.now()) {
        getOrganizationsList(true);
      }
    }, 500);
  }, [searchControl]);

  const getPageData = React.useCallback(async () => {
    try {
      if (store.getState().user.profile === 'admin' || store.getState().user.profile === 'inteegra') {
        getOrganizationsList(true);
      }

    } catch (error) {
      console.log('error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }
  });

  const trySaveUser = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, message: getDic("salvando"), open: true });

      if (!validateForm()) {
        return;
      }

      let _user = await userService.updateUser(userForm);

      setUserForm(_user);
      store.dispatch(actions.setUser(_user));

      setUserFormIsEditing(false);

    } catch (error) {
      console.log('error trySaveUser', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function validateForm() {
      try {
        let _isValid = true;
        let _userFormError = UserModel();

        if (!userForm.name || userForm.name === '') {
          _isValid = false;
          _userFormError.name = `${getDic("campo")} ${getDic("obrigatorio")}`;
        }
        if (!userForm.email || userForm.email === '') {
          _isValid = false;
          _userFormError.email = `${getDic("campo")} ${getDic("obrigatorio")}`;
        }

        setUserFormError(_userFormError);

        return _isValid;
      } catch (error) {
        console.log('error validateForm', error);
        throw error;
      }
    }
  });

  const openEditForm = React.useCallback(async () => {
    setUserFormIsEditing(true);
  });

  const cancelEditForm = React.useCallback(async () => {
    setUserFormIsEditing(false);
    setUserForm(UserModel(store.getState().user));
    setUserFormError(UserModel());
  });

  const trySaveNewPassword = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, message: getDic("salvando"), open: true });

      let _message = validateForm();
      if (_message) {
        setAlertControl({
          open: true,
          title: getDic("erro"),
          message: _message,
          icon: 'error',
        });
        return;
      }

      await userService.updatePassword(userForm, resetPasswordControl.password, resetPasswordControl.confirmPassword);

      setResetPasswordControl({
        open: false,
        password: '',
        confirmPassword: '',
      });

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success',
      });

    } catch (error) {
      console.log('error trySaveNewPassword', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    function validateForm() {
      try {
        let _message = '';

        if (!resetPasswordControl.password || resetPasswordControl.password === '') {
          _message = `${getDic("campo")} "${getDic("senha")}" ${getDic("obrigatorio")}`;
        }
        if (!resetPasswordControl.confirmPassword || resetPasswordControl.confirmPassword === '') {
          _message = `${getDic("campo")} "${getDic("confirmar")} ${getDic("senha")}" ${getDic("obrigatorio")}`;
        }
        if (resetPasswordControl.password !== resetPasswordControl.confirmPassword) {
          _message = `${getDic("campos")} "${getDic("senha")}" ${getDic("e")} "${getDic("confirmar")} ${getDic("senha")}" ${getDic("diferentes")}`;
        }

        return _message;
      } catch (error) {
        console.log('error validateForm', error);
        throw error;
      }
    }
  });

  const getOrganizationsList = React.useCallback(async (newSearch = false) => {
    try {
      if (store.getState().user.profile !== 'inteegra') {
        return;
      }

      setPagination({ ...pagination, loading: true });

      let _ret = await organizationService.getOrganizations(
        searchControl.searchName,
        paginationRef.current,
        searchControl.orderBy,
        searchControl.order,
      );

      let _finalList = [];
      if (!newSearch) {
        _finalList = [...organizationsList, ..._ret.data];
      } else {
        _finalList = [..._ret.data];
      }

      setOrganizationsList(_finalList);
      // setOrganizationsListQuantity(_ret?.count || 0)

      setPagination({ ...pagination, ..._ret.pagination, loading: false });
    } catch (error) {
      console.log('Error getOrganizationsList', error);
      setPagination({ ...pagination, loading: false });
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  const loadMoreData = React.useCallback(async () => {
    setPagination({
      limit: pagination.limit,
      offset: pagination.limit + pagination.offset,
      hasMore: true,
      loading: true,
    });
    getOrganizationsList();
  });

  const changeCurrentOrganization = React.useCallback(async (organization = OrganizationModel()) => {
    try {
      setLoadingControl({ ...loadingControl, message: getDic("salvando"), open: true });

      let _user = UserModel(store.getState().user);
      _user.organizations = [organization];

      await organizationService.changeCurrentOrganization(organization);

      store.dispatch(actions.setChosenOrganization(organization));
      store.dispatch(actions.setUser(_user));

      setCurrentOrganization(organization);

    } catch (error) {
      console.log('Error changeCurrentOrganization', error);
      throw error;
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      navigation={props.navigation}
      header={getDic("perfil")}
      backButton
      scroll
    >
      <FormColumn alignItems='center' padding={10}>
        <Card>
          <FormColumn width={dimensions.width < 620 ? dimensions.width - 30 : 600}>
            <UserForm
              userForm={userForm}
              setUserForm={setUserForm}
              userFormError={userFormError}
              userFormIsEditing={userFormIsEditing}
              trySaveUser={trySaveUser}
              openEditForm={openEditForm}
              cancelEditForm={cancelEditForm}
            />
            <ResetPasswordForm
              resetPasswordControl={resetPasswordControl}
              setResetPasswordControl={setResetPasswordControl}
              trySaveNewPassword={trySaveNewPassword}
            />
          </FormColumn>
        </Card>

        {store.getState().user.profile === 'inteegra' && (
          <OrganizationsList
            dimensions={dimensions}
            organizationsList={organizationsList}
            pagination={pagination}
            loadMoreData={loadMoreData}
            searchControl={searchControl}
            setSearchControl={setSearchControl}
            currentOrganization={currentOrganization}
            changeCurrentOrganization={changeCurrentOrganization}
          />
        )}
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default UserProfilePage;
