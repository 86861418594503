import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  InputField,
  SelectField,
  Card,
  Button,
} from '../../components';
import { mask } from "react-native-mask-text";
import validateFormUtil from '../../utils/validateFormUtil';


const ScheduleForm = (props) => {
  const {
    dimensions,
    scheduleForm,
    setScheduleForm,
    trySaveSchedule,
  } = props;

  return (
    <FormRow justifyContent='center'>
      <FormColumn width={dimensions.width < 680 ? dimensions.width - 80 : 600}>
        <Card>
          <FormColumn padding={10}>
            <FormRow>
              <InputField
                title={`ID ${getDic("evento")} *`}
                value={scheduleForm.eventId}
                onChange={v => setScheduleForm({ ...scheduleForm, eventId: v })}
              />
            </FormRow>
            <FormRow>
              <InputField
                title={`${getDic("nome")} ${getDic("evento")} *`}
                value={scheduleForm.eventName}
                onChange={v => setScheduleForm({ ...scheduleForm, eventName: v })}
              />
            </FormRow>
            <FormRow>
              <InputField
                title={`ID ${getDic("programacao")} *`}
                value={scheduleForm.scheduleId}
                onChange={v => setScheduleForm({ ...scheduleForm, scheduleId: v })}
              />
            </FormRow>
            <FormRow>
              <InputField
                title={`${getDic("nome")} ${getDic("programacao")} *`}
                value={scheduleForm.scheduleName}
                onChange={v => setScheduleForm({ ...scheduleForm, scheduleName: v })}
              />
            </FormRow>
            <FormRow>
              <InputField
                title={`${getDic("data")} ${getDic("inicio")} *`}
                value={scheduleForm.startDate}
                placeholder={`DD/MM/AAAA`}
                onChange={v => setScheduleForm({ ...scheduleForm, startDate: mask(v, '99/99/9999') })}
                keyboardType='numeric'
                onBlur={e => setScheduleForm({ ...scheduleForm, startDate: validateFormUtil.validateDate(e.target.value) })}
              />
            </FormRow>
            <FormRow>
              <InputField
                title={`${getDic("hora")} ${getDic("inicio")} *`}
                value={scheduleForm.startHour}
                placeholder={`HH:MM`}
                onChange={v => setScheduleForm({ ...scheduleForm, startHour: mask(v, '99:99') })}
                keyboardType='numeric'
                onBlur={e => setScheduleForm({ ...scheduleForm, startHour: validateFormUtil.validateHour(e.target.value) })}
              />
            </FormRow>
            <FormRow>
              <InputField
                title={`${getDic("data")} ${getDic("fim")} *`}
                value={scheduleForm.endDate}
                placeholder={`DD/MM/AAAA`}
                onChange={v => setScheduleForm({ ...scheduleForm, endDate: mask(v, '99/99/9999') })}
                keyboardType='numeric'
                onBlur={e => setScheduleForm({ ...scheduleForm, endDate: validateFormUtil.validateDate(e.target.value) })}
              />
            </FormRow>
            <FormRow>
              <InputField
                title={`${getDic("hora")} ${getDic("fim")} *`}
                value={scheduleForm.endHour}
                placeholder={`HH:MM`}
                onChange={v => setScheduleForm({ ...scheduleForm, endHour: mask(v, '99:99') })}
                keyboardType='numeric'
                onBlur={e => setScheduleForm({ ...scheduleForm, endHour: validateFormUtil.validateHour(e.target.value) })}
              />
            </FormRow>
            <FormRow paddingTop={15}>
              <Button
                text={`${getDic("salvar")}`}
                onPress={() => trySaveSchedule()}
              />
            </FormRow>
          </FormColumn>
        </Card>
      </FormColumn>
    </FormRow>
  )
};

export default ScheduleForm;
