import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Platform } from 'react-native';

import AdminAddUserToSchedulePage from './pages/AdminAddUserToSchedulePage';
import AdminGuestsListPage from './pages/AdminGuestsListPage';
import AdminOrganizationRegistrationPage from './pages/AdminOrganizationRegistrationPage';
import AdminOrganizationsListPage from './pages/AdminOrganizationsListPage';
import AdminScheduleRegisterPage from './pages/AdminScheduleRegisterPage';
import AdminSchedulesListPage from './pages/AdminSchedulesListPage';
import AdminUserRegistrationPage from './pages/AdminUserRegistrationPage';
import AdminUsersImporterPage from './pages/AdminUsersImporterPage';
import AdminUsersListPage from './pages/AdminUsersListPage';
import GuestDetailsPage from './pages/GuestDetailsPage';
import GuestRegistrationPage from './pages/GuestRegistrationPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import QrCodePage from './pages/QrCodePage';
import RecoverPasswordPage from './pages/RecoverPasswordPage';
import ScheduleDetailPage from './pages/ScheduleDetailPage';
import ScheduleGuestsReportPage from './pages/ScheduleGuestsReportPage';
import UserProfilePage from './pages/UserProfilePage';

const Stack = createNativeStackNavigator();

const linking = {
  prefixes: ['http://localhost:19006'],
  config: {
    screens: {
      AdminAddUserToSchedulePage: 'AdminAddUserToSchedule',
      AdminGuestsListPage: 'AdminGuestsList',
      AdminOrganizationRegistrationPage: 'AdminOrganizationRegistration',
      AdminOrganizationsListPage: 'AdminOrganizationsList',
      AdminScheduleRegisterPage: 'AdminScheduleRegister',
      AdminSchedulesListPage: 'AdminSchedulesList',
      AdminUserRegistrationPage: 'AdminUserRegistration',
      AdminUsersImporterPage: 'AdminUsersImporter',
      AdminUsersListPage: 'AdminUsersList',
      GuestDetailsPage: 'GuestDetails',
      GuestRegistrationPage: 'GuestRegistration',
      HomePage: 'Home',
      LoginPage: 'Login',
      QrCodePage: 'QrCode',
      RecoverPasswordPage: 'RecoverPassword',
      ScheduleDetailPage: 'ScheduleDetail',
      ScheduleGuestsReportPage: 'ScheduleGuestsReport',
      UserProfilePage: 'UserProfile',
    }
  },
};

const pageConfig = {
  animation: Platform.OS === 'ios' ? 'none' : 'default',
  headerShown: false,
  gestureEnabled: false,
  title: 'Inteegra Coletor'
}

const Router = () => {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="LoginPage">
        <Stack.Screen
          name="AdminAddUserToSchedulePage"
          component={AdminAddUserToSchedulePage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="AdminGuestsListPage"
          component={AdminGuestsListPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="AdminOrganizationRegistrationPage"
          component={AdminOrganizationRegistrationPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="AdminOrganizationsListPage"
          component={AdminOrganizationsListPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="AdminScheduleRegisterPage"
          component={AdminScheduleRegisterPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="AdminSchedulesListPage"
          component={AdminSchedulesListPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="AdminUserRegistrationPage"
          component={AdminUserRegistrationPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="AdminUsersImporterPage"
          component={AdminUsersImporterPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="AdminUsersListPage"
          component={AdminUsersListPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="GuestDetailsPage"
          component={GuestDetailsPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="GuestRegistrationPage"
          component={GuestRegistrationPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="HomePage"
          component={HomePage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="LoginPage"
          component={LoginPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="QrCodePage"
          component={QrCodePage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="RecoverPasswordPage"
          component={RecoverPasswordPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="ScheduleDetailPage"
          component={ScheduleDetailPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="ScheduleGuestsReportPage"
          component={ScheduleGuestsReportPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="UserProfilePage"
          component={UserProfilePage}
          options={{ ...pageConfig }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Router;
