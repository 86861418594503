import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  Card,
  Button,
  TextSmall,
} from '../../components';

const CommandButtons = (props) => {
  const {
    dimensions,
    exportExcelSheet,
    importExcelSheet,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <Card borderRadius={20}>
      <FormColumn alignItems='center' width={width}>
        <FormRow padding={10}>
          <Button
            text={`${getDic("exportar")} ${getDic("planilha")}`}
            onPress={() => exportExcelSheet()}
          />
        </FormRow>
        <FormRow padding={10}>
          <Button
            background='green'
            text={`${getDic("importar")} ${getDic("planilha")}`}
            onPress={() => importExcelSheet()}
          />
        </FormRow>
      </FormColumn>
    </Card>
  )
};

export default CommandButtons;
