import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  FlatList,
  ActivityIndicator,
  Icon,
  Card,
  TextSmall,
  Button,
  TextMedium,
  InputField,
} from '../../components';
import OrganizationModel from '../../models/OrganizationModel';
import { View } from 'react-native';

const UsersList = ({
  dimensions,
  organizationsList,
  loadMoreData,
  pagination,
  searchControl,
  setSearchControl,
  currentOrganization,
  changeCurrentOrganization,
  ...props
}) => {

  const width = dimensions.width < 620 ? dimensions.width - 30 : 600;

  return (
    <FormRow paddingTop={10} justifyContent='center'>
      <Card width={width}>
        <FormRow justifyContent='center'>
          <TextMedium>{getDic("lista")} {getDic("de")} {getDic("organizacoes")}</TextMedium>
        </FormRow>
        <FormRow justifyContent='center'>
          <TextSmall>{getDic("organizacao")} {getDic("atual")} - {currentOrganization.name}</TextSmall>
        </FormRow>
        <FormRow paddingLeft={5} paddingRight={5}>
          <InputField
            fieldHeight={25}
            fieldFontSize={12}
            iconLeft={<FormColumn paddingRight={5}><Icon type="FontAwesome" name="search" size={15} color='gray' /></FormColumn>}
            value={searchControl.searchName}
            placeholder={`${getDic("buscar")} ${getDic("organizacao")}`}
            onChange={v => setSearchControl({ ...searchControl, searchName: v })}
          />
        </FormRow>
        {organizationsList && organizationsList.length > 0 && (
          <FlatList
            keyboardShouldPersistTaps='handled'
            data={organizationsList}
            renderItem={renderListItem}
            keyExtractor={(item) => item.id}
            contentContainerStyle={{ paddingBottom: 10, paddingTop: 5 }}
          // onEndReached={loadMoreData}          
          // ListFooterComponent={(pagination.current.hasMore && <ActivityIndicator />)}
          />
        )}
        {organizationsList.length > 0 && pagination.hasMore && !pagination.loading && (
          <FormRow paddingBottom={10} justifyContent='center'>
            <FormColumn width={200}>
              <Button
                text={`${getDic("carregar")} ${getDic("mais")}`}
                onPress={() => loadMoreData()}
              />
            </FormColumn>
          </FormRow>
        )}
        {pagination.loading && (
          <FormRow padding={10} justifyContent='center'>
            <ActivityIndicator />
          </FormRow>
        )}
      </Card>
    </FormRow>
  )

  function renderListItem(obj) {
    if (obj.item.id === currentOrganization.id) {
      return null;
    }
    return (
      <FormRow padding={4} justifyContent='center'>
        <Card padding={0} width={width - 20} background='#f5f5f5'>
          {renderCard(obj.item)}
        </Card>
      </FormRow>
    )
  }

  function renderCard(organization = OrganizationModel()) {
    return (
      <FormColumn padding={5} width={width}>
        <FormRow padding={5}>
          <FormColumn>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Button onPress={() => changeCurrentOrganization(organization)}>
                  <TextSmall color='white'>{getDic("adicionar")}</TextSmall>
                </Button>
              </View>
              <FormColumn>
                <FormRow>
                  <TextSmall>{organization.name}</TextSmall>
                </FormRow>
              </FormColumn>
            </FormRow>
          </FormColumn>
        </FormRow>
      </FormColumn>
    )
  }
};

export default UsersList;
