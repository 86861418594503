import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  Icon,
  TextMedium,
  FormColumn,
  Button,
  ButtonTransparent,
  InputField,
} from '../../components';

const SuccessScreen = (props) => {
  const {
    dimensions,
    clearCameraDataAndSaveClassification,
    guestClassificationForm,
    setGuestClassificationForm,
    guest,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormRow
      justifyContent='center'
      background='green'
      width='100%'
      height={dimensions.height - 60}
    >
      <FormColumn justifyContent='center' alignItems='center'>
        <Icon type="Feather" name="check-circle" size={100} color='white' />
        <TextMedium color='white'>{getDic("participante-credenciado")}</TextMedium>
        <FormRow justifyContent='center' paddingTop={20} width={width}>
          <TextMedium color='white'>{guest.name}</TextMedium>
        </FormRow>
        <FormRow paddingTop={5} width={width}>
          {renderStarsInput()}
        </FormRow>
        <FormRow paddingTop={5} width={width}>
          <InputField
            labelColor='white'
            title={`${getDic("observacao")}`}
            value={guestClassificationForm.note}
            onChange={(e) => setGuestClassificationForm({ ...guestClassificationForm, note: e })}
          />
        </FormRow>
        <FormRow paddingTop={30} justifyContent='center'>
          <Button
            width={200}
            text='Ok'
            onPress={() => clearCameraDataAndSaveClassification()}
          />
        </FormRow>
      </FormColumn>
    </FormRow >
  )

  function renderStarsInput() {
    return (
      <FormRow justifyContent='center'>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 1 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 1 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 2 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 2 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 3 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 3 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 4 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 4 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
        <ButtonTransparent
          padding={0}
          onPress={() => setGuestClassificationForm({ ...guestClassificationForm, stars: 5 })}
        >
          <Icon type="MaterialIcons" name="star-rate" size={30} color={guestClassificationForm.stars >= 5 ? '#eba307' : '#5c5c5c'} />
        </ButtonTransparent>
      </FormRow>
    )
  }
};

export default SuccessScreen;
