const obj = {
  id: '',
  date: '',
  note: '',
  stars: 0,
}

export default function GuestClassificationModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};
