import { store } from '../store';
import axios from 'axios';
import apiService from './apiService';
import ScheduleModel from '../models/ScheduleModel';
import moment from 'moment';

export default {
  async createSchedule(schedule = ScheduleModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}schedule/createSchedule`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          organizationId: store.getState().chosenOrganization.id,
          eventId: schedule.eventId,
          scheduleId: schedule.scheduleId,
          eventName: schedule.eventName,
          scheduleName: schedule.scheduleName,
          startDate: moment(schedule.startDate).toISOString(),
          endDate: moment(schedule.endDate).toISOString(),
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return ScheduleModel(_ret.data.data);

    } catch (error) {
      console.log('Error createSchedule', error);
      throw error;
    }
  },

  async deleteSchedule(scheduleId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}schedule/inactiveSchedule`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          id: scheduleId,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error deleteSchedule', error);
      throw error;
    }
  },

  async getSchedules(
    organizationId = '',
    searchString = '',
    pagination = {
      limit: 10,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'start_date',
    order = 'DESC',
    startDate = '',
    endDate = '',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _token = await apiService.getUserToken();

      const _options = {
        url: `${apiService.getUrl().accreditatioStand}schedule/getSchedules?organizationId=${organizationId}&offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}&startDate=${startDate}&endDate=${endDate}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        }
      };

      const _ret = await axios(_options);
      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(ScheduleModel({
          ...item
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;

    } catch (error) {
      console.log('Error getSchedules', error);
      throw error;
    }
  },

  async getSchedulesByOrganization(
    organizationId = '',
    searchString = '',
    pagination = {
      limit: 10,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'start_date',
    order = 'DESC',
    startDate = '',
    endDate = '',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _token = await apiService.getUserToken();

      const _options = {
        url: `${apiService.getUrl().accreditatioStand}schedule/getSchedulesByOrganization?organizationId=${organizationId}&offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}&startDate=${startDate}&endDate=${endDate}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        }
      };

      const _ret = await axios(_options);
      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(ScheduleModel({
          ...item
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };

      return _return;

    } catch (error) {
      console.log('Error getSchedulesByOrganization', error);
      throw error;
    }
  },

  async updateSchedule(schedule = ScheduleModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}schedule/updateSchedule`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        data: {
          id: schedule.id,
          eventId: schedule.eventId,
          scheduleId: schedule.scheduleId,
          eventName: schedule.eventName,
          scheduleName: schedule.scheduleName,
          startDate: moment(schedule.startDate).toISOString(),
          endDate: moment(schedule.endDate).toISOString()
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return ScheduleModel(_ret.data.data);

    } catch (error) {
      console.log('Error updateSchedule', error);
      throw error;
    }
  },
}
