const obj = {
  id: '',
  name: '',
}

export default function OrganizationModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};
