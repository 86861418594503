import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  TextSmall,
} from '../../components';

const Instructions = (props) => {
  const {
    dimensions,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormRow justifyContent='center'>
      <FormColumn alignItems='center' width={width}>
        <FormRow padding={5}>
          <TextSmall>
            - Para realizar a importação, utilize a planilha gerada no botão "Exportar Planilha".
          </TextSmall>
        </FormRow>
        <FormRow padding={5}>
          <TextSmall>
            - Preencha os dados na aba "Dados" conforme descrito na aba "Instruções".
          </TextSmall>
        </FormRow>
        <FormRow padding={5}>
          <TextSmall>
            - Não deletar nenhuma coluna ou linha de controle da planilha para garantir o funcionamento do importador.
          </TextSmall>
        </FormRow>
        <FormRow padding={5}>
          <TextSmall>
            - Após preencher os dados, basta subir a planilha utilizando o botão "Importar Planilha".
          </TextSmall>
        </FormRow>
      </FormColumn>
    </FormRow>
  )
};

export default Instructions;
