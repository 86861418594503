import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import ScheduleModel from '../../models/ScheduleModel';
import GuestClassificationModel from '../../models/GuestClassificationModel';
import GuestModel from '../../models/GuestModel';
import QrCodeModel from '../../models/QrCodeModel';
import { useFocusEffect } from '@react-navigation/native';
import qrCodeService from '../../services/qrCodeService';
import guestService from '../../services/guestService';
import {
  Container,
  Loading,
  Alert,
  IsLoadingBackdrop,
  QrCodeScanner,
} from '../../components';
import { Dimensions } from 'react-native';
import guestClassificationService from '../../services/guestClassificationService';
import scheduleXGuestService from '../../services/scheduleXGuestService';
import vCard from 'vcard-parser';

import ErrorScreen from './ErrorScreen';
import SuccessScreen from './SuccessScreen';

const renderScreenControlObj = {
  camera: true,
  success: false,
  error: false,
}

const QrCodePage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [qrCodeData, setQrCodeData] = React.useState('');
  const isScanning = React.useRef(true);
  const schedule = ScheduleModel(store.getState().chosenSchedule);
  const [renderScreenControl, setRenderScreenControl] = React.useState({ ...renderScreenControlObj });
  const [guestClassificationForm, setGuestClassificationForm] = React.useState(GuestClassificationModel());
  const [guest, setGuest] = React.useState(GuestModel());

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      getPageData();
      isScanning.current = true;
      setRenderScreenControl({ ...renderScreenControlObj });
    }, [props.navigation])
  );

  const getPageData = React.useCallback(async () => {
    try {

    } catch (error) {
      console.log('error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }
  });

  const checkReturnData = React.useCallback(async (data = '') => {
    try {
      if (isScanning.current === false) { return };

      isScanning.current = false;
      setLoadingControl({ open: true, message: getDic("salvando") });

      let _qrCode = await qrCodeService.setQrCodeApi(data, schedule.id);

      let _guest = GuestModel();
      if (checkIfQrCodeIsVisitCard()) {
        _guest = await trySearchGuestByVCard(_qrCode);
        if (!_guest.id) {
          _guest = await getDataFromVCard();
        }
      } else {
        _guest = await trySearchGuestByQrCode(_qrCode);
      }

      if (!_guest.id) {
        store.dispatch(actions.setChosenGuest(_guest));
        store.dispatch(actions.setChosenQrCode(_qrCode));
        props.navigation.navigate('GuestRegistrationPage');
      }

      setGuest(_guest);

      setRenderScreenControl({
        ...renderScreenControl,
        camera: false,
        success: true,
      });

      setGuestClassificationForm(GuestClassificationModel());

    } catch (error) {
      console.log('Error checkReturnData', error);
      setRenderScreenControl({
        ...renderScreenControl,
        camera: false,
        error: true,
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      isScanning.current = true;
    }

    function checkIfQrCodeIsVisitCard() {
      try {
        if (data.includes('BEGIN:VCARD') && data.includes('END:VCARD')) {
          return true;
        }
        return false;
      } catch (error) {
        console.log('Error checkIfQrCodeIsVisitCard', error);
        throw error;
      }
    }

    async function trySearchGuestByVCard(qrCode = QrCodeModel()) {
      try {
        let _email = trySearchEmail();
        let _guest = GuestModel();
        if (_email) {
          try {
            _guest = await guestService.getGuestByEmail(store.getState().chosenOrganization.id, _email);
            await scheduleXGuestService.addGuestToSchedule(_guest.id, schedule.id);
          } catch (error) {
            console.log('Error getGuestByEmail', error);
          }
        }

        return _guest;
      } catch (error) {
        console.log('Error trySearchGuestByVCard', error);
        throw error;
      }

      function trySearchEmail() {
        let _card = vCard.parse(data);
        let _email = '';

        if (_card && _card.email && _card.email[0] && _card.email[0].value) {
          _email = _card.email[0].value;
        }

        return _email;
      }
    }

    async function getDataFromVCard() {
      try {
        let _card = vCard.parse(data);
        let _guest = GuestModel();

        if (_card && _card.fn && _card.fn[0] && _card.fn[0].value) {
          _guest.name = _card.fn[0].value;
        }
        if (_card && _card.email && _card.email[0] && _card.email[0].value) {
          _guest.email = _card.email[0].value;
        }
        if (_card && _card.org && _card.org[0] && _card.org[0].value) {
          _guest.company = _card.org[0].value;
        }
        if (_card && _card.title && _card.title[0] && _card.title[0].value) {
          _guest.position = _card.title[0].value;
        }
        if (_card && _card.tel && _card.tel[0] && _card.tel[0].value) {
          _guest.phone = _card.tel[0].value;
        }

        if (_guest.name && _guest.email) {
          _guest = await guestService.saveGuest(store.getState().chosenOrganization.id, _guest);
          await scheduleXGuestService.addGuestToSchedule(_guest.id, schedule.id);
        }

        return _guest;
      } catch (error) {
        console.log('Error getDataFromVCard', error);
        throw error;
      }
    }

    async function trySearchGuestByQrCode(qrCode = QrCodeModel()) {
      try {
        let _guest = GuestModel();
        try {
          _guest = await guestService.getGuestDataByQrCode(qrCode.qrCode, schedule.id);
          await scheduleXGuestService.addGuestToSchedule(_guest.id, schedule.id);
        } catch (error) {
          console.log('Error getGuestDataByQrCode', error);
        }

        if (!_guest.id) {
          try {
            _guest = await guestService.getMiceGuestDataByQrCode(qrCode.id, schedule.id);
          } catch (error) {
            console.log('Error getMiceGuestDataByQrCode', error);
          }
        }

        return _guest;
      } catch (error) {
        console.log('Error trySearchGuestByQrCode', error);
        throw error;
      }
    }
  });

  const clearCameraDataAndSaveClassification = React.useCallback(async () => {
    try {
      setLoadingControl({ ...loadingControl, message: getDic("salvando"), open: true });

      await checkIfCanSaveClassification();

      setQrCodeData('');
      setRenderScreenControl({ ...renderScreenControlObj });
    } catch (error) {
      console.log('Error clearCameraDataAndSaveClassification', error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }

    async function checkIfCanSaveClassification() {
      try {
        if (guest.id && (guestClassificationForm.stars > 0 || guestClassificationForm.note)) {
          await guestClassificationService.addGuestClassification(
            guest.id,
            guestClassificationForm.stars === 0 ? 1 : guestClassificationForm.stars,
            guestClassificationForm.note,
          );
        }
      } catch (error) {
        console.log('Error checkIfCanSaveClassification', error);
        throw error;
      }
    }
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      navigation={props.navigation}
      header={`${getDic("ler")} QR Code`}
      padding={0}
      backButton
    >
      {renderScreenControl.camera && (
        <QrCodeScanner
          isScanning={isScanning.current}
          onCancel={() => props.navigation.goBack()}
          onScann={(data) => checkReturnData(data)}
          useFrontCamera={false}
        />
      )}
      {renderScreenControl.success && (
        <SuccessScreen
          dimensions={dimensions}
          clearCameraDataAndSaveClassification={clearCameraDataAndSaveClassification}
          guestClassificationForm={guestClassificationForm}
          setGuestClassificationForm={setGuestClassificationForm}
          guest={guest}
        />
      )}
      {renderScreenControl.error && (
        <ErrorScreen
          dimensions={dimensions}
          clearCameraDataAndSaveClassification={clearCameraDataAndSaveClassification}
        />
      )}

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default QrCodePage;
