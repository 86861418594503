import React from 'react';
import { ActivityIndicator } from 'react-native';

const ActivityIndicatorProp = ({
  color = "black",
}) => {

  return (
    <ActivityIndicator color={color} />
  )

};

export default ActivityIndicatorProp;
