import axios from 'axios';
import QrCodeModel from '../models/QrCodeModel';
import apiService from './apiService';

export default {
  async setQrCodeApi(qrCodeData = '', scheduleId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}qrCode/addQrCode`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          qrCodeData: qrCodeData,
          scheduleId: scheduleId
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return QrCodeModel({ ..._ret.data.data });

    } catch (error) {
      console.log('Error setQrCodeApi', error);
      throw error;
    }
  },

  async setQrCodeSync(qrCodeId = '', guestId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}qrCode/setQrCodeSync`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          qrCodeId: qrCodeId,
          guestId: guestId,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return QrCodeModel({ ..._ret.data });

    } catch (error) {
      console.log('Error setQrCodeSync', error);
      throw error;
    }
  },
}
