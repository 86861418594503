import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import {
  Container,
  FormColumn,
  Loading,
  Alert,
  IsLoadingBackdrop,
} from '../../components';
import { Dimensions } from 'react-native';
import ScheduleModel from './../../models/ScheduleModel';
import moment from 'moment';

import CommandButtons from './CommandButtons';
import userService from '../../services/userService';

const ScheduleDetailPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [schedule, setSchedule] = React.useState(ScheduleModel());

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  const getPageData = React.useCallback(async () => {
    try {
      setSchedule(store.getState().chosenSchedule);

      if (store.getState().user.profile === 'admin' || store.getState().user.profile === 'inteegra') {
        await userService.addUserToSchedule(store.getState().user.id, store.getState().chosenSchedule.id);
      }

      setLoadingControl({ ...loadingControl, open: false });
    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setIsLoading(false);
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  const navigatePage = React.useCallback(async (page = '') => {
    try {
      if (page === 'QrCodePage' && !checkIfCanAccessQrCode()) {
        setAlertControl({
          open: true,
          title: '',
          message: `${getDic("data")} ${getDic("programacao")} ${getDic("expirada")}`,
          icon: 'danger',
        });
        return;
      }

      props.navigation.navigate(page);
    } catch (error) {
      console.log('Error navigatePage', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }

    function checkIfCanAccessQrCode() {
      try {
        var _scheduleDate = moment(schedule.endDate)
        var _currentDate = moment();
        var _diffMinutes = _currentDate.diff(_scheduleDate, "days");
        if (_diffMinutes && _diffMinutes > 1) {
          return false;
        }
        return true;
      } catch (error) {
        console.log('Error checkIfCanAccessQrCode', error);
        throw error;
      }
    }
  });

  const openGuestRegistration = React.useCallback(async () => {
    try {
      store.dispatch(actions.clearChosenGuest());
      store.dispatch(actions.clearChosenQrCode());
      navigatePage('GuestRegistrationPage');
    } catch (error) {
      console.log('Error openGuestRegistration', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  if (isLoading) { return (<IsLoadingBackdrop />) }
  return (
    <Container
      navigation={props.navigation}
      header={schedule.scheduleName}
      backButton
    >
      <FormColumn padding={5} alignSelf='center'>
        <CommandButtons
          dimensions={dimensions}
          navigatePage={navigatePage}
          openGuestRegistration={openGuestRegistration}
        />
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default ScheduleDetailPage;
