import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  FlatList,
  ActivityIndicator,
  Icon,
  Card,
  TextSmall,
  Button,
  ButtonTransparent,
} from '../../components';
import GuestModel from '../../models/GuestModel';
import { View } from 'react-native';

const GuestsList = (props) => {
  const {
    dimensions,
    guestsList,
    loadMoreData,
    pagination,
    openModalAddClassificationControl,
    openGuestDetailsPage,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <>
      {guestsList && guestsList.length > 0 && (
        <FlatList
          keyboardShouldPersistTaps='handled'
          data={guestsList}
          renderItem={renderListItem}
          keyExtractor={(item) => item.id}
          contentContainerStyle={{ paddingBottom: 10, paddingTop: 5 }}
        // onEndReached={loadMoreData}          
        // ListFooterComponent={(pagination.current.hasMore && <ActivityIndicator />)}
        />
      )}
      {guestsList.length > 0 && pagination.hasMore && !pagination.loading && (
        <FormRow paddingBottom={10} justifyContent='center'>
          <FormColumn width={200}>
            <Button
              text={`${getDic("carregar")} ${getDic("mais")}`}
              onPress={() => loadMoreData()}
            />
          </FormColumn>
        </FormRow>
      )}
      {pagination.loading && (
        <FormRow padding={10} justifyContent='center'>
          <ActivityIndicator />
        </FormRow>
      )}
    </>
  )

  function renderListItem(obj) {
    return (
      <FormRow padding={4} justifyContent='center'>
        <Card padding={0} width={width}>
          {renderCard(obj.item)}
        </Card>
      </FormRow>
    )
  }

  function renderCard(guest = GuestModel()) {
    return (
      <FormColumn padding={5} width={width}>
        <FormRow padding={5}>
          <FormColumn width='70%'>
            <FormRow>
              <View style={{ paddingRight: 10 }}>
                <Icon type="Ionicons" name="person" size={20} color='#456091' />
              </View>
              <TextSmall>{guest.name}</TextSmall>
            </FormRow>
          </FormColumn>
          <FormColumn width='30%'>
            {guest.stars > 0 && renderStars(guest)}
          </FormColumn>
        </FormRow>
        <FormRow padding={5}>
          <FormColumn width='50%'>
            <TextSmall fontWeight='bold'>{getDic("empresa")}</TextSmall>
            <TextSmall>{guest.company}</TextSmall>
          </FormColumn>
          <FormColumn width='50%' alignItems='flex-end'>
            <TextSmall fontWeight='bold'>{getDic("cargo")}</TextSmall>
            <TextSmall>{guest.position}</TextSmall>
          </FormColumn>
        </FormRow>
        <FormRow padding={5}>
          <FormColumn width='50%'>
            <TextSmall fontWeight='bold'>{getDic("email")}</TextSmall>
            <TextSmall>{guest.email}</TextSmall>
          </FormColumn>
          <FormColumn width='50%' alignItems='flex-end'>
            <TextSmall fontWeight='bold'>{getDic("telefone")}</TextSmall>
            <TextSmall>{guest.phone}</TextSmall>
          </FormColumn>
        </FormRow>
        {renderCommandButtons(guest)}
      </FormColumn>
    )
  }

  function renderStars(guest = GuestModel()) {
    return (
      <FormRow justifyContent='flex-end'>
        {guest.stars >= 1 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {guest.stars >= 2 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {guest.stars >= 3 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {guest.stars >= 4 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
        {guest.stars >= 5 && (<Icon type="MaterialIcons" name="star-rate" size={15} color={'#eba307'} />)}
      </FormRow>
    )
  }

  function renderCommandButtons(guest = GuestModel()) {
    return (
      <FormRow padding={5} justifyContent='flex-end'>
        <FormColumn paddingLeft={15}>
          <ButtonTransparent
            padding={0}
            onPress={() => openGuestDetailsPage(guest)}
          >
            <FormRow>
              <FormColumn paddingRight={3}>
                <TextSmall color='#5c5c5c'>{getDic("detalhes")}</TextSmall>
              </FormColumn>
              <Icon type="MaterialCommunityIcons" name="account-details" size={17} color='#5c5c5c' />
            </FormRow>
          </ButtonTransparent>
        </FormColumn>
        <FormColumn paddingLeft={15}>
          <ButtonTransparent
            padding={0}
            onPress={() => openModalAddClassificationControl(guest)}
          >
            <FormRow>
              <FormColumn paddingRight={3}>
                <TextSmall color='green'>{getDic("classificar")}</TextSmall>
              </FormColumn>
              <Icon type="MaterialIcons" name="star-rate" size={15} color='green' />
            </FormRow>
          </ButtonTransparent>
        </FormColumn>
      </FormRow>
    )
  }
};

export default GuestsList;
