import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  StyleSheet,
  View,
} from "react-native";
import {
  Icon,
  ButtonTransparent
} from '../../components';

const FabButtonNewUser = (props) => {
  const {
    openPageNewUser,
  } = props;

  const styles = StyleSheet.create({
    fabView: {
      width: 45,
      height: 45,
      borderRadius: 30,
      backgroundColor: '#456091',
      position: 'absolute',
      bottom: 10,
      right: 20,
      zIndex: 1,
      alignContent: 'center',
      justifyContent: 'center'
    },
  });

  return (
    <View style={styles.fabView}>
      <ButtonTransparent onPress={() => openPageNewUser()}>
        <Icon type="FontAwesome5" name="plus" size={25} color='white' />
      </ButtonTransparent>
    </View>
  )
};

export default FabButtonNewUser;
