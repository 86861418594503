import OrganizationModel from '../../models/OrganizationModel';
import { SET_CHOSEN_ORGANIZATION, CLEAR_CHOSEN_ORGANIZATION } from '../actions';

const INITIAL_STATE = OrganizationModel();

export default function chosenOrganizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CHOSEN_ORGANIZATION:
      return { ...state, ...action.payload };
    case CLEAR_CHOSEN_ORGANIZATION: ;
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
