import * as React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { Dimensions } from 'react-native';
import {
  Container,
  FormColumn,
  Loading,
  Alert,
  Dialog,
} from '../../components';
import organizationService from '../../services/organizationService';
import { useFocusEffect } from '@react-navigation/native';
import OrganizationModel from '../../models/OrganizationModel';

import FabButtonNewOrganization from './FabButtonNewOrganization';
import OrganizationsList from './OrganizationsList';
import HeaderContent from './HeaderContent';

const AdminOrganizationsListPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
    hasMore: true,
    loading: false,
  });
  const paginationRef = React.useRef();
  React.useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);
  const [organizationsList, setOrganizationsList] = React.useState([]);
  const [organizationsListQuantity, setOrganizationsListQuantity] = React.useState(0);
  const [searchControl, setSearchControl] = React.useState({
    searchName: '',
    searchEmail: '',
    orderBy: 'name',
    order: 'ASC',
  });
  const lastSearchStringUpdate = React.useRef(Date.now());
  const [dialogOrganizationControl, setDialogOrganizationControl] = React.useState({
    open: false,
    title: '',
    message: '',
    command: '',
    organizationId: '',
  });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, []);

  React.useEffect(() => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setOrganizationsList([]);
    lastSearchStringUpdate.current = Date.now();
    setTimeout(() => {
      if (lastSearchStringUpdate.current + 500 < Date.now()) {
        getOrganizationsList(true);
      }
    }, 500);
  }, [searchControl]);

  const getPageData = React.useCallback(async () => {
    try {
      if (store.getState().user.profile !== 'inteegra') {
        if (props.navigation.canGoBack()) {
          props.navigation.goBack();
        } else {
          window.history.back();
        }
      }

    } catch (error) {
      console.log('Error getPageData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  useFocusEffect(
    React.useCallback(() => {
      resetOrganizationsList();
    }, [props.navigation])
  );

  const resetOrganizationsList = React.useCallback(async () => {
    setPagination({ limit: 10, offset: 0, hasMore: true, loading: false });
    setOrganizationsList([]);
    setTimeout(() => {
      getOrganizationsList(true);
    }, 500);
  });

  const cleanFilterDataControl = React.useCallback(async () => {
    setSearchControl({
      searchName: '',
      searchEmail: '',
      orderBy: 'name',
      order: 'ASC',
    });
  });

  const getOrganizationsList = React.useCallback(async (newSearch = false) => {
    try {
      setPagination({ ...pagination, loading: true });

      let _ret = await organizationService.getOrganizations(
        searchControl.searchName,
        paginationRef.current,
        searchControl.orderBy,
        searchControl.order,
      );

      let _finalList = [];
      if (!newSearch) {
        _finalList = [...organizationsList, ..._ret.data];
      } else {
        _finalList = [..._ret.data];
      }

      setOrganizationsList(_finalList);
      setOrganizationsListQuantity(_ret?.count || 0)

      setPagination({ ...pagination, ..._ret.pagination, loading: false });
    } catch (error) {
      console.log('Error getOrganizationsList', error);
      setPagination({ ...pagination, loading: false });
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    }
  });

  const loadMoreData = React.useCallback(async () => {
    setPagination({
      limit: pagination.limit,
      offset: pagination.limit + pagination.offset,
      hasMore: true,
      loading: true,
    });
    getOrganizationsList();
  });

  const openPageNewOrganization = React.useCallback(async () => {
    props.navigation.navigate('AdminOrganizationRegistrationPage', { organization: OrganizationModel() });
  });

  const openPageEditOrganization = React.useCallback(async (organization = OrganizationModel()) => {
    props.navigation.navigate('AdminOrganizationRegistrationPage', { organization: organization });
  });

  const openDialogDeleteOrganization = React.useCallback(async (organization = OrganizationModel()) => {
    setDialogOrganizationControl({
      ...dialogOrganizationControl,
      open: true,
      title: getDic("atencao"),
      message: `${getDic("deletar")} ${getDic("contrato")} '${organization.name}'. ${getDic("confirmacao-acao")}`,
      command: 'delete',
      organizationId: organization.id
    });
  });

  const tryDeleteOrganization = React.useCallback(async () => {
    try {
      setDialogOrganizationControl({ ...dialogOrganizationControl, open: false });

      setLoadingControl({ ...loadingControl, open: true, message: getDic("excluindo") });

      await organizationService.deleteOrganization(dialogOrganizationControl.organizationId);

      setAlertControl({
        open: true,
        title: '',
        message: getDic("enviado-sucesso"),
        icon: 'success',
      });

      resetOrganizationsList();

    } catch (error) {
      console.log('Error tryDeleteOrganization', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error',
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  return (
    <Container
      navigation={props.navigation}
      headerContent={
        <>
          <HeaderContent
            dimensions={dimensions}
            searchControl={searchControl}
            setSearchControl={setSearchControl}
            cleanFilterDataControl={cleanFilterDataControl}
            organizationsListQuantity={organizationsListQuantity}
          />
        </>
      }
      headerSubContent={
        <FabButtonNewOrganization
          openPageNewOrganization={openPageNewOrganization}
        />
      }
      headerPadding={5}
      backButton
      scroll
    >
      <FormColumn>
        <OrganizationsList
          dimensions={dimensions}
          organizationsList={organizationsList}
          pagination={pagination}
          loadMoreData={loadMoreData}
          openPageEditOrganization={openPageEditOrganization}
          openDialogDeleteOrganization={openDialogDeleteOrganization}
        />
      </FormColumn>

      {modalControl()}
    </Container>
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (dialogOrganizationControl.open) {
      return (
        <Dialog
          open={dialogOrganizationControl.open}
          title={dialogOrganizationControl.title}
          message={dialogOrganizationControl.message}
          icon='danger'
          button1OnPress={() => tryDeleteOrganization()}
          button2OnPress={() => setDialogOrganizationControl({ ...dialogOrganizationControl, open: false })}
          backdropPress={() => setDialogOrganizationControl({ ...dialogOrganizationControl, open: false })}
        />
      )
    }

    return null;
  }
};

export default AdminOrganizationsListPage;
