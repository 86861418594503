import QrCodeModel from '../../models/QrCodeModel';
import { SET_CHOSEN_QRCODE, CLEAR_CHOSEN_QRCODE } from '../actions';

const INITIAL_STATE = QrCodeModel();

export default function chosenQrCodeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CHOSEN_QRCODE:
      return { ...state, ...action.payload };
    case CLEAR_CHOSEN_QRCODE: ;
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
