import React from 'react';
import { FlatList } from 'react-native';

const FlatListProp = ({
  style,
  contentContainerStyle,
  data,
  renderItem,
  keyExtractor,
  onEndReached,
  onEndReachedThreshold = 0.1,
  ListFooterComponent,
  keyboardShouldPersistTaps,
  ...props
}) => {

  return (
    <FlatList
      style={style}
      contentContainerStyle={contentContainerStyle}
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      onEndReached={onEndReached}
      onEndReachedThreshold={onEndReachedThreshold}
      ListFooterComponent={ListFooterComponent}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
    />
  )
};

export default FlatListProp;
