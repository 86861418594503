import axios from 'axios';
import GuestModel from '../models/GuestModel';
import apiService from './apiService';
import moment from 'moment';

export default {
  async getGuestDataByQrCode(qrCodeData = '', scheduleId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}guest/getGuestByQrCodeData`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          qrCodeData: qrCodeData,
          scheduleId: scheduleId
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return GuestModel({ ..._ret.data.data });

    } catch (error) {
      console.log('Error setQrCodeApi', error);
      throw error;
    }
  },

  async getMiceGuestDataByQrCode(qrCodeId = '', scheduleId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStandMice}guest/registerQrCodeAndGetGuest`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          qrCodeId: qrCodeId,
          scheduleId: scheduleId
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return GuestModel({ ..._ret.data.data });

    } catch (error) {
      console.log('Error getMiceGuestDataByQrCode', error);
      throw error;
    }
  },

  async getGuestByEmail(organizationId = '', email = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}guest/getGuestByEmail?organizationId=${organizationId}&email=${email}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      let _return = GuestModel({
        id: _ret.data?.data?.id,
        email: _ret.data?.data?.email,
        name: _ret.data?.data?.name,
        company: _ret.data?.data?.company,
        position: _ret.data?.data?.position,
        phone: _ret.data?.data?.phone,
        stars: _ret.data?.data?.stars,
      });

      return _return;
    } catch (error) {
      console.log('Error getGuestByEmail', error);
      throw error;
    }
  },

  async getGuests(
    organizationId = '',
    searchString = '',
    pagination = {
      limit: 1,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'name',
    order = 'ASC',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}guest/getGuests?organizationId=${organizationId}&offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(GuestModel({
          id: item.id,
          email: item.email,
          name: item.name,
          company: item.company,
          position: item.position,
          phone: item.phone,
          stars: item.stars,
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;

    } catch (error) {
      console.log('Error getGuests', error);
      throw error;
    }
  },

  async getGuestsWithClassificationBySchedule(
    scheduleId = '',
    searchString = '',
    pagination = {
      limit: 1,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'name',
    order = 'ASC',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}guest/getGuestsWithClassification?scheduleId=${scheduleId}&offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(GuestModel({
          id: item.id,
          email: item.email,
          name: item.name,
          company: item.company,
          position: item.position,
          phone: item.phone,
          stars: item.stars,
          note: item.note,
          date: moment(item.date).format('DD/MM/YYYY HH:mm'),
          userName: item.userName,
          userEmail: item.userEmail,
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;

    } catch (error) {
      console.log('Error getGuestsWithClassificationBySchedule', error);
      throw error;
    }
  },

  async saveGuest(organizationId = '', guest = GuestModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}guest/saveGuest`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          organizationId: organizationId,
          email: guest.email,
          name: guest.name,
          company: guest.company,
          position: guest.position,
          phone: guest.phone,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return GuestModel({ ..._ret.data.data });

    } catch (error) {
      console.log('Error saveGuest', error);
      throw error;
    }
  },

  async updateGuest(guest = GuestModel()) {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}guest/updateGuest`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          guestId: guest.id,
          email: guest.email,
          name: guest.name,
          company: guest.company,
          position: guest.position,
          phone: guest.phone,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return GuestModel({ ..._ret.data.data });

    } catch (error) {
      console.log('Error updateGuest', error);
      throw error;
    }
  },

  async inactiveGuest(guestId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}guest/inactiveGuest`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          id: guestId,
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return GuestModel({ ..._ret.data.data });

    } catch (error) {
      console.log('Error inactiveGuest', error);
      throw error;
    }
  },
}
