import axios from 'axios';
import apiService from './apiService';

export default {
  async addGuestToSchedule(guestId = '', scheduleId = '') {
    try {
      const _token = await apiService.getUserToken();

      const _ret = await axios({
        url: `${apiService.getUrl().accreditatioStand}scheduleXGuest/addGuestToSchedule`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json"
        },
        data: {
          guestId: guestId,
          scheduleId: scheduleId
        }
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;

    } catch (error) {
      console.log('Error addGuestToSchedule', error);
      throw error;
    }
  },
}
