import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  Icon,
  InputField,
  ButtonTransparent,
  TextSmall,
} from '../../components';

const HeaderContent = (props) => {
  const {
    dimensions,
    searchControl,
    setSearchControl,
    cleanFilterDataControl,
    organizationsListQuantity,
  } = props;

  return (
    <FormColumn>
      <FormRow paddingLeft={10} width={dimensions.width - 80}>
        <InputField
          fieldHeight={25}
          fieldFontSize={12}
          iconLeft={<FormColumn paddingRight={5}><Icon type="FontAwesome" name="search" size={15} color='gray' /></FormColumn>}
          value={searchControl.searchName}
          placeholder={`${getDic("buscar")} ${getDic("contrato")}`}
          onChange={v => setSearchControl({ ...searchControl, searchName: v })}
        />
        <FormColumn width={40}>
          <ButtonTransparent onPress={() => cleanFilterDataControl()}>
            <Icon type="Ionicons" name="refresh" size={18} color='white' />
          </ButtonTransparent>
        </FormColumn>
      </FormRow>
      <FormRow width={dimensions.width - 80} justifyContent='center' flexWrap='wrap'>
        <FormColumn justifyContent='center'>
          <TextSmall color='white'>{`${getDic("contratos")} ${getDic("encontrados")}: ${organizationsListQuantity}`}</TextSmall>
        </FormColumn>
      </FormRow>
    </FormColumn>
  )
};

export default HeaderContent;
